import actions from './actions'

export default function ProposalsReducer(state = { loading: false }, action) {
  switch (action.type) {

    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
      }

    case actions.Refresh_Recieved:
      return {
        ...state,
        refresh: true,
        loading: false,
      }

    case actions.Refresh_reset_Recieved:
      return {
        ...state,
        refresh: false,
        loading: false,
      }

    case actions.LIST_PROPOSAL:
      return {
        ...state,
        result: action.result,
        loading: false,
      }

    case actions.COPY_PROPOSAL_RECIEVED:
      return {
        ...state,
        copiedID: action.result,
        loading: false,
      }

    case actions.COPY_PROPOSAL_RESET_RECIEVED:
      return {
        ...state,
        copiedID: null,
        loading: false,
      }

    case actions.EDIT_PROPOSAL:
      return {
        ...state,
        editing: action.result,
        loading: false,
      }

    case actions.RESET_PROPOSAL_RECIEVED:
      return {
        ...state,
        editing: { proposalID: 0 },
        costViews: null,
        newPro: false,
        loading: false,
      }

    case actions.CREATE_RESET_PROPOSAL_RECIEVED:
      return {
        ...state,
        newPro: false,
        loading: false,
      }

    case actions.CREATE_PROPOSAL_RECIEVED:
      return {
        ...state,
        editing: action.result,
        loading: false,
        newPro: true
      }

    case actions.FETCH_COST_VIEW_RECIEVED:
      return {
        ...state,
        costViews: action.result,
        loading: false
      }

    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
