import { getRequest, postRequest, getWorkorderID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('WorkOrderID'))
  searchTerm = `${searchTerm}, WorkOrderID:${getWorkorderID()},`
  return getRequest(`workOrderAdditionalCost/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`workOrderAdditionalCost/edit/${id}`)
}

export async function save(values) {
  return postRequest(`workOrderAdditionalCost/save`, values)
}

export function remove(values) {
  return postRequest(`workOrderAdditionalCost/delete`, values)
}
