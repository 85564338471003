import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, create, copy, getCostView } from '../../services/proposal'

function* fetchProposalsRefresh() {
  const fe = JSON.parse(localStorage.getItem('fetchproposalsFilter'))
  if (fe == null) {
    fe.size = 10
    fe.index = 0
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index)
  yield put({
    type: actions.LIST_PROPOSAL,
    result,
  })
}

function* fetchProposals({ size, index, orderNumber, proposalNumber, beginDate, endDate, status }) {
  // Load last params froom last session.
  if (!size && !index) {
    const fe = JSON.parse(localStorage.getItem('fetchproposalsFilter'))
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      orderNumber = fe.orderNumber || 0
      proposalNumber = fe.proposalNumber || 0
      beginDate = fe.beginDate || 0
      endDate = fe.endDate || 0
      status = fe.status || 0
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
    }
  }
  localStorage.setItem('fetchproposalsFilter', JSON.stringify({ size, index, orderNumber, proposalNumber, beginDate, endDate, status }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, orderNumber, proposalNumber, beginDate, endDate, status)
  yield put({
    type: actions.LIST_PROPOSAL,
    result,
  })
}

function* createProposal() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(create)
  yield put({
    type: actions.CREATE_PROPOSAL_RECIEVED,
    result,
  })
}

function* copyProposal({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(copy, id)
  yield put({
    type: actions.COPY_PROPOSAL_RECIEVED,
    result,
  })
}

function* copyProposalReset() {
  yield put({
    type: actions.COPY_PROPOSAL_RESET_RECIEVED,
  })
}

function* resetPRoposal() {
  yield put({
    type: actions.RESET_PROPOSAL_RECIEVED,
  })
}

function* createResetProposal() {
  yield put({
    type: actions.CREATE_RESET_PROPOSAL_RECIEVED,
  })
}

function* fetchCostView({ id }) {
  if (id) {
    yield put({
      type: actions.REQUESTING,
    });
    const result = yield call(getCostView, id)
    yield put({
      type: actions.FETCH_COST_VIEW_RECIEVED,
      result,
    });
  }
}

function* fetchProposalEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PROPOSAL,
    result,
  });
  yield put({
    type: 'Proposal/FETCH_COST_VIEW',
  });
}

function* fetchProposalSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const saveResult = yield call(save, values)
  if (saveResult !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  const result = yield call(find, saveResult)
  yield put({
    type: actions.EDIT_PROPOSAL,
    result,
  });
  yield put({
    type: actions.FETCH_COST_VIEW,
  });
}

function* fetchProposalDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.RE_FETCH_PROPOSALS,
  });
}

function* refreshCall() {
  yield put({
    type: actions.Refresh_Recieved,
  })
}

function* refreshReset() {
  yield put({
    type: actions.Refresh_reset_Recieved,
  })
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.COPY_PROPOSAL_RESET, copyProposalReset),
    takeEvery(actions.COPY_PROPOSAL, copyProposal),

    takeEvery(actions.Refresh_reset, refreshReset),
    takeEvery(actions.Refresh, refreshCall),

    takeEvery(actions.RESET_PROPOSAL, resetPRoposal),

    takeEvery(actions.CREATE_PROPOSAL, createProposal),
    takeEvery(actions.CREATE_RESET_PROPOSAL, createResetProposal),


    takeEvery(actions.FETCH_PROPOSALS, fetchProposals),
    takeEvery(actions.FETCH_PROPOSAL, fetchProposalEdit),
    takeEvery(actions.SAVE_PROPOSAL, fetchProposalSave),
    takeEvery(actions.DELETE_PROPOSAL, fetchProposalDelete),
    takeEvery(actions.RE_FETCH_PROPOSALS, fetchProposalsRefresh),
    takeEvery(actions.FETCH_COST_VIEW, fetchCostView),
  ])
}
