import actions from './actions'

export default function SuppliersReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_SUPPLIER:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_SUPPLIER:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal
      }
    case actions.RESET_SUPPLIER:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        openModal: null
      }
    case actions.NEW_SUPPLIER:
      return {
        ...state,
        editing: { supplierID: 0 },
        loading: false,
        saveSuccess: false,
        openModal: true
      }
    case actions.CREATE_SUPPLIER_RECIEVED ||
      actions.UPDATE_SUPPLIER_RECIEVED ||
      actions.DELETE_SUPPLIER_RECIEVED:
      return {
        ...state,
        editing: { supplierID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
