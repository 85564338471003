const actions = {
  REQUESTING: 'InventoryServiceLabors/REQUESTING',
  RESPONSE_ERROR: 'InventoryServiceLabors/RESPONSE_ERROR',

  FETCH_INVENTORYSERVICELABORS: 'InventoryServiceLabors/FETCH',
  FETCH_INVENTORYSERVICELABORS_FILTER: 'InventoryServiceLabors/FETCH_FILTER',
  LIST_INVENTORYSERVICELABOR: 'InventoryServiceLabor/LIST',

  FETCH_INVENTORYSERVICELABOR: 'InventoryServiceLabor/FETCH',
  NEW_INVENTORYSERVICELABOR: 'InventoryServiceLabor/NEW',
  EDIT_INVENTORYSERVICELABOR: 'InventoryServiceLabor/EDIT',
  RESET_INVENTORYSERVICELABOR: 'InventoryServiceLabor/RESET',

  RE_FETCH_INVENTORYSERVICELABORS: 'InventoryServiceLabors/REFRESH',
  SAVE_INVENTORYSERVICELABOR: 'InventoryServiceLabor/SAVE',
  SAVE_INVENTORYSERVICELABOR_RECIEVED: 'InventoryServiceLabor/SAVE_INVENTORYSERVICELABOR_RECIEVED',

  DELETE_INVENTORYSERVICELABOR: 'InventoryServiceLabor/DELETE',
  DELETE_INVENTORYSERVICELABOR_RECIEVED: 'InventoryServiceLabor/DELETE_RECIEVED',
}

export default actions
