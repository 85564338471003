const actions = {
  REQUESTING: 'Devices/REQUESTING',
  RESPONSE_ERROR: 'Devices/RESPONSE_ERROR',

  FETCH_DEVICES: 'Devices/FETCH',
  LIST_DEVICE: 'Device/LIST',

  FETCH_DEVICE: 'Device/FETCH',
  NEW_DEVICE: 'Device/NEW',
  EDIT_DEVICE: 'Device/EDIT',
  RESET_DEVICE: 'Device/RESET',

  RE_FETCH_DEVICES: 'Devices/REFRESH',
  SAVE_DEVICE: 'Device/SAVE',
  SAVE_DEVICE_RECIEVED: 'Device/SAVE_DEVICE_RECIEVED',

  DELETE_DEVICE: 'Device/DELETE',
  DELETE_DEVICE_RECIEVED: 'Device/DELETE_RECIEVED',

  FILTER_DEVICES: 'Devices/FILTER_DEVICES',
  FILTER_DEVICES_RECIEVED: 'Devices/FILTER_DEVICES_RECIEVED'
}

export default actions
