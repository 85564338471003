import actions from './actions'

export default function InventoryClassCostTypesReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_INVENTORYCLASSCOSTTYPE:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_INVENTORYCLASSCOSTTYPE:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal
      }
    case actions.RESET_INVENTORYCLASSCOSTTYPE:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        openModal: null
      }
    case actions.NEW_INVENTORYCLASSCOSTTYPE:
      return {
        ...state,
        editing: { id: 0 },
        loading: false,
        saveSuccess: false,
        openModal: true

      }
    case actions.CREATE_INVENTORYCLASSCOSTTYPE_RECIEVED ||
      actions.UPDATE_INVENTORYCLASSCOSTTYPE_RECIEVED ||
      actions.DELETE_INVENTORYCLASSCOSTTYPE_RECIEVED:
      return {
        ...state,
        editing: { id: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
