import actions from './actions'

const initialState = {
  loading: false,
  customers: undefined,
  contracts: undefined,
  inventories: undefined,
  inventoryClasses: undefined,
  inventoryClassCostTypes: undefined,
  costTypeDefinitions: undefined,
  workOrderTypes: undefined,
  locations: undefined,
  areas: undefined,
  users: undefined,
  addresses: undefined,
  devices: undefined,
  intervals: undefined,
  technicians: undefined,
  workOrders: undefined,
  suppliers: undefined,
  inventoryServices: undefined,
  mlfb: undefined,
  technicianusers: undefined,
  adminUsers: undefined,
  notificationUsers: undefined,
  docParent: undefined,
  clin: undefined,
  contactPerson: undefined,
  bankInfos: undefined
}

export default function countryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      }
    case actions.DROPDOWN_CLIN:
      return {
        ...state,
        clin: action.result,
        loading: false,
      }
    case actions.DROPDOWN_CONTACT_PERSON:
      return {
        ...state,
        contactPerson: action.result,
        loading: false,
      }
    case actions.DROPDOWN_ADMIN_USERS:
      return {
        ...state,
        adminUsers: action.result,
        loading: false,
      }
    case actions.DROPDOWN_MLFB:
      return {
        ...state,
        mlfb: action.result,
        loading: false,
      }
    case actions.DROPDOWN_INVENTORY_SERVICES:
      return {
        ...state,
        inventoryServices: action.result,
        loading: false,
      }
    case actions.DROPDOWN_TECHNICIAN_USERS:
      return {
        ...state,
        technicianusers: action.result,
        loading: false,
      }
    case actions.DROPDOWN_INVENTORY_RESET_RECIEVED:
      return {
        ...state,
        inventories: undefined,
        loading: false,
      }
    case actions.DROPDOWN_PARTS:
      return {
        ...state,
        parts: action.result,
        loading: false,
      }
    case actions.DROPDOWN_INVENTORY_CLASS_COST_TYPE:
      return {
        ...state,
        inventoryClassCostTypes: action.result,
        loading: false,
      }
    case actions.DROPDOWN_SUPPLIER:
      return {
        ...state,
        suppliers: action.result,
        loading: false,
      }
    case actions.DROPDOWN_CONTRACT:
      return {
        ...state,
        contracts: action.result,
        loading: false,
      }
    case actions.DROPDOWN_CUSTOMER:
      return {
        ...state,
        customers: action.result,
        loading: false,
      }
    case actions.DROPDOWN_WORKORDER:
      return {
        ...state,
        workOrders: action.result,
        loading: false,
      }
    case actions.DROPDOWN_NOTIFICATION_USERS:
      return {
        ...state,
        notificationUsers: action.result,
        loading: false,
      }

    case actions.DROPDOWN_DOC_PARENT:
      return {
        ...state,
        docParent: action.result,
        loading: false,
      }

    case actions.DROPDOWN_BANK_INFO:
      return {
        ...state,
        bankInfos: action.result,
        loading: false,
      }

    case actions.DROPDOWN_INVENTORY_CLASS:
      return {
        ...state,
        inventoryClasses: action.result,
        loading: false,
      }

    case actions.DROPDOWN_COST_TYPE_DEFINITIONS:
      return {
        ...state,
        costTypeDefinitions: action.result,
        loading: false,
      }

    case actions.DROPDOWN_AREA:
      return {
        ...state,
        areas: action.result,
        loading: false,
      }

    case actions.DROPDOWN_INVENTORY:
      return {
        ...state,
        inventories: action.result,
        loading: false,
      }

    case actions.DROPDOWN_TECHNICIAN:
      return {
        ...state,
        technicians: action.result,
        loading: false,
      }

    case actions.DROPDOWN_WORKORDER_TYPE:
      return {
        ...state,
        workOrderTypes: action.result,
        loading: false,
      }

    case actions.DROPDOWN_ADDRESS:
      return {
        ...state,
        addresses: action.result,
        loading: false,
      }

    case actions.DROPDOWN_INTERVAL:
      return {
        ...state,
        intervals: action.result,
        loading: false,
      }

    case actions.DROPDOWN_DEVICE:
      return {
        ...state,
        devices: action.result,
        loading: false,
      }

    case actions.DROPDOWN_LOCATION:
      return {
        ...state,
        locations: action.result,
        loading: false,
      }

    case actions.DROPDOWN_USERS:
      return {
        ...state,
        users: action.result,
        loading: false,
      }

    case actions.DROPDOWN_COST_TYPE:
      return {
        ...state,
        costTypes: action.result,
        loading: false,
      }
      
    default:
      return state
  }
}
