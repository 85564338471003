import { getRequest, postRequest, getProposalID } from './api'

export async function list(id, unitType) {
  id= getProposalID()
  return getRequest(`proposalDetail/get-list/${id}/${unitType}`)
}

export async function find(id) {
  return getRequest(`proposalDetail/edit/${id}`)
}

export async function save(values) {
  return postRequest(`proposalDetail/save`, values)
}

export function remove(values) {
  return postRequest(`proposalDetail/delete`, values)
}




export function getMaterial(id) {
  return getRequest(`proposalDetail/get-material/${id}`)
}

export async function saveMaterial(values) {
  return postRequest(`proposalDetail/save-material`, values)
}

export async function deleteMaterial(values) {
  return postRequest(`proposalDetail/delete-material`, values)
}

