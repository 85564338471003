import { getRequest, postRequest, getcontractIdNumber } from './api'

// export async function list(size, index, searchTerm, orderBy) {
//   return getRequest(`proposal/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
// }

export async function list(size, index, orderNumber, proposalNumber,beginDate,endDate, status) {
  const contractID = getcontractIdNumber()
  return getRequest(`proposal/get-filters/${size || 10}/${index || 0}/${orderNumber || '%20'}/${proposalNumber || '%20'}/${beginDate || '%20'}/${endDate || '%20'}/${status || '%20'}/${contractID}`)
}

export async function find(id) {
  return getRequest(`proposal/edit/${id}`)
}

export async function copy(id) {
  return getRequest(`proposal/copy/${id}`)
}

export async function save(values) {
  return postRequest(`proposal/save`, values)
}

export function remove(values) {
  return postRequest(`proposal/delete`, values)
}

export async function create() {
  const ContractID = getcontractIdNumber()
  return getRequest(`proposal/create/${ContractID}`)
}

export async function createWorkOrder(id) {
  return getRequest(`workOrder/create-from-proposal/${id}`)
}

export async function getCostView(id) {
  return getRequest(`proposal/get-cost-view/${id}`)
}
