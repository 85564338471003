import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/clinPaymentType'

function* fetchClinPaymentTypesRefresh() {
  let fe = null // JSON.parse(localStorage.getItem('fetchClinPaymentTypes'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  });
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_CLINPAYMENTTYPE,
    result,
  });
}

function* fetchClinPaymentTypes({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    size = localStorage.getItem('pageSize') || 15
    index = 0
    searchTerm = '%20'
    orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_CLINPAYMENTTYPE,
    result,
  })
}

function* fetchClinPaymentTypeEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_CLINPAYMENTTYPE,
    result,
  })
}

function* fetchClinPaymentTypeSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_CLINPAYMENTTYPE_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CLINPAYMENTTYPES,
  })
}

function* fetchClinPaymentTypeDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_CLINPAYMENTTYPE_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CLINPAYMENTTYPES,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CLINPAYMENTTYPES, fetchClinPaymentTypes),
    takeEvery(actions.FETCH_CLINPAYMENTTYPE, fetchClinPaymentTypeEdit),
    takeEvery(actions.SAVE_CLINPAYMENTTYPE, fetchClinPaymentTypeSave),
    takeEvery(actions.DELETE_CLINPAYMENTTYPE, fetchClinPaymentTypeDelete),
    takeEvery(actions.RE_FETCH_CLINPAYMENTTYPES, fetchClinPaymentTypesRefresh),
  ])
}
