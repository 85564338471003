const actions = {
  REQUESTING: 'Proposals/REQUESTING',
  RESPONSE_ERROR: 'Proposals/RESPONSE_ERROR',

  FETCH_PROPOSALS: 'Proposals/FETCH',
  LIST_PROPOSAL: 'Proposal/LIST',

  FETCH_PROPOSAL: 'Proposal/FETCH',
  
  EDIT_PROPOSAL: 'Proposal/EDIT',

  RE_FETCH_PROPOSALS: 'Proposals/REFRESH',
  SAVE_PROPOSAL: 'Proposal/SAVE',
  SAVE_PROPOSAL_RECIEVED: 'Proposal/SAVE_PROPOSAL_RECIEVED',

  DELETE_PROPOSAL: 'Proposal/DELETE',
  DELETE_PROPOSAL_RECIEVED: 'Proposal/DELETE_RECIEVED',

  CREATE_PROPOSAL: 'CREATE_PROPOSAL',
  CREATE_PROPOSAL_RECIEVED: 'CREATE_PROPOSAL_RECIEVED',

  RESET_PROPOSAL: 'RESET_PROPOSAL',
  RESET_PROPOSAL_RECIEVED: 'RESET_PROPOSAL_RECIEVED',

  CREATE_RESET_PROPOSAL_RECIEVED:'CREATE_RESET_PROPOSAL_RECIEVED',
  CREATE_RESET_PROPOSAL: 'CREATE_RESET_PROPOSAL',

  Refresh: 'Refresh_Proposal',
  Refresh_Recieved: 'Refresh_Proposal_recieved',

  Refresh_reset: 'Refresh_reset',
  Refresh_reset_Recieved: 'Refresh_reset_Recieved',

  COPY_PROPOSAL_RECIEVED: 'COPY_PROPOSAL_RECIEVED',
  COPY_PROPOSAL: 'COPY_PROPOSAL',

  COPY_PROPOSAL_RESET_RECIEVED: 'COPY_PROPOSAL_RESET_RECIEVED',
  COPY_PROPOSAL_RESET: 'COPY_PROPOSAL_RESET',
  
  FETCH_COST_VIEW: 'Proposal/FETCH_COST_VIEW',
  FETCH_COST_VIEW_RECIEVED: 'Proposal/FETCH_COST_VIEW_RECIEVED'


}

export default actions
