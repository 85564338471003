import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Menu, Dropdown, Avatar, Badge } from 'antd'

import styles from './style.module.scss'

import actions from '../../../../redux/user/actions'
import actionsUser from '../../../../redux/userss/actions'
import ProfileEditModal from '../../../../pages/profile'

export default () => {
  const dispatch = useDispatch()
  const { user, userss } = useSelector(state => ({
    user: state.user,
    userss: state.userss,
  }))
  const logout = () => {
    localStorage.clear()
    dispatch({
      type: actions.LOGOUT,
    })
  }
  const openProfile = () => {
    dispatch({
      type: actionsUser.FETCH_PROFILE,
      id: user.id,
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item style={{ cursor: 'default' }}>
        <strong>
          <FormattedMessage id="hello" />, {user.name || 'Anonymous'}
        </strong>
        <div>{user.email}</div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={openProfile}>
          <i className={`${styles.menuIcon} fe fe-user`} />
          <FormattedMessage id="editProfile" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className={`${styles.menuIcon} fe fe-log-out`} />
          <FormattedMessage id="logout" />
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <div>
      {userss.profile && <ProfileEditModal />}
      
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Badge count={0}>
            <Avatar className={styles.avatar} shape="square" size="large">
              <i className={`${styles.menuIcon} fe fe-user`} />
            </Avatar>
          </Badge>
        </div>
      </Dropdown>
    </div>
  )
}
