import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import customers from './customers/reducers'
import countries from './country/reducers'
import postalcodes from './postalcode/reducers'
import contracts from './contracts/reducers'
import dropdowns from './dropdowns/reducers'
import contractBudgets from './contractBudgets/reducers'
import costTypeDefinitions from './costTypeDefinitions/reducers'
import costTypes from './costTypes/reducers'
import intervals from './intervals/reducers'
import suppliers from './suppliers/reducers'
import countrys from './countrys/reducers'
import postalCode2s from './postalCode2s/reducers'
import userss from './userss/reducers'
import grid from './grid/reducers'
import technicians from './technicians/reducers'
import workOrderTypes from './workOrderTypes/reducers'
import inventoryClasss from './inventoryClasss/reducers'
import inventoryClassCostTypes from './inventoryClassCostTypes/reducers'
import locations from './locations/reducers'
import areas from './areas/reducers'
import addresss from './addresss/reducers'
import devices from './devices/reducers'
import inventorys from './inventorys/reducers'
import workOrders from './workOrders/reducers'
import workOrderStatusForms from './workOrderStatusForms/reducers'
import inventoryServices from './inventoryServices/reducers'
import workOrderOperationCosts from './workOrderOperationCosts/reducers'
import reports from './reports/reducers'
import dashboard from './dashboard/reducers'
import workOrderTechnicianMaps from './workOrderTechnicianMaps/reducers'
import workOrderInventoryServiceMaps from './workOrderInventoryServiceMaps/reducers'
import parts from './parts/reducers'
import workorderpartmaps from './workorderpartmaps/reducers'
import invoices from './invoices/reducers'
import inventoryLogs from './inventoryLogs/reducers'
import inventoryProductInformations from './inventoryProductInformations/reducers'
import repairLimits from './repairLimits/reducers'
import inventoryServiceLabors from './inventoryServiceLabors/reducers'
import serviceRequests from './serviceRequests/reducers'
import clinYearPrices from './clinYearPrices/reducers'
import externalInvoiceLogs from './externalInvoiceLogs/reducers'
import technicianParts from './technicianParts/reducers'
import workOrderPhotos from './workOrderPhotos/reducers'
import tabletErrorLogs from './tabletErrorLogs/reducers'
import technicianDailyRecords from './technicianDailyRecords/reducers'
import technicianDailyReports from './technicianDailyReports/reducers'
import partOrders from './partOrders/reducers'
import partOrderArriveds from './partOrderArriveds/reducers'
import partTransfers from './partTransfers/reducers'
import workOrderCostTypeTotals from './workOrderCostTypeTotals/reducers'
import partTransferDelivereds from './partTransferDelivereds/reducers'
import mlfbDocuments from './mlfbDocuments/reducers'
import mlfbDocumentsFolder from './mlfbDocumentsFolder/reducers'
import partTransferRecieveds from './partTransferRecieveds/reducers'
import contractBudgetInventoryClasss from './contractBudgetInventoryClasss/reducers'
import workOrderInventoryLogs from './workOrderInventoryLogs/reducers'
import proposals from './proposals/reducers'
import proposalDetailMaterial from './proposalDetailMaterial/reducers'
import proposalDetailWorkingHours from './proposalDetailWorkingHours/reducers'
import proposalDetailPrePriceItem from './proposalDetailPrePriceItem/reducers'
import proposalFiles from './proposalFiles/reducers'
import clinPaymentTypes from './clinPaymentTypes/reducers'
import materialPriceDefinitions from './materialPriceDefinitions/reducers'
import contactPersons from './contactPersons/reducers'
import invoiceDetails from './invoiceDetails/reducers'

import workOrderTravelCosts from './workOrderTravelCosts/reducers'

import workOrderAdditionalCosts from './workOrderAdditionalCosts/reducers'

import clinBudgets from './clinBudgets/reducers'

import invoice2s from './invoice2s/reducers'

import invoice2Lines from './invoice2Lines/reducers'

import bankInfos from './bankInfos/reducers'

import emailDeliverys from './emailDeliverys/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    grid,
    dropdowns,
    customers,
    countries,
    postalcodes,
    contracts,
    contractBudgets,
    costTypeDefinitions,
    costTypes,
    intervals,
    suppliers,
    countrys,
    postalCode2s,
    userss,
    technicians,
    workOrderTypes,
    inventoryClasss,
    inventoryClassCostTypes,
    locations,
    areas,
    addresss,
    devices,
    inventorys,
    workOrders,
    workOrderStatusForms,
    inventoryServices,
    workOrderOperationCosts,
    reports,
    dashboard,
    workOrderTechnicianMaps,
    workOrderInventoryServiceMaps,
    parts,
    workorderpartmaps,
    invoices,
    inventoryLogs,
    inventoryProductInformations,
    repairLimits,
    inventoryServiceLabors,
    serviceRequests,
    clinYearPrices,
    externalInvoiceLogs,
    technicianParts,
    workOrderPhotos,
    tabletErrorLogs,
    technicianDailyRecords,
    technicianDailyReports,
    partOrders,
    partOrderArriveds,
    partTransfers,
    workOrderCostTypeTotals,
    partTransferDelivereds,
    mlfbDocuments,
    partTransferRecieveds,
    mlfbDocumentsFolder,
    contractBudgetInventoryClasss,
    workOrderInventoryLogs,
    proposals,
    proposalDetailMaterial,
    proposalDetailWorkingHours,
    proposalDetailPrePriceItem,
    proposalFiles,
    clinPaymentTypes,
    materialPriceDefinitions,
    contactPersons,
    invoiceDetails,

    workOrderTravelCosts,

  workOrderAdditionalCosts,

  clinBudgets,

  invoice2s,

  invoice2Lines,

  bankInfos,

  emailDeliverys,

})
