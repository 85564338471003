import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { find, save, remove, listByID } from '../../services/technicianDailyRecord'

function* fetchTechnicianDailyRecords({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(listByID, id)
  yield put({
    type: actions.LIST_TECHNICIANDAILYRECORD,
    result,
  })
}

function* fetchTechnicianDailyRecordEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_TECHNICIANDAILYRECORD,
    result,
  })
}

function* fetchTechnicianDailyRecordSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_TECHNICIANDAILYRECORD_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_TECHNICIANDAILYRECORDS,
    id: values.values.technicianDailyReportID
  })
}

function* fetchTechnicianDailyRecordDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_TECHNICIANDAILYRECORD_RECIEVED,
    result,
  })
  console.log(values);
  yield put({
    type: actions.RE_FETCH_TECHNICIANDAILYRECORDS,
    id: values.values.technicianDailyReportID
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_TECHNICIANDAILYRECORDS, fetchTechnicianDailyRecords),
    takeEvery(actions.FETCH_TECHNICIANDAILYRECORD, fetchTechnicianDailyRecordEdit),
    takeEvery(actions.SAVE_TECHNICIANDAILYRECORD, fetchTechnicianDailyRecordSave),
    takeEvery(actions.DELETE_TECHNICIANDAILYRECORD, fetchTechnicianDailyRecordDelete),
    takeEvery(actions.RE_FETCH_TECHNICIANDAILYRECORDS, fetchTechnicianDailyRecords),
  ])
}
