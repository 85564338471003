import actions from './actions'

export default function WorkOrderOperationCostsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
      }
    case actions.RECIEVED_OPERATION_COSTS:
      return {
        ...state,
        loading: false,
        costs: action.result
      }
    case actions.LIST_WORKORDEROPERATIONCOST:
      return {
        ...state,
        result: action.result,
        loading: false,
      }
    case actions.EDIT_WORKORDEROPERATIONCOST:
      return {
        ...state,
        editing: action.result,
        loading: false,
      }
    case actions.RESET_WORKORDEROPERATIONCOST:
      return {
        ...state,
        editing: null,
        loading: false,
        costs: null,
      }
      case actions.FETCH_OPERATION_COSTS_DELETE_ALL_RECIEVED:
        return {
          ...state,
          editing: null,
          loading: false,
          costs: null,
          result: null
        }
    case actions.NEW_WORKORDEROPERATIONCOST:
      return {
        ...state,
        editing: { workOrderOperationCostID: 0 },
        loading: false,
      }
    case actions.CREATE_WORKORDEROPERATIONCOST_RECIEVED ||
      actions.UPDATE_WORKORDEROPERATIONCOST_RECIEVED ||
      actions.DELETE_WORKORDEROPERATIONCOST_RECIEVED:
      return {
        ...state,
        editing: { workOrderOperationCostID: 0 },
        loading: false,
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      }
    case actions.AMOUNT_UPDATED:
      return {
        ...state,
        amount: action.result,
        editing: { workOrderOperationCostID: 0 },
        loading: false,
      }
    default:
      return state
  }
}
