import actions from './actions'

export default function AddresssReducer(state = { loading: false, lists: null }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_ADDRESS:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.FILTER_ADDRESS_RECIEVED:
      return {
        ...state,
        lists: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_ADDRESS:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_ADDRESS:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        lists: null
      }
    case actions.NEW_ADDRESS:
      return {
        ...state,
        editing: { addressID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_ADDRESS_RECIEVED ||
      actions.UPDATE_ADDRESS_RECIEVED ||
      actions.DELETE_ADDRESS_RECIEVED:
      return {
        ...state,
        editing: { addressID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
