const actions = {
  REQUESTING: 'MaterialPriceDefinitions/REQUESTING',
  RESPONSE_ERROR: 'MaterialPriceDefinitions/RESPONSE_ERROR',

  FETCH_MATERIALPRICEDEFINITIONS: 'MaterialPriceDefinitions/FETCH',
  LIST_MATERIALPRICEDEFINITION: 'MaterialPriceDefinition/LIST',

  FETCH_MATERIALPRICEDEFINITION: 'MaterialPriceDefinition/FETCH',
  NEW_MATERIALPRICEDEFINITION: 'MaterialPriceDefinition/NEW',
  EDIT_MATERIALPRICEDEFINITION: 'MaterialPriceDefinition/EDIT',
  RESET_MATERIALPRICEDEFINITION: 'MaterialPriceDefinition/RESET',

  RE_FETCH_MATERIALPRICEDEFINITIONS: 'MaterialPriceDefinitions/REFRESH',
  SAVE_MATERIALPRICEDEFINITION: 'MaterialPriceDefinition/SAVE',
  SAVE_MATERIALPRICEDEFINITION_RECIEVED: 'MaterialPriceDefinition/SAVE_MATERIALPRICEDEFINITION_RECIEVED',

  DELETE_MATERIALPRICEDEFINITION: 'MaterialPriceDefinition/DELETE',
  DELETE_MATERIALPRICEDEFINITION_RECIEVED: 'MaterialPriceDefinition/DELETE_RECIEVED',
}

export default actions
