import actions from './actions'

export default function PartTransferRecievedsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_PARTTRANSFERRECIEVED:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_PARTTRANSFERRECIEVED:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_PARTTRANSFERRECIEVED:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_PARTTRANSFERRECIEVED:
      return {
        ...state,
        editing: { partTransferRecievedID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_PARTTRANSFERRECIEVED_RECIEVED ||
      actions.UPDATE_PARTTRANSFERRECIEVED_RECIEVED ||
      actions.DELETE_PARTTRANSFERRECIEVED_RECIEVED:
      return {
        ...state,
        editing: { partTransferRecievedID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
