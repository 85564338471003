const actions = {
  REQUESTING: 'MlfbDocuments/REQUESTING',
  RESPONSE_ERROR: 'MlfbDocuments/RESPONSE_ERROR',

  FETCH_MLFBDOCUMENTS: 'MlfbDocuments/FETCH',
  LIST_MLFBDOCUMENT: 'MlfbDocument/LIST',

  FETCH_MLFBDOCUMENT: 'MlfbDocument/FETCH',
  NEW_MLFBDOCUMENT: 'MlfbDocument/NEW',
  EDIT_MLFBDOCUMENT: 'MlfbDocument/EDIT',
  RESET_MLFBDOCUMENT: 'MlfbDocument/RESET',

  RE_FETCH_MLFBDOCUMENTS: 'MlfbDocuments/REFRESH',
  SAVE_MLFBDOCUMENT: 'MlfbDocument/SAVE',
  SAVE_MLFBDOCUMENT_RECIEVED: 'MlfbDocument/SAVE_MLFBDOCUMENT_RECIEVED',

  DELETE_MLFBDOCUMENT: 'MlfbDocument/DELETE',
  DELETE_MLFBDOCUMENT_RECIEVED: 'MlfbDocument/DELETE_RECIEVED',
  
  SAVEPDF: 'MlfbDocument/SAVEPDF',
  SAVEPDF_REC: 'MlfbDocument/SAVEPDF_REC', 
}

export default actions
