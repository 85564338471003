const actions = {
  REQUESTING: 'BankInfos/REQUESTING',
  RESPONSE_ERROR: 'BankInfos/RESPONSE_ERROR',

  FETCH_BANKINFOS: 'BankInfos/FETCH',
  LIST_BANKINFO: 'BankInfo/LIST',

  FETCH_BANKINFO: 'BankInfo/FETCH',
  NEW_BANKINFO: 'BankInfo/NEW',
  EDIT_BANKINFO: 'BankInfo/EDIT',
  RESET_BANKINFO: 'BankInfo/RESET',

  RE_FETCH_BANKINFOS: 'BankInfos/REFRESH',
  SAVE_BANKINFO: 'BankInfo/SAVE',
  SAVE_BANKINFO_RECIEVED: 'BankInfo/SAVE_BANKINFO_RECIEVED',

  DELETE_BANKINFO: 'BankInfo/DELETE',
  DELETE_BANKINFO_RECIEVED: 'BankInfo/DELETE_RECIEVED',
}

export default actions
