/* eslint-disable */

import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/invoiceDetail'


function* fetchINVOICEDETAILs({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, id, undefined) 
  yield put({
    type: actions.LIST_INVOICEDETAIL,
    result,
  });
}

function* fetchINVOICEDETAILEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_INVOICEDETAIL,
    result,
  })
}

function* fetchINVOICEDETAILSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const resultSave = yield call(save, values)
  if (resultSave !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC });
    yield put({
      type: 'Refresh_Invoice'
    });
  }
}

function* fetchINVOICEDETAILDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC });
    yield put({
      type: 'Refresh_Invoice'
    });
  } 
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INVOICEDETAILS, fetchINVOICEDETAILs),
    takeEvery(actions.FETCH_INVOICEDETAIL, fetchINVOICEDETAILEdit),
    takeEvery(actions.SAVE_INVOICEDETAIL, fetchINVOICEDETAILSave),
    takeEvery(actions.DELETE_INVOICEDETAIL, fetchINVOICEDETAILDelete),
  ])
}
