const actions = {
  REQUESTING: 'InventoryClassCostTypes/REQUESTING',
  RESPONSE_ERROR: 'InventoryClassCostTypes/RESPONSE_ERROR',

  FETCH_INVENTORYCLASSCOSTTYPES: 'InventoryClassCostTypes/FETCH',
  LIST_INVENTORYCLASSCOSTTYPE: 'InventoryClassCostType/LIST',
  FETCH_INVENTORYCLASSCOSTTYPES_FILTER: 'InventoryClassCostTypes/FETCH_FILTER',

  FETCH_INVENTORYCLASSCOSTTYPE: 'InventoryClassCostType/FETCH',
  NEW_INVENTORYCLASSCOSTTYPE: 'InventoryClassCostType/NEW',
  EDIT_INVENTORYCLASSCOSTTYPE: 'InventoryClassCostType/EDIT',
  RESET_INVENTORYCLASSCOSTTYPE: 'InventoryClassCostType/RESET',

  RE_FETCH_INVENTORYCLASSCOSTTYPES: 'InventoryClassCostTypes/REFRESH',
  SAVE_INVENTORYCLASSCOSTTYPE: 'InventoryClassCostType/SAVE',
  SAVE_INVENTORYCLASSCOSTTYPE_RECIEVED:
    'InventoryClassCostType/SAVE_INVENTORYCLASSCOSTTYPE_RECIEVED',

  DELETE_INVENTORYCLASSCOSTTYPE: 'InventoryClassCostType/DELETE',
  DELETE_INVENTORYCLASSCOSTTYPE_RECIEVED: 'InventoryClassCostType/DELETE_RECIEVED',
}

export default actions
