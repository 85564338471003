export default async function getMenuData() {
  return [
    // {
    //   key: 'invoices',
    //   icon: 'fe fe-file-text',
    //   url: '/reports/invoice',
    // },
    {
      key: 'reportsworkorders',
      icon: 'fe fe-clipboard',
      url: '/reports/workorder',
    },
    {
      key: 'reportsinventory',
      icon: 'fe fe-hard-drive',
      url: '/reports/inventory',
    },
    // {
    //   key: 'reportinvoiceswogroup',
    //   icon: 'fe fe-bookmark',
    //   url: '/reports/wo-and-clin',
    // },
    {
      key: 'reporttechnician',
      icon: 'fe fe-git-branch',
      url: '/reports/technician',
    },
    {
      key: 'reportstatus',
      icon: 'fe fe-file',
      url: '/reports/status-report',
    },
    {
      key: 'reportpart',
      icon: 'fe fe-package',
      url: '/reports/part',
    },
    {
      key: 'clinYearPrices',
      icon: 'fe fe-shopping-cart',
      url: '/clin-years',
    },
    {
      key: 'externalinvoice',
      icon: 'fe fe-database',
      url: '/external-invoice',
    },  
    {
      key: 'completedworkorder',
      icon: 'fe fe-airplay',
      url: '/completed-workorders',
    }, 


  ]
}
