const actions = {
  REQUESTING: 'Countrys/REQUESTING',
  RESPONSE_ERROR: 'Countrys/RESPONSE_ERROR',

  FETCH_COUNTRY2S: 'Countrys/FETCH',
  LIST_COUNTRY2: 'Country/LIST',

  FETCH_COUNTRY2: 'Country/FETCH',
  NEW_COUNTRY2: 'Country/NEW',
  EDIT_COUNTRY2: 'Country/EDIT',
  RESET_COUNTRY2: 'Country/RESET',

  RE_FETCH_COUNTRY2S: 'Countrys/REFRESH',
  SAVE_COUNTRY2: 'Country/SAVE',
  SAVE_COUNTRY2_RECIEVED: 'Country/SAVE_COUNTRY2_RECIEVED',

  DELETE_COUNTRY2: 'Country/DELETE',
  DELETE_COUNTRY2_RECIEVED: 'Country/DELETE_RECIEVED',
}

export default actions
