import actions from './actions'

export default function userReducer(state = { loading: true }, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        menuData: action.payload,
        loading: false
      }

    case actions.SET_STATE2:
      return {
        ...state,
        menuData2: action.payload,
        loading: false
      }

      // parametrick
    case actions.GET_CONTRACT_MENU_RECIEVED:
      return {
        ...state,
        menuData2: action.payload,
        loading: false
      }


    case actions.SET_STATE3:
      return {
        ...state,
        menuData3: action.payload,
        loading: false
      }
      

    case actions.SET_STATE4:
      return {
        ...state,
        menuData4: action.payload,
        loading: false
      }

    default:
      return state
  }
}
