import actions from './actions'

export default function PartsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
        partsSelectList: null
      } 
    case actions.CONFIRM_MODAL_RESET_RECIEVER:
      return {
        ...state,
        confirm: undefined,
        confirmCatch: undefined,
      }
    case actions.CONFIRM_CHOOSE_OK_RECIEVER:
      return {
        ...state,
        confirmCatch: 1,
      }
    case actions.CONFIRM_MODAL_OPEN_RECIEVER:
      return {
        ...state,
        confirm: 1,
      }
    case actions.NEW_PART_STATUS_MODAL_RECIEVED:
      return {
        ...state,
        partManuelModal: 1,
        loading: false,
      }
    case actions.NEW_PART_STATUS_MODAL_RESET_RECIEVED:
      return {
        ...state,
        partManuelModal: null,
        loading: false,
      }
    case actions.SEARCH_REC:
      return {
        ...state,
        partsSelectList: action.result,
        loading: false,
      }
    case actions.SEARCH_REC_RESET_RECIEVED:
      return {
        ...state,
        partsSelectList: null,
        editing: null,
        loading: false,
      }
    case actions.LIST_PART:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_PART:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal

      }
    case actions.CLONE_PART_RECIEVED:
      return {
        ...state,
        editing: action.result,
        loading: false,
      }
    case actions.RESET_PART:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        openModal: null

      }
    case actions.NEW_PART:
      return {
        ...state,
        editing: { partID: 0 },
        loading: false,
        saveSuccess: false,
        openModal: true

      }
    case actions.DELETE_PART_RECIEVED:
      return {
        ...state,
        editing: { partID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
