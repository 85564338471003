import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, find2, createInvoice } from '../../services/invoice2'

function* fetchInvoice2sRefresh() {
  const fe = JSON.parse(localStorage.getItem('fetchInvoice2s'))
  if (fe == null) {
    fe.size = 10
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_INVOICE2,
    result,
  })
}

function* fetchInvoice2s({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchInvoice2s'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchInvoice2s', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_INVOICE2,
    result,
  })
}


function* fetchInvoice2sSilent({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchInvoice2s'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchInvoice2s', JSON.stringify({ size, index, searchTerm, orderBy }))
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_INVOICE2,
    result,
  })
}

function* fetchInvoice2Edit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_INVOICE2,
    result, 
  })
}

function* fetchInvoice2EditEOk({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find2, id)
  yield put({
    type: actions.EDIT_INVOICE2,
    result
  })
}

function* fetchInvoice2Save(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_INVOICE2_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVOICE2S,
  })
}

function* fetchInvoice2Delete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_INVOICE2_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVOICE2S,
  })
}

function* reds() {
  yield put({
    type: actions.RESET_INVOICE2_rec,
  }) 
}

function* createInvoiceFetch({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(createInvoice, id)
  yield put({
    type: actions.CREATE_INVOICE2_RECIEVED,
    result, 
  });



  const fe = JSON.parse(localStorage.getItem('fetchInvoice2s'))
  if (fe == null) {
    fe.size = 10
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result2 = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_INVOICE2,
    result:result2,
  })










}

function* resetInvoiceResetFetch() {
  yield put({
    type: actions.CREATE_INVOICE2_RESET,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INVOICE2S_SILENT, fetchInvoice2sSilent),
    takeEvery(actions.FETCH_INVOICE2S, fetchInvoice2s),
    takeEvery(actions.EDIT_EOK, fetchInvoice2EditEOk),  
    takeEvery(actions.FETCH_INVOICE2, fetchInvoice2Edit),
    takeEvery(actions.SAVE_INVOICE2, fetchInvoice2Save),
    takeEvery(actions.DELETE_INVOICE2, fetchInvoice2Delete),
    takeEvery(actions.RE_FETCH_INVOICE2S, fetchInvoice2sRefresh),
    takeEvery(actions.RESET_INVOICE2, reds),

    takeEvery(actions.CREATE_INVOICE2, createInvoiceFetch),
    takeEvery(actions.CREATE_INVOICE2_RESET, resetInvoiceResetFetch),

  ])
}
