const actions = {
  REQUESTING: 'WorkOrderAdditionalCosts/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderAdditionalCosts/RESPONSE_ERROR',

  FETCH_WORKORDERADDITIONALCOSTS: 'WorkOrderAdditionalCosts/FETCH',
  LIST_WORKORDERADDITIONALCOST: 'WorkOrderAdditionalCost/LIST',

  FETCH_WORKORDERADDITIONALCOST: 'WorkOrderAdditionalCost/FETCH',
  NEW_WORKORDERADDITIONALCOST: 'WorkOrderAdditionalCost/NEW',
  EDIT_WORKORDERADDITIONALCOST: 'WorkOrderAdditionalCost/EDIT',
  RESET_WORKORDERADDITIONALCOST: 'WorkOrderAdditionalCost/RESET',

  RE_FETCH_WORKORDERADDITIONALCOSTS: 'WorkOrderAdditionalCosts/REFRESH',
  SAVE_WORKORDERADDITIONALCOST: 'WorkOrderAdditionalCost/SAVE',
  SAVE_WORKORDERADDITIONALCOST_RECIEVED: 'WorkOrderAdditionalCost/SAVE_WORKORDERADDITIONALCOST_RECIEVED',

  DELETE_WORKORDERADDITIONALCOST: 'WorkOrderAdditionalCost/DELETE',
  DELETE_WORKORDERADDITIONALCOST_RECIEVED: 'WorkOrderAdditionalCost/DELETE_RECIEVED',
}

export default actions
