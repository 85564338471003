import { getRequest, postRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(
    `workOrderType/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`workOrderType/edit/${id}`)
}

export async function save(values) {
  return postRequest(`workOrderType/save`, values)
}

export function remove(values) {
  return postRequest(`workOrderType/delete`, values)
}
