export default function GridReducer(state = { reload: false }, action) {
  switch (action.type) {
    case 'grid/reload':
      return {
        ...state,
        reload: action,
      }
    default: return state
  }
}
