const actions = {
  REQUESTING: 'WorkOrderCostTypeTotals/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderCostTypeTotals/RESPONSE_ERROR',

  FETCH_WORKORDERCOSTTYPETOTALS: 'WorkOrderCostTypeTotals/FETCH',
  LIST_WORKORDERCOSTTYPETOTAL: 'WorkOrderCostTypeTotal/LIST',

  FETCH_WORKORDERCOSTTYPETOTAL: 'WorkOrderCostTypeTotal/FETCH',
  NEW_WORKORDERCOSTTYPETOTAL: 'WorkOrderCostTypeTotal/NEW',
  EDIT_WORKORDERCOSTTYPETOTAL: 'WorkOrderCostTypeTotal/EDIT',
  RESET_WORKORDERCOSTTYPETOTAL: 'WorkOrderCostTypeTotal/RESET',

  RE_FETCH_WORKORDERCOSTTYPETOTALS: 'WorkOrderCostTypeTotals/REFRESH',
  SAVE_WORKORDERCOSTTYPETOTAL: 'WorkOrderCostTypeTotal/SAVE',
  SAVE_WORKORDERCOSTTYPETOTAL_RECIEVED: 'WorkOrderCostTypeTotal/SAVE_WORKORDERCOSTTYPETOTAL_RECIEVED',

  DELETE_WORKORDERCOSTTYPETOTAL: 'WorkOrderCostTypeTotal/DELETE',
  DELETE_WORKORDERCOSTTYPETOTAL_RECIEVED: 'WorkOrderCostTypeTotal/DELETE_RECIEVED',
}

export default actions
