import actions from './actions'

export default function WorkOrdersReducer(state = { loading: false, editing: { workOrderID: 0 } }, action) {

  switch (action.type) {

    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        newWO: false,
        // editing: null,
      }

    case actions.ADD_INVENTORY_RECIEVED:
      console.log(action.payload);

      return {
        ...state,
        inventoryAdd: action.payload,
      }
      
    case actions.ADD_INVENTORY_RESET_RECIEVED:
      return {
        ...state,
        inventoryAdd: null,
      }      

    case actions.LIST_WORKORDER:
      return {
        ...state,
        editing: { workOrderID: 0 },
        result: action.result,
        loading: false,
      }

    case actions.EDIT_WORKORDER:
      return {
        ...state,
        editing: action.result,
        newWO: false,
        loading: false,
      }

    case actions.FETCH_NEXTCONTROLS_RECIEVED:
      return {
        ...state,
        nextControls: action.result,
        loading: false,
      }

    case actions.RESET_WORKORDER:
      return {
        ...state,
        editing: { workOrderID: 0 },
        loading: false,
        costs: null,
        newWO: false,
        assetHistory: null,
      }

    case actions.SAVE_WORKORDER_RECIEVED:
      return {
        ...state,
        loading: false,
        editing: action.result,
      }

    case actions.FETCH_WORKORDER_COSTVIEW_RECIEVED:
      return {
        ...state,
        loading: false,
        costViews: action.result,
      }

    case actions.NEW_WORKORDER:
      return {
        ...state,
        editing: { workOrderID: 0 },
        loading: false,
      }

    case actions.CREATE_WORKORDER_RECIEVED ||
      actions.UPDATE_WORKORDER_RECIEVED ||
      actions.DELETE_WORKORDER_RECIEVED:
      return {
        ...state,
        editing: { workOrderID: 0 },
        loading: false,
      }

    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveResulted: true,
        message: action.message,
      }

    case actions.CREATE_AUTO_WORKORDERS:
      return {
        ...state,
        loading: false,
      }

    case actions.SKIP_AUTO_WORKORDERS:
      return {
        ...state,
        loading: false,
      }

    case actions.CREATE_NEW_WORKORDER:
      return {
        ...state,
        newWO: false,
        editing: { workOrderID: 0 },
        loading: true,
      }

    case actions.GET_APPOINTMENT_DATE_RECIEVED:
      return {
        ...state,
        loading: false,
        appointmentDate: action.payload
      }

    case actions.GET_APPOINTMENT_DATE_RESET_RECIEVED:
      return {
        ...state,
        loading: false,
        appointmentDate: null
      }

    case actions.CREATE_NEW_WORKORDER_RECIEVED:
      return {
        ...state,
        newWO: true,
        editing: action.result,
        loading: false,
      }

    case actions.CREATE_NEW_WORKORDER_RESET_RECIEVED:
      return {
        ...state,
        newWO: false,
        recall: null,
      }

    case actions.FETCH_WORKORDER_ASSET_REPORT_RECIEVED:
      return {
        ...state,
        loading: false,
        assetHistory: {
          result: { items: action.result, count: action.result ? action.result.length : 0 },
        },
      }

    case actions.FETCH_WORKORDER_ASSET_REPORT_RESET_RECIEVED:
      return {
        ...state,
        loading: false,
        assetHistory: null,
        // assetHistoryCount: null
      }

    case actions.FETCH_WORKORDER_ASSET_COUNT_RECIEVED:
      return {
        ...state,
        loading: false,
        assetHistoryCount: action.result,
      }

    case actions.RECALL_WORKORDER_RECIEVED:
      return {
        ...state,
        loading: false,
        recall: action.result,
      }

    case actions.PRINT_POPUP_RECIEVED:
      return {
        ...state,
        loading: false,
        printPopup: true,
      }

    case actions.PRINT_POPUP_RESET_RECIEVED:
      return {
        ...state,
        loading: false,
        printPopup: false,
      }

    case actions.GET_COSTVIEW_RECIVEVED2:
      return {
        ...state,
        loading: false,
        costViews2: action.payload
      }



    default:
      return state
  }
}
