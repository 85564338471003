/* eslint-disable */


const actions = {
  REQUESTING: 'ProposalDetails/REQUESTING',
  RESPONSE_ERROR: 'ProposalDetails/RESPONSE_ERROR',

  FETCH_PROPOSALDETAIL_WORKING_HOURS: 'ProposalDetails/FETCH',
  LIST_PROPOSALDETAIL_WORKING_HOUR: 'ProposalDetail/LIST',

  FETCH_PROPOSALDETAIL_WORKING_HOUR: 'ProposalDetail/FETCH',
  NEW_PROPOSALDETAIL_WORKING_HOUR: 'ProposalDetail/NEW',
  EDIT_PROPOSALDETAIL_WORKING_HOUR: 'ProposalDetail/EDIT',
  RESET_PROPOSALDETAIL_WORKING_HOUR: 'ProposalDetail/RESET',

  RE_FETCH_PROPOSALDETAIL_WORKING_HOURS: 'ProposalDetails/REFRESH',
  SAVE_PROPOSALDETAIL_WORKING_HOUR: 'ProposalDetail/SAVE',
  SAVE_PROPOSALDETAIL_WORKING_HOUR_RECIEVED: 'ProposalDetail/SAVE_PROPOSALDETAIL_WORKING_HOUR_RECIEVED',

  DELETE_PROPOSALDETAIL_WORKING_HOUR: 'ProposalDetail/DELETE',
  DELETE_PROPOSALDETAIL_WORKING_HOUR_RECIEVED: 'ProposalDetail/DELETE_RECIEVED',

 
}

export default actions
