import actions from './actions'

export default function ServiceRequestsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_SERVICEREQUEST:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_SERVICEREQUEST:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_SERVICEREQUEST:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_SERVICEREQUEST:
      return {
        ...state,
        editing: { ServiceRequestID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_SERVICEREQUEST_RECIEVED ||
      actions.UPDATE_SERVICEREQUEST_RECIEVED ||
      actions.DELETE_SERVICEREQUEST_RECIEVED:
      return {
        ...state,
        editing: { ServiceRequestID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
