import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`;
  return getRequest(`contactPerson/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`contactPerson/edit/${id}`)
}

export async function save(values) {
  return postRequest(`contactPerson/save`, values)
}

export function remove(values) {
  return postRequest(`contactPerson/delete`, values)
}
