const actions = {
  REQUESTING: 'InventoryLogs/REQUESTING',
  RESPONSE_ERROR: 'InventoryLogs/RESPONSE_ERROR',

  FETCH_INVENTORYLOGS: 'InventoryLogs/FETCH',
  LIST_INVENTORYLOG: 'InventoryLog/LIST',

  FETCH_INVENTORYLOG: 'InventoryLog/FETCH',
  NEW_INVENTORYLOG: 'InventoryLog/NEW',
  EDIT_INVENTORYLOG: 'InventoryLog/EDIT',
  RESET_INVENTORYLOG: 'InventoryLog/RESET',
  RESETLIST_INVENTORYLOG: 'InventoryLog/RESETLIST',

  RE_FETCH_INVENTORYLOGS: 'InventoryLogs/REFRESH',
  SAVE_INVENTORYLOG: 'InventoryLog/SAVE',
  SAVE_INVENTORYLOG_RECIEVED: 'InventoryLog/SAVE_INVENTORYLOG_RECIEVED',

  DELETE_INVENTORYLOG: 'InventoryLog/DELETE',
  DELETE_INVENTORYLOG_RECIEVED: 'InventoryLog/DELETE_RECIEVED',
}

export default actions
