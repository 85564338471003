export default {
  locale: 'de-DE',
  isSidebarOpen: false,
  isSupportChatOpen: false,
  isMobileView: false,
  isMobileMenuOpen: false,
  isMenuCollapsed: false,
  isMenuShadow: false,
  isMenuUnfixed: false,
  menuLayoutType: 'top-dark', // left, top, top-dark, nomenu
  menuType: 'default', // default, flyout, compact
  menuColor: 'dark', // dark, blue, gray, white
  flyoutMenuColor: 'blue', // dark, blue, gray, white
  systemLayoutColor: 'gray', // white, dark, blue, gray, image
  isTopbarFixed: true,
  isFooterDark: false,
  isContentNoMaxWidth: true,
  isAppMaxWidth: false,
  isGrayBackground: false,
  isGrayTopbar: true,
  isCardShadow: false,
  isSquaredBorders: false,
  isBorderless: false,
  routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
}
