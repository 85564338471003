import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, quantity, getManuelPartEdit, saveMaterial, deleteMaterial } from '../../services/workorderpartmap'

function* fetchWorkOrderPartMapsRefresh() {
  let fe = JSON.parse(localStorage.getItem('fetchWorkOrderPartMaps'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  } else {
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_WORKORDERPARTMAP,
    result,
  })
}

function* fetchWorkOrderPartMaps({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = null // JSON.parse(localStorage.getItem('fetchWorkOrderPartMaps'))
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem(
    'fetchWorkOrderPartMaps',
    JSON.stringify({ size, index, searchTerm, orderBy }),
  )
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_WORKORDERPARTMAP,
    result,
  })
}

function* fetchWorkOrderPartMapEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_WORKORDERPARTMAP,
    result,
  })
}

function* fetchWorkOrderPartMapSave(values) {
  yield put({
    type: actions.REQUESTING,
  })

  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }

  yield put({
    type: actions.SAVE_WORKORDERPARTMAP_RECIEVED,
    result,
  })
  yield put({
    type: actions.AMOUNT_UPDATED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDERPARTMAPS,
  });

  yield put({ type: 'WorkOrder/GET_COSTVIEW2', id: values.values.workOrderID });
  yield put({ type: 'WorkOrders/FETCH_WORKORDER_COSTVIEW', id: values.values.workOrderID });
  yield put({ type: 'WorkOrderOperationCosts/FETCH' });
  yield put({ type: 'WorkOrderAdditionalCosts/FETCH' });
}

function* fetchWorkOrderPartMapDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_WORKORDERPARTMAP_RECIEVED,
    result,
  })
  yield put({
    type: actions.AMOUNT_UPDATED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDERPARTMAPS,
  });
  yield put({ type: 'WorkOrder/GET_COSTVIEW2', id: values.values.workOrderID });
  yield put({ type: 'WorkOrders/FETCH_WORKORDER_COSTVIEW', id: values.values.workOrderID });
  yield put({ type: 'WorkOrderOperationCosts/FETCH' });
  yield put({ type: 'WorkOrderAdditionalCosts/FETCH' });
}

function* fetchWorkOrderPartMapQuantity({ id, location }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(quantity, id, location)
  yield put({
    type: actions.FETCH_WORKORDERPARTMAPS_QTY_RECIEVED,
    result,
  })
}

function* fetchManuelPartEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  });
  const result = yield call(getManuelPartEdit, id)
  yield put({
    type: actions.FETCH_MANUEL_PART_EDIT_RECIEVED,
    result,
  })
}

function* fetchManuelPartReset() {
  yield put({
    type: actions.FETCH_MANUEL_PART_RESET_RECIEVED,
  });
}


function* saveWorkOrderPartMapSave(values) {
  yield put({
    type: actions.REQUESTING,
  })

  const result = yield call(saveMaterial, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }

  yield put({
    type: actions.SAVE_WORKORDERPARTMAP_RECIEVED,
    result,
  });
  yield put({
    type: actions.AMOUNT_UPDATED,
    result,
  });
  yield put({
    type: actions.RE_FETCH_WORKORDERPARTMAPS,
  });

  yield put({ type: 'WorkOrder/GET_COSTVIEW2', id: values.values.workOrderID });
  yield put({ type: 'WorkOrders/FETCH_WORKORDER_COSTVIEW', id: values.values.workOrderID });
  yield put({ type: 'WorkOrderOperationCosts/FETCH' });
  yield put({ type: 'WorkOrderAdditionalCosts/FETCH' });
  yield put({ type: 'FETCH_WORKORDER_BUDGETS', workOrderID: values.values.workOrderID, contractID: values.values.contractID });
}

function* deleteMaterial2(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(deleteMaterial, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_MATERIAL_RECIEVED,
    result,
  })
  yield put({
    type: actions.AMOUNT_UPDATED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDERPARTMAPS,
  });
  yield put({ type: 'WorkOrder/GET_COSTVIEW2', id: values.values.workOrderID });
  yield put({ type: 'WorkOrders/FETCH_WORKORDER_COSTVIEW', id: values.values.workOrderID });
  yield put({ type: 'WorkOrderOperationCosts/FETCH' });
  yield put({ type: 'WorkOrderAdditionalCosts/FETCH' });
  yield put({ type: 'FETCH_WORKORDER_BUDGETS', workOrderID: values.values.workOrderID, contractID: values.values.contractID });
}



export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_WORKORDERPARTMAPS, fetchWorkOrderPartMaps),
    takeEvery(actions.FETCH_WORKORDERPARTMAP, fetchWorkOrderPartMapEdit),
    takeEvery(actions.SAVE_WORKORDERPARTMAP, fetchWorkOrderPartMapSave),
    takeEvery(actions.DELETE_WORKORDERPARTMAP, fetchWorkOrderPartMapDelete),
    takeEvery(actions.DELETE_MATERIAL, deleteMaterial2),

    takeEvery(actions.RE_FETCH_WORKORDERPARTMAPS, fetchWorkOrderPartMapsRefresh),
    takeEvery(actions.FETCH_WORKORDERPARTMAPS_QTY, fetchWorkOrderPartMapQuantity),
    takeEvery(actions.FETCH_MANUEL_PART_EDIT, fetchManuelPartEdit),
    takeEvery(actions.FETCH_MANUEL_PART_RESET, fetchManuelPartReset),
    takeEvery(actions.SAVE_MANUEL_PART_EDIT, saveWorkOrderPartMapSave),

  ])
}
