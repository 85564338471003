import actions from './actions'

export default function ClinBudgetsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_CLINBUDGET:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_CLINBUDGET:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_CLINBUDGET:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }


    case actions.RESET_WORKORDER_BUDGETS_RECIEVED:
      return {
        ...state,
        workOrderBudgets: null,
        loading: false
      }
    case actions.FETCH_WORKORDER_BUDGETS_RECIEVED:
      return {
        ...state,
        loading: false,
        workOrderBudgets: action.result,
      }



    case actions.NEW_CLINBUDGET:
      return {
        ...state,
        editing: { ClinBudgetID: 0 },
        loading: false,
        saveSuccess: false,
      }

    case actions.CREATE_CLINBUDGET_RECIEVED ||
      actions.UPDATE_CLINBUDGET_RECIEVED ||
      actions.DELETE_CLINBUDGET_RECIEVED:
      return {
        ...state,
        editing: { ClinBudgetID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
