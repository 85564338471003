import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import { list3 } from '../../services/mlfbDocument'
  
function* fetchMlfbDocuments({
  size,
  index,
  searchTerm,
  orderBy,
  partNumber,
  mlfb,
  parentID,
  assetClassID
}) {
  const t = localStorage.getItem('fetchmlfbDocumentsFilter2')
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        partNumber = fe.partNumber || partNumber
        mlfb = fe.mlfb || mlfb
        parentID = fe.parentID || parentID
        assetClassID = fe.assetClassID || assetClassID
      }
    } catch (e) {
      console.log(e)
    }
  }

  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(
      'fetchmlfbDocumentsFilter2',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        partNumber,
        mlfb,
        parentID,
        assetClassID
      }),
    )
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    list3,
    size,
    index,
    searchTerm,
    orderBy,
    partNumber,
    mlfb,
    parentID,
    assetClassID
  )
  yield put({
    type: actions.LIST_MLFBDOCUMENT2,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_MLFBDOCUMENTS2, fetchMlfbDocuments),
    takeEvery(actions.RE_FETCH_MLFBDOCUMENTS2, fetchMlfbDocuments),
    
  ])
}
