const actions = {
  REQUESTING: 'PartTransferDelivereds/REQUESTING',
  RESPONSE_ERROR: 'PartTransferDelivereds/RESPONSE_ERROR',

  FETCH_PARTTRANSFERARRIVEDS: 'PartTransferDelivereds/FETCH',
  LIST_PARTTRANSFERARRIVED: 'PartTransferDelivered/LIST',

  FETCH_PARTTRANSFERARRIVED: 'PartTransferDelivered/FETCH',
  NEW_PARTTRANSFERARRIVED: 'PartTransferDelivered/NEW',
  EDIT_PARTTRANSFERARRIVED: 'PartTransferDelivered/EDIT',
  RESET_PARTTRANSFERARRIVED: 'PartTransferDelivered/RESET',

  RE_FETCH_PARTTRANSFERARRIVEDS: 'PartTransferDelivereds/REFRESH',
  SAVE_PARTTRANSFERARRIVED: 'PartTransferDelivered/SAVE',
  SAVE_PARTTRANSFERARRIVED_RECIEVED: 'PartTransferDelivered/SAVE_PARTTRANSFERARRIVED_RECIEVED',

  DELETE_PARTTRANSFERARRIVED: 'PartTransferDelivered/DELETE',
  DELETE_PARTTRANSFERARRIVED_RECIEVED: 'PartTransferDelivered/DELETE_RECIEVED',

  UPDATE_QUANTITY: 'PartTransferDelivered/UPDATE_QUANTITY',
  UPDATE_QUANTITY_RECIEVED: 'PartTransferDelivered/UPDATE_QUANTITY_RECIEVED',
}

export default actions
