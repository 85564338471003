import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, deleteAll } from '../../services/workOrderOperationCost'
import { getCosts } from '../../services/inventoryClassCostType'

function* fetchWorkOrderOperationCostsRefresh() {
  let fe = JSON.parse(localStorage.getItem('fetchWorkOrderOperationCosts'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  } else {
    fe = {}
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_WORKORDEROPERATIONCOST,
    result,
  })
}

function* fetchWorkOrderOperationCosts({ size, index, searchTerm, orderBy }) {
  
  // Load last params froom last session.
  
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = null // JSON.parse(localStorage.getItem('fetchWorkOrderOperationCosts'))
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
 
  localStorage.setItem(
    'fetchWorkOrderOperationCosts',
    JSON.stringify({ size, index, searchTerm, orderBy }),
  );

  yield put({
    type: actions.REQUESTING,
  });

  const result = yield call(list, size, index, searchTerm, orderBy)
  
  yield put({
    type: actions.LIST_WORKORDEROPERATIONCOST,
    result,
  });

}

function* fetchWorkOrderOperationCostEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_WORKORDEROPERATIONCOST,
    result,
  })
}

function* fetchWorkOrderOperationCostSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.AMOUNT_UPDATED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDEROPERATIONCOSTS,
  })
}

function* fetchWorkOrderOperationCostDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.AMOUNT_UPDATED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDEROPERATIONCOSTS,
  })
}

function* fetchWorkOrderOperationCostsByClassIDAndDate({ classID, date }) {
  const result = yield call(getCosts, classID, date)
  yield put({
    type: actions.RECIEVED_OPERATION_COSTS,
    result,
  })
}

function* fetchWorkOrderOperationCostsDeleteAll() {
  yield call(deleteAll)
  yield put({
    type: actions.FETCH_OPERATION_COSTS_DELETE_ALL_RECIEVED,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_WORKORDEROPERATIONCOSTS, fetchWorkOrderOperationCosts),
    takeEvery(actions.FETCH_WORKORDEROPERATIONCOST, fetchWorkOrderOperationCostEdit),
    takeEvery(actions.SAVE_WORKORDEROPERATIONCOST, fetchWorkOrderOperationCostSave),
    takeEvery(actions.DELETE_WORKORDEROPERATIONCOST, fetchWorkOrderOperationCostDelete),
    takeEvery(actions.RE_FETCH_WORKORDEROPERATIONCOSTS, fetchWorkOrderOperationCostsRefresh),
    takeEvery(actions.FETCH_OPERATION_COSTS, fetchWorkOrderOperationCostsByClassIDAndDate),
    takeEvery(actions.FETCH_OPERATION_COSTS_DELETE_ALL, fetchWorkOrderOperationCostsDeleteAll),
  ])
}
