import { getRequest, postRequest,getPartTransferID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('PartTransferID'))
  searchTerm = `${searchTerm}, PartTransferID:${getPartTransferID()},`
  return getRequest(`partTransferDelivered/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`partTransferDelivered/edit/${id}`)
}

export async function save(values) {
  return postRequest(`partTransferDelivered/save`, values)
}

export function remove(values) {
  return postRequest(`partTransferDelivered/delete`, values)
}

export function updateQuantity(id) {
  return getRequest(`partTransferDelivered/update-quantity/${id}`)
}
