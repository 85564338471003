const actions = {
  REQUESTING: 'Technicians/REQUESTING',
  RESPONSE_ERROR: 'Technicians/RESPONSE_ERROR',

  FETCH_TECHNICIANS: 'Technicians/FETCH',
  LIST_TECHNICIAN: 'Technician/LIST',

  FETCH_TECHNICIAN: 'Technician/FETCH',
  NEW_TECHNICIAN: 'Technician/NEW',
  EDIT_TECHNICIAN: 'Technician/EDIT',
  RESET_TECHNICIAN: 'Technician/RESET',

  RE_FETCH_TECHNICIANS: 'Technicians/REFRESH',
  SAVE_TECHNICIAN: 'Technician/SAVE',
  SAVE_TECHNICIAN_RECIEVED: 'Technician/SAVE_TECHNICIAN_RECIEVED',

  DELETE_TECHNICIAN: 'Technician/DELETE',
  DELETE_TECHNICIAN_RECIEVED: 'Technician/DELETE_RECIEVED',
}

export default actions
