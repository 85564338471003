const actions = {
  REQUESTING: 'MlfbDocuments/REQUESTING',
  RESPONSE_ERROR: 'MlfbDocuments/RESPONSE_ERROR',

  RE_FETCH_MLFBDOCUMENTS2: 'MlfbDocuments/REFRESH123212',
  FETCH_MLFBDOCUMENTS2: 'MlfbDocuments/FETCH2232',
  LIST_MLFBDOCUMENT2: 'MlfbDocument/LIST221321',

}

export default actions
