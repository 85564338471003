const actions = {
  REQUESTING: 'PartOrders/REQUESTING',
  RESPONSE_ERROR: 'PartOrders/RESPONSE_ERROR',

  FETCH_PARTORDERS: 'PartOrders/FETCH',
  LIST_PARTORDER: 'PartOrder/LIST',

  FETCH_PARTORDER: 'PartOrder/FETCH',
  NEW_PARTORDER: 'PartOrder/NEW',
  EDIT_PARTORDER: 'PartOrder/EDIT',
  RESET_PARTORDER: 'PartOrder/RESET',

  RE_FETCH_PARTORDERS: 'PartOrders/REFRESH',
  SAVE_PARTORDER: 'PartOrder/SAVE',
  SAVE_PARTORDER_RECIEVED: 'PartOrder/SAVE_PARTORDER_RECIEVED',

  DELETE_PARTORDER: 'PartOrder/DELETE',
  DELETE_PARTORDER_RECIEVED: 'PartOrder/DELETE_RECIEVED',

  FETCH_PARTORDER2: 'PartOrder/FETCH_PARTORDER2',
  FETCH_PARTORDER2_REC: 'PartOrder/FETCH_PARTORDER2_REC',

  SAVEPDF: 'PartOrder/SAVEPDF',
  SAVEPDF_RECIEVED: 'PartOrder/SAVEPDF_RECIEVED'

}

export default actions
