import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list2, find, save, remove, export2 } from '../../services/partTransfer'

function* fetchPartTransfers({
  size,
  index,
  searchTerm,
  orderBy,
  partID,
  status,
  requestDate,
  transferDate,
  deliveredDate,
  model,
  mlfb,
  manufacturer,
  manufacturerNr,
  owner,
  description,
  excel
}) {

  const t = localStorage.getItem('fetchPartTransfersFilter')
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        partID = fe.partID || partID
        status = fe.status || status
        requestDate = fe.requestDate || requestDate
        transferDate = fe.transferDate || transferDate
        deliveredDate = fe.deliveredDate || deliveredDate
        manufacturer = fe.manufacturer || manufacturer
        manufacturerNr = fe.manufacturerNr || manufacturerNr
        model = fe.model || model
        mlfb = fe.mlfb || mlfb
        description = fe.description || description
        owner = fe.owner || owner
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(
      'fetchPartTransfersFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        partID,
        status,
        requestDate,
        transferDate,
        deliveredDate,
        model,
        mlfb,
        manufacturer,
        manufacturerNr,
        owner,
        description
      }),
    )
  } catch (e) {
    console.log(e)
  }

  if (excel !== undefined && excel === true) {
    yield call(
      export2,
      partID,
      status,
      requestDate,
      transferDate,
      deliveredDate,
      model,
      mlfb,
      manufacturer,
      manufacturerNr,
      owner,
      description
    )
  } else {
    yield put({
      type: actions.REQUESTING,
    })
    const result = yield call(
      list2,
      size,
      index,
      searchTerm,
      orderBy,
      partID,
      status,
      requestDate,
      transferDate,
      deliveredDate,
      model,
      mlfb,
      manufacturer,
      manufacturerNr,
      owner,
      description
    )
    yield put({
      type: actions.LIST_PARTTRANSFER,
      result,
    })
  }
}


function* fetchPartTransferEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PARTTRANSFER,
    result,
  })
}

function* fetchPartTransferSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_PARTTRANSFER_RECIEVED,
    result,
  })
  yield put({
    type: actions.FETCH_PARTTRANSFERS,
  })
}

function* fetchPartTransferDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_PARTTRANSFER_RECIEVED,
    result,
  })
  yield put({
    type: actions.FETCH_PARTTRANSFERS,
  })
}


function* fetchPartTransferEdit2({ id }) {
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PARTTRANSFER,
    result,
  })
}



export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_PARTTRANSFER2, fetchPartTransferEdit2),
    takeEvery(actions.FETCH_PARTTRANSFERS, fetchPartTransfers),
    takeEvery(actions.FETCH_PARTTRANSFER, fetchPartTransferEdit),
    takeEvery(actions.SAVE_PARTTRANSFER, fetchPartTransferSave),
    takeEvery(actions.DELETE_PARTTRANSFER, fetchPartTransferDelete),
  ])
}
