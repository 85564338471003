export default async function getMenuData2() {
  return [
    {
      key: 'contracts',
      icon: 'fe fe-file-text',
      url: '/contracts',
    },
   
    // {
    //   key: 'interval',
    //   icon: 'fe fe-clock',
    //   url: '/intervals',
    // },
    // {
    //   key: 'costtypedefinition',
    //   icon: 'fe fe-command',
    //   url: '/costtypedefinitions',
    // },
    // {
    //   key: 'workordertypes',
    //   icon: 'fe fe-airplay',
    //   url: '/workordertypes',
    // },
    {
      icon: 'fe fe-database',
      key: 'customers',
      url: '/customers',
    },
    {
      key: 'bankInfos',
      icon: 'fe fe-airplay',
      url: '/bankInfos',
      role: 'admin',
    }, 
    {
      key: 'userandroles',
      icon: 'fe fe-users',
      url: '/users',
      role: 'admin',
    }, 
    {
      icon: 'fe fe-mail',
      key: 'emailDeliverys',
      url: '/emailDeliverys',
    },
    {
      icon: 'fe fe-send',
      key: 'pushnotifications',
      url: '/pushnotifications',
    },
  ]
}
