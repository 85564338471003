const actions = {
  REQUESTING: 'PartTransferRecieveds/REQUESTING',
  RESPONSE_ERROR: 'PartTransferRecieveds/RESPONSE_ERROR',

  FETCH_PARTTRANSFERRECIEVEDS: 'PartTransferRecieveds/FETCH',
  LIST_PARTTRANSFERRECIEVED: 'PartTransferRecieved/LIST',

  FETCH_PARTTRANSFERRECIEVED: 'PartTransferRecieved/FETCH',
  NEW_PARTTRANSFERRECIEVED: 'PartTransferRecieved/NEW',
  EDIT_PARTTRANSFERRECIEVED: 'PartTransferRecieved/EDIT',
  RESET_PARTTRANSFERRECIEVED: 'PartTransferRecieved/RESET',

  RE_FETCH_PARTTRANSFERRECIEVEDS: 'PartTransferRecieveds/REFRESH',
  SAVE_PARTTRANSFERRECIEVED: 'PartTransferRecieved/SAVE',
  SAVE_PARTTRANSFERRECIEVED_RECIEVED: 'PartTransferRecieved/SAVE_PARTTRANSFERRECIEVED_RECIEVED',

  DELETE_PARTTRANSFERRECIEVED: 'PartTransferRecieved/DELETE',
  DELETE_PARTTRANSFERRECIEVED_RECIEVED: 'PartTransferRecieved/DELETE_RECIEVED',

  UPDATE_QUANTITY: 'PartTransferRecieved/UPDATE_QUANTITY',
  UPDATE_QUANTITY_RECIEVED: 'PartTransferRecieved/UPDATE_QUANTITY_RECIEVED',
}

export default actions
