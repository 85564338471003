const actions = {
  REQUESTING: 'WorkOrderTechnicianMaps/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderTechnicianMaps/RESPONSE_ERROR',

  FETCH_WORKORDERTECHNICIANMAPS: 'WorkOrderTechnicianMaps/FETCH',
  LIST_WORKORDERTECHNICIANMAP: 'WorkOrderTechnicianMap/LIST',

  FETCH_WORKORDERTECHNICIANMAP: 'WorkOrderTechnicianMap/FETCH',
  NEW_WORKORDERTECHNICIANMAP: 'WorkOrderTechnicianMap/NEW',
  EDIT_WORKORDERTECHNICIANMAP: 'WorkOrderTechnicianMap/EDIT',
  RESET_WORKORDERTECHNICIANMAP: 'WorkOrderTechnicianMap/RESET',

  RE_FETCH_WORKORDERTECHNICIANMAPS: 'WorkOrderTechnicianMaps/REFRESH',
  SAVE_WORKORDERTECHNICIANMAP: 'WorkOrderTechnicianMap/SAVE',
  SAVE_WORKORDERTECHNICIANMAP_RECIEVED: 'WorkOrderTechnicianMap/SAVE_WORKORDERTECHNICIANMAP_RECIEVED',

  DELETE_WORKORDERTECHNICIANMAP: 'WorkOrderTechnicianMap/DELETE',
  DELETE_WORKORDERTECHNICIANMAP_RECIEVED: 'WorkOrderTechnicianMap/DELETE_RECIEVED',
}

export default actions
