const actions = {
  REQUESTING: 'PostalCodes/REQUESTING',
  RESPONSE_ERROR: 'PostalCodes/RESPONSE_ERROR',

  FETCH_POSTALCODES: 'PostalCodes/FETCH',
  LIST_POSTALCODE: 'PostalCode/LIST',

  FETCH_POSTALCODE: 'PostalCode/FETCH',
  NEW_POSTALCODE: 'PostalCode/NEW',
  EDIT_POSTALCODE: 'PostalCode/EDIT',
  RESET_POSTALCODE: 'PostalCode/RESET',

  RE_FETCH_POSTALCODES: 'PostalCodes/REFRESH',
  SAVE_POSTALCODE: 'PostalCode/SAVE',
  SAVE_POSTALCODE_RECIEVED: 'PostalCode/SAVE_POSTALCODE_RECIEVED',

  DELETE_POSTALCODE: 'PostalCode/DELETE',
  DELETE_POSTALCODE_RECIEVED: 'PostalCode/DELETE_RECIEVED',
}

export default actions
