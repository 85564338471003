import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID')) searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`;
  return getRequest(`mlfbDocument/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`mlfbDocument/edit/${id}`)
}

export async function save(values) {
  return postRequest(`mlfbDocument/save`, values)
}

export function remove(values) {
  return postRequest(`mlfbDocument/delete`, values)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy, 
  partNumber,  
  mlfb, 
  parentID,
  assetClassID
) {
  const contractID = getcontractIdNumber()
  const url = `mlfbDocument/get-list2/${size || '15'}/${index || '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${contractID}/${partNumber || '%20'}/${mlfb || '%20'}/${parentID || '%20'}/${assetClassID || '%20'}`
  return getRequest(url)
}
 
export async function list3(
  size,
  index,
  searchTerm,
  orderBy, 
  partNumber,  
  mlfb, 
  parentID,
  assetClassID
) {
  const contractID = getcontractIdNumber()
  const url = `mlfbDocument/get-list3/${size || '15'}/${index || '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${contractID}/${partNumber || '%20'}/${mlfb || '%20'}/${parentID || '%20'}/${assetClassID || '%20'}`
  return getRequest(url)
}
 