const actions = {
  REQUESTING: 'WorkOrderInventoryServiceMaps/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderInventoryServiceMaps/RESPONSE_ERROR',
  AMOUNT_UPDATED: 'WorkOrderInventoryServiceMaps/AMOUNT_UPDATED',

  FETCH_WORKORDERINVENTORYSERVICEMAPS: 'WorkOrderInventoryServiceMaps/FETCH',
  LIST_WORKORDERINVENTORYSERVICEMAP: 'WorkOrderInventoryServiceMap/LIST',

  FETCH_WORKORDERINVENTORYSERVICEMAP: 'WorkOrderInventoryServiceMap/FETCH',
  NEW_WORKORDERINVENTORYSERVICEMAP: 'WorkOrderInventoryServiceMap/NEW',
  EDIT_WORKORDERINVENTORYSERVICEMAP: 'WorkOrderInventoryServiceMap/EDIT',
  RESET_WORKORDERINVENTORYSERVICEMAP: 'WorkOrderInventoryServiceMap/RESET',

  RE_FETCH_WORKORDERINVENTORYSERVICEMAPS: 'WorkOrderInventoryServiceMaps/REFRESH',
  SAVE_WORKORDERINVENTORYSERVICEMAP: 'WorkOrderInventoryServiceMap/SAVE',
  SAVE_WORKORDERINVENTORYSERVICEMAP_RECIEVED:
    'WorkOrderInventoryServiceMap/SAVE_WORKORDERINVENTORYSERVICEMAP_RECIEVED',

  DELETE_WORKORDERINVENTORYSERVICEMAP: 'WorkOrderInventoryServiceMap/DELETE',
  DELETE_WORKORDERINVENTORYSERVICEMAP_RECIEVED: 'WorkOrderInventoryServiceMap/DELETE_RECIEVED',
}

export default actions
