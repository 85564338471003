import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, getWorkOrderBudgets } from '../../services/clinBudget'

function* fetchClinBudgetsRefresh() {
  const fe = JSON.parse(localStorage.getItem('fetchClinBudgets'))
  if (fe == null) {
    fe.size = 10
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_CLINBUDGET,
    result,
  })
}

function* fetchClinBudgets({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchClinBudgets'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchClinBudgets', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_CLINBUDGET,
    result,
  });
}

function* fetchClinBudgetEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_CLINBUDGET,
    result,
  })
}

function* fetchClinBudgetSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_CLINBUDGET_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CLINBUDGETS,
  })
}

function* fetchClinBudgetDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_CLINBUDGET_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CLINBUDGETS,
  })
}

function* fetchClinBudget({ workOrderID, contractID }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getWorkOrderBudgets, workOrderID, contractID)
  yield put({
    type: actions.FETCH_WORKORDER_BUDGETS_RECIEVED,
    result,
  })
}

function* resetClinBudget() {
  yield put({
    type: actions.RESET_WORKORDER_BUDGETS_RECIEVED,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CLINBUDGET, fetchClinBudgetEdit),
    takeEvery(actions.FETCH_CLINBUDGETS, fetchClinBudgets),
    takeEvery(actions.SAVE_CLINBUDGET, fetchClinBudgetSave),
    takeEvery(actions.DELETE_CLINBUDGET, fetchClinBudgetDelete),
    takeEvery(actions.RE_FETCH_CLINBUDGETS, fetchClinBudgetsRefresh),

    takeEvery(actions.FETCH_WORKORDER_BUDGETS, fetchClinBudget),
    takeEvery(actions.RESET_WORKORDER_BUDGETS, resetClinBudget),
  ]);
}
