import actions from './actions'

export default function WorkOrderPhotosReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_WORKORDERPHOTO:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_WORKORDERPHOTO:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_WORKORDERPHOTO:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_WORKORDERPHOTO:
      return {
        ...state,
        editing: { workOrderPhotoID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_WORKORDERPHOTO_RECIEVED ||
      actions.UPDATE_WORKORDERPHOTO_RECIEVED ||
      actions.DELETE_WORKORDERPHOTO_RECIEVED:
      return {
        ...state,
        editing: { workOrderPhotoID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
