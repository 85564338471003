import { all, takeEvery, put, call } from 'redux-saga/effects'
import getDropdown from '../../services/dropdown'
import actions from './actions'

function* fetchDropdown({
  entity,
  date,
  classID,
  appropriation,
  searchValue,
  areaID,
  contractIdNull,
  icctID,
  contractId
}) {
  let type

  if (entity === actions.CLIN) type = actions.DROPDOWN_CLIN
  if (entity === actions.CUSTOMERS) type = actions.DROPDOWN_CUSTOMER
  if (entity === actions.CONTRACTS) type = actions.DROPDOWN_CONTRACT
  if (entity === actions.INVENTORY) type = actions.DROPDOWN_INVENTORY
  if (entity === actions.INVENTORY_CLASS) type = actions.DROPDOWN_INVENTORY_CLASS
  
  if (entity === actions.INVENTORY_CLASS_COST_TYPE)
    type = actions.DROPDOWN_INVENTORY_CLASS_COST_TYPE

  if (entity === actions.COST_TYPE) type = actions.DROPDOWN_COST_TYPE
  if (entity === actions.COST_TYPE_DEFINITIONS) type = actions.DROPDOWN_COST_TYPE_DEFINITIONS
  if (entity === actions.WORKORDER_TYPE) type = actions.DROPDOWN_WORKORDER_TYPE
  if (entity === actions.LOCATIONS) type = actions.DROPDOWN_LOCATION
  if (entity === actions.AREAS) type = actions.DROPDOWN_AREA
  if (entity === actions.USERS) type = actions.DROPDOWN_USERS
  if (entity === actions.ADDRESS) type = actions.DROPDOWN_ADDRESS
  if (entity === actions.DEVICE) type = actions.DROPDOWN_DEVICE
  if (entity === actions.INTERVAL) type = actions.DROPDOWN_INTERVAL
  if (entity === actions.TECHNICIAN) type = actions.DROPDOWN_TECHNICIAN
  if (entity === actions.MLFB) type = actions.DROPDOWN_MLFB
  if (entity === actions.WORKORDER) type = actions.DROPDOWN_WORKORDER
  if (entity === actions.SUPPLIER) type = actions.DROPDOWN_SUPPLIER
  if (entity === actions.INVENTORY_SERVICES) type = actions.DROPDOWN_INVENTORY_SERVICES
  if (entity === actions.PARTS) type = actions.DROPDOWN_PARTS
  if (entity === actions.TECHNICIAN_USERS) type = actions.DROPDOWN_TECHNICIAN_USERS
  if (entity === actions.ADMIN_USERS) type = actions.DROPDOWN_ADMIN_USERS
  if (entity === actions.NOTIFICATION_USERS) type = actions.DROPDOWN_NOTIFICATION_USERS
  if (entity === actions.DOC_PARENT) type = actions.DROPDOWN_DOC_PARENT
  
  if (entity === actions.CONTACT_PERSON) type = actions.DROPDOWN_CONTACT_PERSON
  if (entity === actions.BANK_INFO) type = actions.DROPDOWN_BANK_INFO

  if (type !== undefined) {
    yield put({
      type: actions.REQUESTING,
    })
    const result = yield call(
      getDropdown,
      entity,
      date,
      classID,
      appropriation,
      searchValue,
      areaID,
      contractIdNull,
      icctID,
      contractId
    )
    yield put({ type, result })
  }
  
}

function* resetInventory() {
  yield put({
    type: actions.DROPDOWN_INVENTORY_RESET_RECIEVED,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_DROPDOWN, fetchDropdown),
    takeEvery(actions.DROPDOWN_INVENTORY_RESET, resetInventory),
    
  ])
}
