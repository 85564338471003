import { getRequest, postRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(
    `users/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`users/edit/${id}`)
}

export async function save(values) {
  return postRequest(`users/save`, values)
}

export function remove(values) {
  return postRequest(`users/delete`, values)
}

export async function sendNotificationToDevice(values) {
  return postRequest(`PushNotification/Send`, values)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy, 
  status, 
) {
  const url = `users/get-list2/${size || '15'}/${index || '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${status|| '%20'}`
  return getRequest(url)
}
 