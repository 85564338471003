import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID')) 
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`;
    
  return getRequest(`clinPaymentType/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`clinPaymentType/edit/${id}`)
}

export async function save(values) {
  return postRequest(`clinPaymentType/save`, values)
}

export function remove(values) {
  return postRequest(`clinPaymentType/delete`, values)
}
