import actions from './actions'

export default function MaterialPriceDefinitionsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_MATERIALPRICEDEFINITION:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_MATERIALPRICEDEFINITION:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_MATERIALPRICEDEFINITION:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_MATERIALPRICEDEFINITION:
      return {
        ...state,
        editing: { materialPriceDefinitionID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_MATERIALPRICEDEFINITION_RECIEVED ||
      actions.UPDATE_MATERIALPRICEDEFINITION_RECIEVED ||
      actions.DELETE_MATERIALPRICEDEFINITION_RECIEVED:
      return {
        ...state,
        editing: { materialPriceDefinitionID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
