const actions = {
  REQUESTING: 'ClinPaymentTypes/REQUESTING',
  RESPONSE_ERROR: 'ClinPaymentTypes/RESPONSE_ERROR',

  FETCH_CLINPAYMENTTYPES: 'ClinPaymentTypes/FETCH',
  LIST_CLINPAYMENTTYPE: 'ClinPaymentType/LIST',

  FETCH_CLINPAYMENTTYPE: 'ClinPaymentType/FETCH',
  NEW_CLINPAYMENTTYPE: 'ClinPaymentType/NEW',
  EDIT_CLINPAYMENTTYPE: 'ClinPaymentType/EDIT',
  RESET_CLINPAYMENTTYPE: 'ClinPaymentType/RESET',

  RE_FETCH_CLINPAYMENTTYPES: 'ClinPaymentTypes/REFRESH',
  SAVE_CLINPAYMENTTYPE: 'ClinPaymentType/SAVE',
  SAVE_CLINPAYMENTTYPE_RECIEVED: 'ClinPaymentType/SAVE_CLINPAYMENTTYPE_RECIEVED',

  DELETE_CLINPAYMENTTYPE: 'ClinPaymentType/DELETE',
  DELETE_CLINPAYMENTTYPE_RECIEVED: 'ClinPaymentType/DELETE_RECIEVED',
}

export default actions
