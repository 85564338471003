import { getRequest, getcontractIdNumber } from './api'

export async function getNextControlDatesMonthly() {
  return getRequest(`dashboard/get-next-control-date/${getcontractIdNumber()}`)
}

export async function getWorkOrderCountGroupByType() {
  return getRequest(`dashboard/get-workorder-count-by-type/${getcontractIdNumber()}`)
}

export async function getContractBudgets() {
  return getRequest(`dashboard/get-contract-budgets/${getcontractIdNumber()}`)
}

export async function getInventoryCount() {
  return getRequest(`dashboard/get-inventory-count/${getcontractIdNumber()}`)
}

export async function getContractDetails() {
  return getRequest(`dashboard/get-contract/${getcontractIdNumber()}`)
}

export async function getWorkOrderTotalAmountGroupByType() {
  return getRequest(`dashboard/get-workorder-totals-by-type/${getcontractIdNumber()}`)
}

export async function getWorkOrderOverLimit() {
  return getRequest(`dashboard/get-over-due-date/${getcontractIdNumber()}`)
}

export async function getContractBudgetsClass() {
  return getRequest(`dashboard/get-contract-budgets-class/${getcontractIdNumber()}`)
}