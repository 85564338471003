import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(`technicianDailyReport/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`technicianDailyReport/edit/${id}`)
}

export async function save(values) {
  return postRequest(`technicianDailyReport/save`, values)
}

export function remove(values) {
  return postRequest(`technicianDailyReport/delete`, values)
}

export async function list2(size, index, searchTerm, orderBy, reportDate, technicianID, status) {
  const contractId = getcontractIdNumber()
  return getRequest
  (`technicianDailyReport/get-list2/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}/${contractId}/${reportDate || '%20'}/${technicianID || '%20'}/${status || '%20'}`)
}