const actions = {
  REQUESTING: 'CostTypeDefinitions/REQUESTING',
  RESPONSE_ERROR: 'CostTypeDefinitions/RESPONSE_ERROR',

  FETCH_COSTTYPEDEFINITIONS: 'CostTypeDefinitions/FETCH',
  LIST_COSTTYPEDEFINITION: 'CostTypeDefinition/LIST',

  FETCH_COSTTYPEDEFINITION: 'CostTypeDefinition/FETCH',
  NEW_COSTTYPEDEFINITION: 'CostTypeDefinition/NEW',
  EDIT_COSTTYPEDEFINITION: 'CostTypeDefinition/EDIT',
  RESET_COSTTYPEDEFINITION: 'CostTypeDefinition/RESET',

  RE_FETCH_COSTTYPEDEFINITIONS: 'CostTypeDefinitions/REFRESH',
  SAVE_COSTTYPEDEFINITION: 'CostTypeDefinition/SAVE',
  SAVE_COSTTYPEDEFINITION_RECIEVED: 'CostTypeDefinition/SAVE_COSTTYPEDEFINITION_RECIEVED',

  DELETE_COSTTYPEDEFINITION: 'CostTypeDefinition/DELETE',
  DELETE_COSTTYPEDEFINITION_RECIEVED: 'CostTypeDefinition/DELETE_RECIEVED',
}

export default actions
