const actions = {
  REQUESTING: 'InventoryServices/REQUESTING',
  RESPONSE_ERROR: 'InventoryServices/RESPONSE_ERROR',

  FETCH_INVENTORYSERVICES: 'InventoryServices/FETCH',
  LIST_INVENTORYSERVICE: 'InventoryService/LIST',

  FETCH_INVENTORYSERVICE: 'InventoryService/FETCH',
  NEW_INVENTORYSERVICE: 'InventoryService/NEW',
  EDIT_INVENTORYSERVICE: 'InventoryService/EDIT',
  RESET_INVENTORYSERVICE: 'InventoryService/RESET',

  RE_FETCH_INVENTORYSERVICES: 'InventoryServices/REFRESH',
  SAVE_INVENTORYSERVICE: 'InventoryService/SAVE',
  SAVE_INVENTORYSERVICE_RECIEVED: 'InventoryService/SAVE_INVENTORYSERVICE_RECIEVED',

  DELETE_INVENTORYSERVICE: 'InventoryService/DELETE',
  DELETE_INVENTORYSERVICE_RECIEVED: 'InventoryService/DELETE_RECIEVED',
}

export default actions
