import actions from './actions'

export default function Invoice2sReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        // editing: null,
        saveSuccess: false,
      }
    case actions.LIST_INVOICE2:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_INVOICE2:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_INVOICE2_rec:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.SAVE_INVOICE2_RECIEVED:
      return {
        ...state,
        saveResult: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_INVOICE2:
      return {
        ...state,
        editing: { InvoiceID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.DELETE_INVOICE2_RECIEVED:
      return {
        ...state,
        editing: { InvoiceID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }      
    case actions.CREATE_INVOICE2_RECIEVED:
      return {
        ...state,
        newInvoice: true,
        editing: action.result,
        loading: false,
      }
    case actions.CREATE_INVOICE2_RESET_RECIEVED:
      return {
        ...state,
        newInvoice: false, 
      }
    default:
      return state
  }
}
