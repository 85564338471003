import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `technicianPart/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy ||
      '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`technicianPart/edit/${id}`)
}

export async function save(values) {
  return postRequest(`technicianPart/save`, values)
}

export function remove(values) {
  return postRequest(`technicianPart/delete`, values)
}

export async function list2(size, index, searchTerm, orderBy, partID, technicianID) {
  const contractID = getcontractIdNumber()
  const url = `technicianPart/get-list2/${size || '15'}/${index || '0'}/${searchTerm ||
    '%20'}/${orderBy || '%20'}/${contractID}/${partID || '%20'}/${technicianID || '%20'}`
  return getRequest(url)
}
