import { getRequest, postRequest, getcontractIdNumber, getWorkorderID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `inventoryClassCostType/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy ||
      '%20'}`,
  )
}

export async function getCosts(classID, date) {
  const contractID = getcontractIdNumber()
  const workOrderID = getWorkorderID()
  return getRequest(`inventoryClassCostType/costs/${contractID}/${workOrderID}/${classID}/${date}`)
}

export async function find(id) {
  return getRequest(`inventoryClassCostType/edit/${id}`)
}

export async function save(values) {
  return postRequest(`inventoryClassCostType/save`, values)
}

export function remove(values) {
  return postRequest(`inventoryClassCostType/delete`, values)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy,
  inventoryClassCode,
  costTypeCode,
  definitionCode,
  appropriation,
  code,
) {
  const contractID = getcontractIdNumber()
  const url = `inventoryClassCostType/get-list2/${size || '15'}/${index || '0'}/${searchTerm ||
    '%20'}/${orderBy || '%20'}/${contractID}/${inventoryClassCode || '%20'}/${costTypeCode ||
    '%20'}/${definitionCode || '%20'}/${appropriation || '%20'}/${code || '%20'}`
  return getRequest(url)
}
