const actions = {
  REQUESTING: 'WorkOrderStatusForms/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderStatusForms/RESPONSE_ERROR',

  FETCH_WORKORDERSTATUSFORMS: 'WorkOrderStatusForms/FETCH',
  LIST_WORKORDERSTATUSFORM: 'WorkOrderStatusForm/LIST',

  FETCH_WORKORDERSTATUSFORM: 'WorkOrderStatusForm/FETCH',
  NEW_WORKORDERSTATUSFORM: 'WorkOrderStatusForm/NEW',
  EDIT_WORKORDERSTATUSFORM: 'WorkOrderStatusForm/EDIT',
  RESET_WORKORDERSTATUSFORM: 'WorkOrderStatusForm/RESET',

  RE_FETCH_WORKORDERSTATUSFORMS: 'WorkOrderStatusForms/REFRESH',
  SAVE_WORKORDERSTATUSFORM: 'WorkOrderStatusForm/SAVE',
  SAVE_WORKORDERSTATUSFORM_RECIEVED: 'WorkOrderStatusForm/SAVE_WORKORDERSTATUSFORM_RECIEVED',

  DELETE_WORKORDERSTATUSFORM: 'WorkOrderStatusForm/DELETE',
  DELETE_WORKORDERSTATUSFORM_RECIEVED: 'WorkOrderStatusForm/DELETE_RECIEVED',
}

export default actions
