import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/workOrderStatusForm'

function* fetchWorkOrderStatusFormsRefresh() {
  let fe = JSON.parse(localStorage.getItem('fetchWorkOrderStatusForms'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  } else {
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_WORKORDERSTATUSFORM,
    result,
  })
}

function* fetchWorkOrderStatusForms({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = null // JSON.parse(localStorage.getItem('fetchWorkOrderStatusForms'))
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem(
    'fetchWorkOrderStatusForms',
    JSON.stringify({ size, index, searchTerm, orderBy }),
  )
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_WORKORDERSTATUSFORM,
    result,
  })
}

function* fetchWorkOrderStatusFormEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_WORKORDERSTATUSFORM,
    result,
  })
}

function* fetchWorkOrderStatusFormSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_WORKORDERSTATUSFORM_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDERSTATUSFORMS,
  })
}

function* fetchWorkOrderStatusFormDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_WORKORDERSTATUSFORM_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDERSTATUSFORMS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_WORKORDERSTATUSFORMS, fetchWorkOrderStatusForms),
    takeEvery(actions.FETCH_WORKORDERSTATUSFORM, fetchWorkOrderStatusFormEdit),
    takeEvery(actions.SAVE_WORKORDERSTATUSFORM, fetchWorkOrderStatusFormSave),
    takeEvery(actions.DELETE_WORKORDERSTATUSFORM, fetchWorkOrderStatusFormDelete),
    takeEvery(actions.RE_FETCH_WORKORDERSTATUSFORMS, fetchWorkOrderStatusFormsRefresh),
  ])
}
