import actions from './actions'

export default function AreasReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
      }
    case actions.GetContractDetails_RECIEVED:
      return {
        ...state,
        contractDetails: action.result,
        loading: false,
      }
    case actions.GetNextControlDatesMonthly_RECIEVED:
      return {
        ...state,
        nextControlMonthly: action.result,
        loading: false,
      }
    case actions.GetContractBudgets_RECIEVED:
      return {
        ...state,
        contractBudgets: action.result,
        loading: false,
      }
    case actions.GetContractBudgetsClass_RECIEVED:
      return {
        ...state,
        contractBudgetsClass: action.result,
        loading: false,
      }
    case actions.GetInventoryCount_RECIEVED:
      return {
        ...state,
        inventoryCount: action.result,
        loading: false,
      }
    case actions.GetWorkOrderCountGroupByType_RECIEVED:
      return {
        ...state,
        workOrdersTypes: action.result,
        loading: false,
      }
    case actions.GetWorkOrderTotalAmountGroupByType_RECIEVED:
      return {
        ...state,
        workOrdersPriceSum: action.result,
        loading: false,
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      }
    case actions.GetWorkOrderOverLimitsRecieved:
      return {
        ...state,
        workOrderOverLimits: action.result,
        loading: false,
      }

    default:
      return state
  }
}
