import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/externalInvoiceLog'

function* fetchExternalInvoiceLogsRefresh() {
  const fe = JSON.parse(localStorage.getItem('fetchExternalInvoiceLogs'))
  if (fe == null) {
    fe.size = 10
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_EXTERNALINVOICELOG,
    result,
  })
}

function* fetchExternalInvoiceLogs({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchExternalInvoiceLogs'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem(
    'fetchExternalInvoiceLogs',
    JSON.stringify({ size, index, searchTerm, orderBy }),
  )
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_EXTERNALINVOICELOG,
    result,
  })
}

function* fetchExternalInvoiceLogEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_EXTERNALINVOICELOG,
    result,
  })
}

function* fetchExternalInvoiceLogSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_EXTERNALINVOICELOG_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_EXTERNALINVOICELOGS,
  })
}

function* fetchExternalInvoiceLogDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_EXTERNALINVOICELOG_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_EXTERNALINVOICELOGS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_EXTERNALINVOICELOGS, fetchExternalInvoiceLogs),
    takeEvery(actions.FETCH_EXTERNALINVOICELOG, fetchExternalInvoiceLogEdit),
    takeEvery(actions.SAVE_EXTERNALINVOICELOG, fetchExternalInvoiceLogSave),
    takeEvery(actions.DELETE_EXTERNALINVOICELOG, fetchExternalInvoiceLogDelete),
    takeEvery(actions.RE_FETCH_EXTERNALINVOICELOGS, fetchExternalInvoiceLogsRefresh),
  ])
}
