const actions = {
  REQUESTING: 'WorkOrderTypes/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderTypes/RESPONSE_ERROR',

  FETCH_WORKORDERTYPES: 'WorkOrderTypes/FETCH',
  LIST_WORKORDERTYPE: 'WorkOrderType/LIST',

  FETCH_WORKORDERTYPE: 'WorkOrderType/FETCH',
  NEW_WORKORDERTYPE: 'WorkOrderType/NEW',
  EDIT_WORKORDERTYPE: 'WorkOrderType/EDIT',
  RESET_WORKORDERTYPE: 'WorkOrderType/RESET',

  RE_FETCH_WORKORDERTYPES: 'WorkOrderTypes/REFRESH',
  SAVE_WORKORDERTYPE: 'WorkOrderType/SAVE',
  SAVE_WORKORDERTYPE_RECIEVED: 'WorkOrderType/SAVE_WORKORDERTYPE_RECIEVED',

  DELETE_WORKORDERTYPE: 'WorkOrderType/DELETE',
  DELETE_WORKORDERTYPE_RECIEVED: 'WorkOrderType/DELETE_RECIEVED',
}

export default actions
