import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { list2, find, save, remove, sendNotificationToDevice } from '../../services/users'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
 
// function* fetchUserss({ size, index, searchTerm, orderBy }) {
//   // Load last params froom last session.
//   if (!size && !index && !searchTerm && !orderBy) {
//     const fe = null // JSON.parse(localStorage.getItem('fetchUserss'))
//     if (fe != null) {
//       size = fe.size || 15
//       index = fe.index || 0
//       searchTerm = fe.searchTerm || '%20'
//       orderBy = fe.orderBy || '%20'
//     } else {
//       size = size || localStorage.getItem('pageSize') || 15
//       index = 0
//       searchTerm = '%20'
//       orderBy = '%20'
//     }
//   }
//   localStorage.setItem('fetchUserss', JSON.stringify({ size, index, searchTerm, orderBy }))
//   yield put({
//     type: actions.REQUESTING,
//   })
//   const result = yield call(list, size, index, searchTerm, orderBy)
//   yield put({
//     type: actions.LIST_USERS,
//     result,
//   })
// }

function* fetchUsersEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_USERS,
    result,
  })
}

function* fetchUsersSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_USERS_RECIEVED,
    result,
  })
  yield put({
    type: actions.FETCH_PROFILE_CLOSE,
  })
  yield put({
    type: 'user/LOAD_CURRENT_ACCOUNT',
  })
  yield put({
    type: actions.RE_FETCH_USERSS,
  })
}

function* fetchUsersDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.RESET_USERS,
    result,
  })
  yield put({
    type: actions.RE_FETCH_USERSS,
  })
}

function* fetchUserProfile({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.FETCH_PROFILE_SUCCESS,
    result,
  })
}

function* sendNotificationToDevice2(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(sendNotificationToDevice, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
}





function* fetchUsers({
  size,
  index,
  searchTerm,
  orderBy,
  status, 
}) {

  const t = localStorage.getItem('fetchUsersFilter')
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        status = fe.status || status
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem('fetchUsersFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        status,  
      }),
    )
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    list2,
    size,
    index,
    searchTerm,
    orderBy,
    status, 
  )
  yield put({
    type: actions.LIST_USERS,
    result,
  })
} 


export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_USERSS, fetchUsers),
    takeEvery(actions.FETCH_USERS, fetchUsersEdit),
    takeEvery(actions.SAVE_USERS, fetchUsersSave),
    takeEvery(actions.PUSH_NOTIFICATION, sendNotificationToDevice2),
    takeEvery(actions.DELETE_USERS, fetchUsersDelete),
    takeEvery(actions.RE_FETCH_USERSS, fetchUsers),
    takeEvery(actions.FETCH_PROFILE, fetchUserProfile),
  ])
}
