import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/workOrderAdditionalCost'

function* fetchWorkOrderAdditionalCostsRefresh() {
  let fe = JSON.parse(localStorage.getItem('fetchWorkOrderAdditionalCosts'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  } else { 
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_WORKORDERADDITIONALCOST,
    result,
  })
}

function* fetchWorkOrderAdditionalCosts({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = null // JSON.parse(localStorage.getItem('fetchWorkOrderAdditionalCosts'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchWorkOrderAdditionalCosts', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_WORKORDERADDITIONALCOST,
    result,
  })
}

function* fetchWorkOrderAdditionalCostEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_WORKORDERADDITIONALCOST,
    result,
  })
}

function* fetchWorkOrderAdditionalCostSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_WORKORDERADDITIONALCOST_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_WORKORDERADDITIONALCOSTS,
  });
  yield put({ type: 'WorkOrder/GET_COSTVIEW2', id: values.values.workOrderID });
  yield put({ type: 'WorkOrders/FETCH_WORKORDER_COSTVIEW', id: values.values.workOrderID });
  yield put({ type: 'WorkOrderOperationCosts/FETCH' });
}

function* fetchWorkOrderAdditionalCostDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_WORKORDERADDITIONALCOST_RECIEVED,
    result,
  });
  yield put({
    type: actions.RE_FETCH_WORKORDERADDITIONALCOSTS,
  });
  yield put({ type: 'WorkOrder/GET_COSTVIEW2', id: values.values.workOrderID });
  yield put({ type: 'WorkOrders/FETCH_WORKORDER_COSTVIEW', id: values.values.workOrderID });
  yield put({ type: 'WorkOrderOperationCosts/FETCH' });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_WORKORDERADDITIONALCOSTS, fetchWorkOrderAdditionalCosts),
    takeEvery(actions.FETCH_WORKORDERADDITIONALCOST, fetchWorkOrderAdditionalCostEdit),
    takeEvery(actions.SAVE_WORKORDERADDITIONALCOST, fetchWorkOrderAdditionalCostSave),
    takeEvery(actions.DELETE_WORKORDERADDITIONALCOST, fetchWorkOrderAdditionalCostDelete),
    takeEvery(actions.RE_FETCH_WORKORDERADDITIONALCOSTS, fetchWorkOrderAdditionalCostsRefresh),
  ])
}
