import localeAntd from 'antd/es/locale/fr_FR'

const messages = {

}

export default {
  locale: 'fr-FR',
  localeAntd,
  messages,
}
