import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `inventory/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`inventory/edit/${id}`)
}

export async function mlfb(id, classID) {
  const contractId = getcontractIdNumber()
  return getRequest(`inventory/get-product-mlfb2/${contractId}/${id}/${classID || 0}`)
}

export async function save(values) {
  return postRequest(`inventory/save`, values)
}

export function remove(values) {
  return postRequest(`inventory/delete`, values)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy,
  inventoryNumber,
  deviceInfo,
  appropriation,
  className,
  areaName,
  location,
  street,
  model,
  serialNumber,
  mlfbNumber,
  emhBarcode,
  status,
  costTypeCode
) {
  const contractID = getcontractIdNumber()
  const url = `inventory/get-list2/${size || '15'}/${index || '0'}/${searchTerm ||
    '%20'}/${orderBy || '%20'}/${contractID}/${inventoryNumber || '%20'}/${deviceInfo ||
    '%20'}/${appropriation || '%20'}/${className || '%20'}/${areaName || '%20'}/${location ||
    '%20'}/${street || '%20'}/${model || '%20'}/${serialNumber || '%20'}/${mlfbNumber ||
    '%20'}/${emhBarcode || '%20'}/${status || '%20'}/${costTypeCode || '%20'}`
  return getRequest(url)
}
