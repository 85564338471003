const actions = {
  REQUESTING: 'Invoices/REQUESTING',
  RESPONSE_ERROR: 'Invoices/RESPONSE_ERROR',

  FETCH_INVOICES: 'Invoices/FETCH',
  LIST_INVOICE: 'Invoice/LIST',

  FETCH_INVOICE: 'Invoice/FETCH',
  NEW_INVOICE: 'Invoice/NEW',
  EDIT_INVOICE: 'Invoice/EDIT',

  RE_FETCH_INVOICES: 'Invoices/REFRESH',
  SAVE_INVOICE: 'Invoice/SAVE',
  SAVE_INVOICE_RECIEVED: 'Invoice/SAVE_INVOICE_RECIEVED',

  DELETE_INVOICE: 'Invoice/DELETE',
  DELETE_INVOICE_RECIEVED: 'Invoice/DELETE_RECIEVED',

  CREATE_INVOICE: 'Reports/CREATE_INVOICE',
  CREATE_INVOICE_RECIEVED: 'Reports/CREATE_INVOICE_RECIEVED',
 
  RESET_INVOICE: 'RESET_INVOICE',
  RESET_INVOICE_RECIEVED: 'RESET_INVOICE_RECIEVED',

  CREATE_RESET_INVOICE_RECIEVED:'CREATE_RESET_INVOICE_RECIEVED',
  CREATE_RESET_INVOICE: 'CREATE_RESET_INVOICE',

  Refresh: 'Refresh_Invoice',
  Refresh_Recieved: 'Refresh_Invoice_Invoiced',

  Refresh_reset: 'Refresh_reset',
  Refresh_reset_Recieved: 'Refresh_reset_Recieved',
  
  MAKE_NEW_INVOICE: 'Reports/MAKE_NEW_INVOICE',
  MAKE_NEW_INVOICE_RECIEVED: 'Reports/MAKE_NEW_INVOICE_RECIEVED',
}

export default actions
