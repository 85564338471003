const actions = {
  REQUESTING: 'TechnicianParts/REQUESTING',
  RESPONSE_ERROR: 'TechnicianParts/RESPONSE_ERROR',

  FETCH_TECHNICIANPARTS: 'TechnicianParts/FETCH',
  LIST_TECHNICIANPART: 'TechnicianPart/LIST',

  FETCH_TECHNICIANPART: 'TechnicianPart/FETCH',
  NEW_TECHNICIANPART: 'TechnicianPart/NEW',
  EDIT_TECHNICIANPART: 'TechnicianPart/EDIT',
  RESET_TECHNICIANPART: 'TechnicianPart/RESET',

  RE_FETCH_TECHNICIANPARTS: 'TechnicianParts/REFRESH',
  SAVE_TECHNICIANPART: 'TechnicianPart/SAVE',
  SAVE_TECHNICIANPART_RECIEVED: 'TechnicianPart/SAVE_TECHNICIANPART_RECIEVED',

  DELETE_TECHNICIANPART: 'TechnicianPart/DELETE',
  DELETE_TECHNICIANPART_RECIEVED: 'TechnicianPart/DELETE_RECIEVED',

  FETCH_TECHNICIANPARTS_FILTER: 'TechnicianPart/FILTER',
}

export default actions
