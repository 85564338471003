/*eslint-disable*/
import { all, put, call, takeEvery } from 'redux-saga/effects'
import { list } from '../../services/country'
import actions from './actions'

function* fetchCountries({ size, index, searchTerm, orderBy }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, 99999, 0, searchTerm, orderBy)
  yield put({
    type: actions.LIST_COUNTRIES,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_COUNTRIES, fetchCountries),
  ])
}
