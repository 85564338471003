const actions = {
  SET_STATE: 'menu/SET_STATE',
  SET_STATE2: 'menu/SET_STATE2',
  SET_STATE3: 'menu/SET_STATE3',
  SET_STATE4: 'menu/SET_STATE4',

  GET_DATA: 'menu/GET_DATA',
  GET_DATA2: 'menu/GET_DATA2',
  GET_DATA3: 'menu/GET_DATA3',
  GET_DATA4: 'menu/GET_DATA4',

  GET_CONTRACT_MENU: 'menu/contract_based',
  GET_CONTRACT_MENU_RECIEVED: 'menu/contract_based_recieved',
}

export default actions
