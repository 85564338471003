import localeAntd from 'antd/es/locale/ru_RU'

const messages = {

}

export default {
  locale: 'ru-RU',
  localeAntd,
  messages,
}
