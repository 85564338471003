import { getRequest, getcontractIdNumber } from './api'

export default function getDropdown(
  entity,
  date,
  classID,
  appropriation,
  searchValue,
  areaID,
  contractIdNull,
  icctID,
  contractId
) {
  entity = entity.replace('dropdown/', '')
  entity = entity.toLowerCase()
  let contractid = contractIdNull ? '' : getcontractIdNumber()
  if (contractId !== undefined)
    contractid = contractId
  if (date === undefined) date = '%20'
  if (classID === undefined) classID = '%20'
  if (classID === null) classID = '%20'
  if (appropriation === undefined) appropriation = '%20'
  if (searchValue === undefined || searchValue === null) searchValue = '%20'
  if (areaID === undefined || areaID === null) areaID = '%20'
  if (icctID === undefined || icctID === null) icctID = '%20'
  return getRequest(`dropdown/get/${entity}/${contractid !== '' ? contractid : 0}/${date}/${classID}/${appropriation}/${searchValue}/${areaID}/${icctID}`)
}
