export default async function getMenuData() {
    return [
        {
            key: 'workorderlist',
            url: '/workorders',
            icon: 'fe fe-clipboard',
        },
        // {
        //     key: 'proposals',
        //     icon: 'fe fe-database',
        //     role: 'admin',
        //     children: [
        //         {
        //             key: 'proposals',
        //             url: '/proposals',
        //         },
        //         {
        //             key: 'clinPaymentType',
        //             url: '/clinPaymentTypes',
        //         },
        //         {
        //             key: 'materialPriceDefinition',
        //             url: '/materialPriceDefinitions',
        //         },
        //     ],
        // },

        {
            key: 'proposals',
            icon: 'fe fe-database',
            role: 'admin',
            url: '/proposals',
            // children: [
            //   {
            //     key: 'proposals',
            //     url: '/proposals',
            //   },
            //   {
            //     key: 'clinPaymentType',
            //     url: '/clinPaymentTypes',
            //   },
            //   {
            //     key: 'materialPriceDefinition',
            //     url: '/materialPriceDefinitions',
            //   },  
            // ],
        },


        // {
        //     key: 'inventoryServices',
        //     icon: 'fe fe-server',
        //     url: '/inventoryservices',
        // },

        {
            key: 'partOrders',
            icon: 'fe fe-shopping-cart',
            url: '/partorders',
        },
        {
            key: 'partTransfers',
            icon: 'fe fe-truck',
            url: '/partTransfers',
        },
        // {
        //     key: 'inventoryProductInformations',
        //     url: '/inventoryProductInformations',
        //     icon: 'fe fe-tag',
        // },
        // {
        //     key: 'inventoryServiceLabors',
        //     icon: 'fe fe-sliders',
        //     url: '/inventoryServiceLabors',
        // },



        // {
        //     key: 'repairLimits',
        //     icon: 'fe fe-briefcase',
        //     url: '/repairLimits',
        // },
        // {
        //   key: 'technicianParts',
        //   icon: 'fe fe-package',
        //   url: '/technicianParts',
        //   role: 'user',
        // },

        {
            key: 'technicianDailyReports',
            icon: 'fe fe-mail',
            url: '/technicianDailyReports',
            role: 'user',
        },

        // {
        //     key: 'mlfbDocuments',
        //     icon: 'fe fe-folder',
        //     children: [
        //         {
        //             key: 'mlfbDocumentsFolder',
        //             url: '/mlfbDocumentsFolder'
        //         },
        //         {
        //             key: 'mlfbDocuments',
        //             url: '/mlfbDocuments'
        //         },
        //     ],
        // },
        // {
        //     key: 'contractBudgetInventoryClassMenuName',
        //     icon: 'fe fe-credit-card',
        //     url: '/budget-class',
        // },


        {
            key: 'nextcontrols',
            icon: 'fe fe-command',
            url: '/nextcontrols'
        },
        {
            key: 'technicians',
            icon: 'fe fe-git-branch',
            url: '/technicians',
        },

        {
            key: 'suppliers',
            icon: 'fe fe-truck',
            url: '/suppliers',
        },

        {
            key: 'clinBudgets',
            icon: 'fe fe-briefcase',
            url: '/clinBudgets',
        },
        // {
        //     key: 'contractbudget',
        //     icon: 'fe fe-credit-card',
        //     url: '/contractbudgets',
        // },
        {
            key: 'contactPerson',
            icon: 'fe fe-user',
            url: '/contactPersons',
        },
        {
            key: 'dashboards',
            icon: 'fe fe-activity',
            url: '/dashboard/analytics',
        },
        // {
        //     key: 'workOrderInventoryLogs',
        //     icon: 'fe fe-git-branch',
        //     url: '/workOrderInventoryLogs',
        // },

    ]
}

