import actions from './actions'

export default function ContractBudgetsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
      }
    case actions.LIST_CONTRACTBUDGET:
      return {
        ...state,
        result: action.result,
        loading: false,
      }
    case actions.FETCH_CONTRACTBUDGETS_DATERANGE:
      return {
        ...state,
        currentBudget: action.result,
        loading: false,
      }
    case actions.EDIT_CONTRACTBUDGET:
      return {
        ...state,
        editing: action.result,
        loading: false,
      }
    case actions.RESET_CONTRACTBUDGET:
      return {
        ...state,
        editing: null,
        loading: false,
      }
    case actions.NEW_CONTRACTBUDGET:
      return {
        ...state,
        editing: { contractBudgetID: 0 },
        loading: false,
      }
    case actions.CREATE_CONTRACTBUDGET_RECIEVED ||
      actions.UPDATE_CONTRACTBUDGET_RECIEVED ||
      actions.DELETE_CONTRACTBUDGET_RECIEVED:
      return {
        ...state,
        editing: { contractBudgetID: 0 },
        loading: false,
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      }
    default:
      return state
  }
}
