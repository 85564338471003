import { getRequest, postRequest, getMicroBaseApiUrl } from './api'

const token = localStorage.getItem('token')

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(`partTransfer/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`partTransfer/edit/${id}`)
}

export async function save(values) {
  return postRequest(`partTransfer/save`, values)
}

export function remove(values) {
  return postRequest(`partTransfer/delete`, values)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy,
  partID,
  status,
  requestDate,
  transferDate,
  deliveredDate,
  model,
  mlfb,
  manufacturer,
  manufacturerNr,
  owner,
  description
) {
  const url = `partTransfer/get-list2/${size || '15'}/${index || '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${partID || '%20'}/${status || '%20'}/${requestDate || '%20'}/${transferDate || '%20'}/${deliveredDate || '%20'}/${model || '%20'}/${manufacturer || '%20'}/${manufacturerNr || '%20'}/${mlfb || '%20'}/${owner || '%20'}/${description || '%20'}`
  return getRequest(url)
}

export async function export2(
  partID,
  status,
  requestDate,
  transferDate,
  deliveredDate,
  model,
  mlfb,
  manufacturer,
  manufacturerNr,
  owner,
  description
) {
  const url = `report/part-transfers-excel/${partID || '%20'}/${status || '%20'}/${requestDate || '%20'}/${transferDate || '%20'}/${deliveredDate || '%20'}/${model || '%20'}/${manufacturer || '%20'}/${manufacturerNr || '%20'}/${mlfb || '%20'}/${owner || '%20'}/${description || '%20'}`
  const furl = `${getMicroBaseApiUrl(url)}${url}`
  const win = window.open(`${furl}/${token}`, '_blank')
  win.focus()
}