/* eslint-disable */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { list, searchCode } from '../../services/postalCode2'
import actions from './actions'

function* fetchPostalCodes({ size, index, searchTerm, orderBy }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, 1000000, 0, searchTerm, 'Code ASC')
  yield put({
    type: actions.LIST_POSTALCODES,
    result,
  });
}

function* fetchPostalCodesWithSearch({ query }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(searchCode, `Code:${query}`)
  yield put({
    type: actions.SEARCH_POSTALCODE_RECIEVED,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_POSTALCODES, fetchPostalCodes),
    takeEvery(actions.SEARCH_POSTALCODE, fetchPostalCodesWithSearch),
  ])
}
