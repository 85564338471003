/* eslint-disable */


const actions = {
  REQUESTING: 'INVOICEDETAILs/REQUESTING',
  RESPONSE_ERROR: 'INVOICEDETAILs/RESPONSE_ERROR',

  FETCH_INVOICEDETAILS: 'INVOICEDETAILs/FETCH',
  LIST_INVOICEDETAIL: 'INVOICEDETAIL/LIST',

  FETCH_INVOICEDETAIL: 'INVOICEDETAIL/FETCH',
  NEW_INVOICEDETAIL: 'INVOICEDETAIL/NEW',
  EDIT_INVOICEDETAIL: 'INVOICEDETAIL/EDIT',
  RESET_INVOICEDETAIL: 'INVOICEDETAIL/RESET',

  RE_FETCH_INVOICEDETAILS: 'INVOICEDETAILs/REFRESH',
  SAVE_INVOICEDETAIL: 'INVOICEDETAIL/SAVE',
  SAVE_INVOICEDETAIL_RECIEVED: 'INVOICEDETAIL/SAVE_INVOICEDETAIL_RECIEVED',

  DELETE_INVOICEDETAIL: 'INVOICEDETAIL/DELETE',
  DELETE_INVOICEDETAIL_RECIEVED: 'INVOICEDETAIL/DELETE_RECIEVED',
}

export default actions
