import { getRequest, postRequest, getProposalWorkOrderID, getWorkorderID } from './api'

export async function list(id) {

  const proposalWorkOrderID = getProposalWorkOrderID();
  const workOrderWorkOrderID = getWorkorderID();

  if (proposalWorkOrderID !== '0') {
    id = proposalWorkOrderID
  }

  if (workOrderWorkOrderID !== '0') {
    id = workOrderWorkOrderID
  }
 
  return getRequest(`proposalFile/get-list/${id}`)
}

export async function find(id) {
  return getRequest(`proposalFile/edit/${id}`)
}

export async function save(values) {
  return postRequest(`proposalFile/save`, values)
}

export function remove(values) {
  return postRequest(`proposalFile/delete`, values)
}
