import actions from './actions'

export default function postalCodeReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
      }
    case actions.LIST_POSTALCODES:
      return {
        ...state,
        result: action.result,
        loading: false,
        loaded: true,
      }
    case actions.SEARCH_POSTALCODE_RECIEVED:
      return {
        ...state,
        result: action.result,
        loading: false,
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      }
    default:
      return state
  }
}
