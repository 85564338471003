import { getRequest, postRequest, getPartOrderID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('PartOrderID'))
    searchTerm = `${searchTerm}, PartOrderID:${getPartOrderID()},`
  return getRequest(`partOrderArrived/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}
 
export async function find(id) {
  return getRequest(`partOrderArrived/edit/${id}`)
}

export async function save(values) {
  return postRequest(`partOrderArrived/save`, values)
}

export function remove(values) {
  return postRequest(`partOrderArrived/delete`, values)
}

export function updateQuantity(id) {
  return getRequest(`partOrderArrived/update-quantity/${id}`)
}
