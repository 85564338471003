import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list2, find, save, remove, savePdf, export2 } from '../../services/partOrder'


function* fetchPartOrderEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PARTORDER,
    result,
  })
}

function* fetchPartOrderSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_PARTORDER_RECIEVED,
    result,
  })
  yield put({
    type: actions.FETCH_PARTORDERS,
  })
}

function* fetchPartOrderDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_PARTORDER_RECIEVED,
    result,
  })
  yield put({
    type: actions.FETCH_PARTORDERS,
  })
}
 

function* fetchPartOrderEdit2({ id }) {
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PARTORDER,
    result,
  })
}

// function* fetchPartOrders({
//   size,
//   index,
//   searchTerm,
//   orderBy,
  
//   partID,
//   status,
//   supplierID,
//   orderDate,
//   excel
// }) {
//   size = size || localStorage.getItem('pageSize') || 15
 
//   yield put({
//     type: actions.REQUESTING,
//   })

//   if (excel !== undefined && excel === true) {
//     yield call(
//       export2, 
//       partID,
//       status,
//       supplierID,
//       orderDate
//     )
//   } else {
//     const result = yield call(
//       list2,
//       size,
//       index,
//       searchTerm,
//       orderBy,

//       partID,
//       status,
//       supplierID,
//       orderDate,
//     )
//     yield put({
//       type: actions.LIST_PARTORDER,
//       result,
//     })
//   }  

// }







function* fetchPartOrders({
  size,
  index,
  searchTerm,
  orderBy,
  partID,
  status,
  supplierID,
  orderDate,
  model,
  mlfb,
  manufacturer,
  manufacturerNr,
  owner,
  description,
  excel
}) {

  const t = localStorage.getItem('fetchPartOrdersFilter')
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        partID = fe.partID || partID
        status = fe.status || status
        supplierID = fe.supplierID || supplierID
        orderDate = fe.orderDate || orderDate
        manufacturer = fe.manufacturer || manufacturer
        manufacturerNr = fe.manufacturerNr || manufacturerNr
        model = fe.model || model
        mlfb = fe.mlfb || mlfb
        description = fe.description || description
        owner = fe.owner || owner
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(
      'fetchPartOrdersFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        partID,
        status, 
        supplierID,
        orderDate,
        model,
        mlfb,
        manufacturer,
        manufacturerNr,
        owner,
        description
      }),
    )
  } catch (e) {
    console.log(e)
  }

  if (excel !== undefined && excel === true) {
    yield call(
      export2,
      partID,
      status, 
      supplierID,
      orderDate,
      model,
      mlfb,
      manufacturer,
      manufacturerNr,
      owner,
      description
    )
  } else {
    yield put({
      type: actions.REQUESTING,
    })
    const result = yield call(
      list2,
      size,
      index,
      searchTerm,
      orderBy,
      partID,
      status, 
      supplierID,
      orderDate,
      model,
      mlfb,
      manufacturer,
      manufacturerNr,
      owner,
      description
    )
    yield put({
      type: actions.LIST_PARTORDER,
      result,
    })
  }
} 




function* SavePdf({ partOrderID, file}) {
  yield call(savePdf, partOrderID, file)
  const result = yield call(find, partOrderID)
  yield put({
    type: actions.EDIT_PARTORDER,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_PARTORDER2, fetchPartOrderEdit2),
    takeEvery(actions.FETCH_PARTORDERS, fetchPartOrders),
    takeEvery(actions.FETCH_PARTORDER, fetchPartOrderEdit),
    takeEvery(actions.SAVE_PARTORDER, fetchPartOrderSave),
    takeEvery(actions.DELETE_PARTORDER, fetchPartOrderDelete), 
    takeEvery(actions.SAVEPDF, SavePdf),
  ])
}
