import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import actions2 from '../mlfbDocumentsFolder/actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { find, save, remove, list2 } from '../../services/mlfbDocument'

function* fetchMlfbDocumentEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_MLFBDOCUMENT,
    result,
  })
}

function* fetchMlfbDocumentSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_MLFBDOCUMENT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_MLFBDOCUMENTS,
  })
  yield put({
    type: actions2.RE_FETCH_MLFBDOCUMENTS2,
  })
}

function* fetchMlfbDocumentDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_MLFBDOCUMENT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_MLFBDOCUMENTS,
  }) 
  yield put({
    type: actions2.RE_FETCH_MLFBDOCUMENTS2,
  })
}

function* fetchMlfbDocuments({
  size,
  index,
  searchTerm,
  orderBy,
  partNumber,
  mlfb,
  parentID,
  assetClassID
}) {

  const t = localStorage.getItem('fetchmlfbDocumentsFilter')
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        partNumber = fe.partNumber || partNumber
        mlfb = fe.mlfb || mlfb
        parentID = fe.parentID || parentID
        assetClassID = fe.assetClassID || assetClassID
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(
      'fetchmlfbDocumentsFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        partNumber,
        mlfb,
        parentID,
        assetClassID
      }),
    )
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    list2,
    size,
    index,
    searchTerm,
    orderBy,
    partNumber,
    mlfb,
    parentID,
    assetClassID
  )
  yield put({
    type: actions.LIST_MLFBDOCUMENT,
    result,
  })
}
  

export default function* rootSaga() {
  yield all([ 
    takeEvery(actions.FETCH_MLFBDOCUMENTS, fetchMlfbDocuments),
    takeEvery(actions.RE_FETCH_MLFBDOCUMENTS, fetchMlfbDocuments),
    takeEvery(actions.FETCH_MLFBDOCUMENT, fetchMlfbDocumentEdit),
    takeEvery(actions.SAVE_MLFBDOCUMENT, fetchMlfbDocumentSave),
    takeEvery(actions.DELETE_MLFBDOCUMENT, fetchMlfbDocumentDelete),
  ])
}
