import { getRequest, postRequest, getWorkorderID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('workOrderID'))
    searchTerm = `${searchTerm}, workOrderID:${getWorkorderID()},`
  return getRequest(
    `workOrderPartmap/get-list/1000/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`workOrderPartmap/edit/${id}`)
}

export async function save(values) {
  return postRequest(`workOrderPartmap/save`, values)
}

export function remove(values) {
  return postRequest(`workOrderPartmap/delete`, values)
}

export function quantity(id, location) {
  return getRequest(`part/quantity/${id}/${location || '%20'}`)
}

export function getManuelPartEdit(id) {
  return getRequest(`workOrderPartmap/get-material/${id}`)
}

export async function saveMaterial(values) {
  return postRequest(`workOrderPartmap/save-material`, values)
}

export async function deleteMaterial(values) {
  return postRequest(`workOrderPartmap/delete-material`, values)
}
