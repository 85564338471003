import { getRequest, postRequest, getInvoice2ID } from './api'

export async function list(size, index, searchTerm, orderBy) {
 const dsl = `invoice2Line/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}/${getInvoice2ID() || '%20'}`;

 console.log(dsl);
  return getRequest(dsl)
}

export async function find(id) {
  return getRequest(`invoice2Line/edit/${id}`)
}

export async function save(values) {
  return postRequest(`invoice2Line/save`, values)
}

export function remove(values) {
  return postRequest(`invoice2Line/delete`, values)
}
