import { getRequest, postRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(
    `device/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`device/edit/${id}`)
}

export async function save(values) {
  return postRequest(`device/save`, values)
}

export function remove(values) {
  return postRequest(`device/delete`, values)
}
