const actions = {
  REQUESTING: 'ContractBudgets/REQUESTING',
  RESPONSE_ERROR: 'ContractBudgets/RESPONSE_ERROR',

  FETCH_CONTRACTBUDGETS: 'ContractBudgets/FETCH',
  LIST_CONTRACTBUDGET: 'ContractBudget/LIST',

  FETCH_CONTRACTBUDGET: 'ContractBudget/FETCH',
  NEW_CONTRACTBUDGET: 'ContractBudget/NEW',
  EDIT_CONTRACTBUDGET: 'ContractBudget/EDIT',
  RESET_CONTRACTBUDGET: 'ContractBudget/RESET',

  RE_FETCH_CONTRACTBUDGETS: 'ContractBudgets/REFRESH',
  SAVE_CONTRACTBUDGET: 'ContractBudget/SAVE',
  SAVE_CONTRACTBUDGET_RECIEVED: 'ContractBudget/SAVE_CONTRACTBUDGET_RECIEVED',

  FETCH_CONTRACTBUDGETS_DATERANGE: 'ContractBudgets/FETCH_RANGE',

  DELETE_CONTRACTBUDGET: 'ContractBudget/DELETE',
  DELETE_CONTRACTBUDGET_RECIEVED: 'ContractBudget/DELETE_RECIEVED',
}

export default actions
