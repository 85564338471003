const actions = {
  REQUESTING: 'TechnicianDailyReports/REQUESTING',
  RESPONSE_ERROR: 'TechnicianDailyReports/RESPONSE_ERROR',

  FETCH_TECHNICIANDAILYREPORTS: 'TechnicianDailyReports/FETCH',
  LIST_TECHNICIANDAILYREPORT: 'TechnicianDailyReport/LIST',

  FETCH_TECHNICIANDAILYREPORT: 'TechnicianDailyReport/FETCH',
  NEW_TECHNICIANDAILYREPORT: 'TechnicianDailyReport/NEW',
  EDIT_TECHNICIANDAILYREPORT: 'TechnicianDailyReport/EDIT',
  RESET_TECHNICIANDAILYREPORT: 'TechnicianDailyReport/RESET',

  RE_FETCH_TECHNICIANDAILYREPORTS: 'TechnicianDailyReports/REFRESH',
  SAVE_TECHNICIANDAILYREPORT: 'TechnicianDailyReport/SAVE',
  SAVE_TECHNICIANDAILYREPORT_RECIEVED: 'TechnicianDailyReport/SAVE_TECHNICIANDAILYREPORT_RECIEVED',

  DELETE_TECHNICIANDAILYREPORT: 'TechnicianDailyReport/DELETE',
  DELETE_TECHNICIANDAILYREPORT_RECIEVED: 'TechnicianDailyReport/DELETE_RECIEVED',
}

export default actions
