import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { find, save, remove, list2 } from '../../services/inventoryClassCostType'

// function* fetchInventoryClassCostTypesRefresh() {
//   let fe = JSON.parse(localStorage.getItem('fetchInventoryClassCostTypes'))
//   if (fe == null) {
//     fe = {}
//     fe.size = localStorage.getItem('pageSize') || 15
//     fe.index = 0
//     fe.searchTerm = '%20'
//     fe.orderBy = '%20'
//   } else {
//     fe.searchTerm = '%20'
//     fe.orderBy = '%20'
//   }
//   yield put({
//     type: actions.REQUESTING,
//   })
//   const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
//   yield put({
//     type: actions.LIST_INVENTORYCLASSCOSTTYPE,
//     result,
//   })
// }

// function* fetchInventoryClassCostTypes({ size, index, searchTerm, orderBy }) {
//   // Load last params froom last session.
//   if (!size && !index && !searchTerm && !orderBy) {
//     const fe = null // JSON.parse(localStorage.getItem('fetchInventoryClassCostTypes'))
//     if (fe != null) {
//       size = fe.size || 15
//       index = fe.index || 0
//       searchTerm = fe.searchTerm || '%20'
//       orderBy = fe.orderBy || '%20'
//     } else {
//       size = size || localStorage.getItem('pageSize') || 15
//       index = 0
//       searchTerm = '%20'
//       orderBy = '%20'
//     }
//   }
//   localStorage.setItem(
//     'fetchInventoryClassCostTypes',
//     JSON.stringify({ size, index, searchTerm, orderBy }),
//   )
//   yield put({
//     type: actions.REQUESTING,
//   })
//   const result = yield call(list, size, index, searchTerm, orderBy)
//   yield put({
//     type: actions.LIST_INVENTORYCLASSCOSTTYPE,
//     result,
//   })
// }

function* fetchInventoryClassCostTypeEdit({ id, openModal }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id, openModal)
  yield put({
    type: actions.EDIT_INVENTORYCLASSCOSTTYPE,
    result,
    openModal
  })
}

function* fetchInventoryClassCostTypeSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_INVENTORYCLASSCOSTTYPE_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVENTORYCLASSCOSTTYPES,
  })
}

function* fetchInventoryClassCostTypeDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_INVENTORYCLASSCOSTTYPE_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVENTORYCLASSCOSTTYPES,
  })
}

function* fetchInventoryClassCostTypesFilter({
  size,
  index,
  searchTerm,
  orderBy,
  inventoryClassCode,
  costTypeCode,
  definitionCode,
  appropriation,
  code,
}) {

  try {
    const t = localStorage.getItem('fetchInventoryClassCostTypesFilter')
    console.log(t);

    if (t !== undefined && t != null) {
      try {
        const fe = JSON.parse(t)
        if (fe != null) {
          if (size === undefined) size = fe.size || size
          if (index === undefined) index = fe.index || index
          if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
          if (orderBy === undefined) orderBy = fe.orderBy || orderBy
          inventoryClassCode = fe.inventoryClassCode || inventoryClassCode
          costTypeCode = fe.costTypeCode || costTypeCode
          definitionCode = fe.definitionCode || definitionCode
          appropriation = fe.appropriation || appropriation
          code = fe.code || code
        }
      } catch (e) {
        console.log(e)
      }
    }
  } catch (e) {
    console.error(e);
  }

  size = size || localStorage.getItem('pageSize') || 15

  try {
    localStorage.setItem('fetchInventoryClassCostTypesFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        inventoryClassCode,
        costTypeCode,
        definitionCode,
        appropriation,
        code,
      })
    );
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: actions.REQUESTING,
  });

  const result = yield call(
    list2,
    size,
    index,
    searchTerm,
    orderBy,
    inventoryClassCode,
    costTypeCode,
    definitionCode,
    appropriation,
    code,
  )

  yield put({
    type: actions.LIST_INVENTORYCLASSCOSTTYPE,
    result,
  });

}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.FETCH_INVENTORYCLASSCOSTTYPES, fetchInventoryClassCostTypes),
    takeEvery(actions.FETCH_INVENTORYCLASSCOSTTYPE, fetchInventoryClassCostTypeEdit),
    takeEvery(actions.SAVE_INVENTORYCLASSCOSTTYPE, fetchInventoryClassCostTypeSave),
    takeEvery(actions.DELETE_INVENTORYCLASSCOSTTYPE, fetchInventoryClassCostTypeDelete),
    takeEvery(actions.RE_FETCH_INVENTORYCLASSCOSTTYPES, fetchInventoryClassCostTypesFilter),
    takeEvery(actions.FETCH_INVENTORYCLASSCOSTTYPES_FILTER, fetchInventoryClassCostTypesFilter),
  ])
}
