const actions = {
  REQUESTING: 'Intervals/REQUESTING',
  RESPONSE_ERROR: 'Intervals/RESPONSE_ERROR',

  FETCH_INTERVALS: 'Intervals/FETCH',
  LIST_INTERVAL: 'Interval/LIST',

  FETCH_INTERVAL: 'Interval/FETCH',
  NEW_INTERVAL: 'Interval/NEW',
  EDIT_INTERVAL: 'Interval/EDIT',
  RESET_INTERVAL: 'Interval/RESET',

  RE_FETCH_INTERVALS: 'Intervals/REFRESH',
  SAVE_INTERVAL: 'Interval/SAVE',
  SAVE_INTERVAL_RECIEVED: 'Interval/SAVE_INTERVAL_RECIEVED',

  DELETE_INTERVAL: 'Interval/DELETE',
  DELETE_INTERVAL_RECIEVED: 'Interval/DELETE_RECIEVED',
}

export default actions
