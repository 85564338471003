import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy, noContract) {
  searchTerm = `${searchTerm}`
  if (noContract === undefined) 
    searchTerm += `,ContractID:${getcontractIdNumber()}`
    
  return getRequest(
    `location/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`location/edit/${id}`)
}

export async function save(values) {
  return postRequest(`location/save`, values)
}

export function remove(values) {
  return postRequest(`location/delete`, values)
}
