const actions = {
  REQUESTING: 'EmailDeliverys/REQUESTING',
  RESPONSE_ERROR: 'EmailDeliverys/RESPONSE_ERROR',

  FETCH_EMAILDELIVERYS: 'EmailDeliverys/FETCH',
  LIST_EMAILDELIVERY: 'EmailDelivery/LIST',

  FETCH_EMAILDELIVERY: 'EmailDelivery/FETCH',
  NEW_EMAILDELIVERY: 'EmailDelivery/NEW',
  EDIT_EMAILDELIVERY: 'EmailDelivery/EDIT',
  RESET_EMAILDELIVERY: 'EmailDelivery/RESET',

  RE_FETCH_EMAILDELIVERYS: 'EmailDeliverys/REFRESH',
  SAVE_EMAILDELIVERY: 'EmailDelivery/SAVE',
  SAVE_EMAILDELIVERY_RECIEVED: 'EmailDelivery/SAVE_EMAILDELIVERY_RECIEVED',

  DELETE_EMAILDELIVERY: 'EmailDelivery/DELETE',
  DELETE_EMAILDELIVERY_RECIEVED: 'EmailDelivery/DELETE_RECIEVED',
}

export default actions
