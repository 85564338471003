const actions = {
  REQUESTING: 'TabletErrorLogs/REQUESTING',
  RESPONSE_ERROR: 'TabletErrorLogs/RESPONSE_ERROR',

  FETCH_TABLETERRORLOGS: 'TabletErrorLogs/FETCH',
  LIST_TABLETERRORLOG: 'TabletErrorLog/LIST',

  FETCH_TABLETERRORLOG: 'TabletErrorLog/FETCH',
  NEW_TABLETERRORLOG: 'TabletErrorLog/NEW',
  EDIT_TABLETERRORLOG: 'TabletErrorLog/EDIT',
  RESET_TABLETERRORLOG: 'TabletErrorLog/RESET',

  RE_FETCH_TABLETERRORLOGS: 'TabletErrorLogs/REFRESH',
  SAVE_TABLETERRORLOG: 'TabletErrorLog/SAVE',
  SAVE_TABLETERRORLOG_RECIEVED: 'TabletErrorLog/SAVE_TABLETERRORLOG_RECIEVED',

  DELETE_TABLETERRORLOG: 'TabletErrorLog/DELETE',
  DELETE_TABLETERRORLOG_RECIEVED: 'TabletErrorLog/DELETE_RECIEVED',
}

export default actions
