import { getRequest } from './api'

export function currentAccount() {
  const token = localStorage.getItem("token")
  if (token === null) {
    return null
  }
  return getRequest(`token/me/${token}`)
}

export function logout() {
  localStorage.removeItem("token")
  return getRequest(`token/logout`)
}
