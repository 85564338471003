import { getRequest, postRequest, getWorkorderID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('WorkOrderID'))
    searchTerm = `${searchTerm}, WorkOrderID:${getWorkorderID()},`
  return getRequest(
    `workOrderOperationCost/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy ||
      '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`workOrderOperationCost/edit/${id}`)
}

export async function save(values) {
  return postRequest(`workOrderOperationCost/save`, values)
}

export function remove(values) {
  return postRequest(`workOrderOperationCost/delete`, values)
}

export async function deleteAll() {
  const workOrderID = getWorkorderID()
  return getRequest(`workOrderOperationCost/deleteall/${workOrderID}`)
}
