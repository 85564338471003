import { getRequest, postRequest, getInvoiceID } from './api'

export async function list(id, unitType) {
  id = getInvoiceID()
  return getRequest(`invoiceDetail/get-list/${id}/${unitType || '%20'}`)
}

export async function find(id) {
  return getRequest(`invoiceDetail/edit/${id}`)
}

export async function save(values) {
  return postRequest(`invoiceDetail/save`, values)
}

export function remove(values) {
  return postRequest(`invoiceDetail/delete`, values)
}