const actions = {
  REQUESTING: 'Areas/REQUESTING',
  RESPONSE_ERROR: 'Areas/RESPONSE_ERROR',

  FETCH_AREAS: 'Areas/FETCH',
  LIST_AREA: 'Area/LIST',

  FETCH_AREA: 'Area/FETCH',
  NEW_AREA: 'Area/NEW',
  EDIT_AREA: 'Area/EDIT',
  RESET_AREA: 'Area/RESET',

  RE_FETCH_AREAS: 'Areas/REFRESH',
  SAVE_AREA: 'Area/SAVE',
  SAVE_AREA_RECIEVED: 'Area/SAVE_AREA_RECIEVED',

  DELETE_AREA: 'Area/DELETE',
  DELETE_AREA_RECIEVED: 'Area/DELETE_RECIEVED',
}

export default actions
