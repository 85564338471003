import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(`invoice/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`invoice/edit/${id}`)
}

export async function save(values) {
  return postRequest(`invoice/save`, values)
}

export function remove(values) {
  return postRequest(`invoice/delete`, values)
}

// export function create(workOrderStatus, location, area, address, begin, end) {
//   const contractID = getcontractIdNumber()
//   const url = `invoice/create/${contractID}/${workOrderStatus || '%20'}/${location || '%20'}/${area || '%20'}/${address || '%20'}/${begin || '%20'}/${end || '%20'}`
//   return getRequest(url)
// } 

export async function getProposal(id) {
  return getRequest(`invoice/get-proposal-by-workorder-listview/${id}`)
}

export async function makeInvoice() {
  const ContractID = getcontractIdNumber()
  return getRequest(`invoice/create/${ContractID}`)
}
