import { getRequest, postRequest, getWorkorderID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('workOrderID'))
    searchTerm = `${searchTerm}, workOrderID:${getWorkorderID()},`
  return getRequest(
    `workOrderInventoryServiceMap/get-list/${size || 15}/${index || 0}/${searchTerm ||
      '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`workOrderInventoryServiceMap/edit/${id}`)
}

export async function save(values) {
  return postRequest(`workOrderInventoryServiceMap/save`, values)
}

export function remove(values) {
  return postRequest(`workOrderInventoryServiceMap/delete`, values)
}
