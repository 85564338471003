import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `contractBudget/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function currentRange(searchTerm) {
  if (searchTerm.includes('AreaID:0') || searchTerm === 'AreaID:') searchTerm = ''
  if (searchTerm !== '') {
    if (!searchTerm.includes('ContractID'))
      searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
    return getRequest(`contractBudget/get-current-date-range/${searchTerm || '%20'}`)
  }
  return null
}

export async function find(id) {
  return getRequest(`contractBudget/edit/${id}`)
}

export async function save(values) {
  return postRequest(`contractBudget/save`, values)
}

export function remove(values) {
  return postRequest(`contractBudget/delete`, values)
}
