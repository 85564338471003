const actions = {
  REQUESTING: 'InventoryProductInformations/REQUESTING',
  RESPONSE_ERROR: 'InventoryProductInformations/RESPONSE_ERROR',

  FETCH_INVENTORYPRODUCTINFORMATIONS: 'InventoryProductInformations/FETCH',
  FETCH_INVENTORYPRODUCTINFORMATIONS_FILTER: 'InventoryProductInformations/FETCH_FILTER',
  LIST_INVENTORYPRODUCTINFORMATION: 'InventoryProductInformation/LIST',

  FETCH_INVENTORYPRODUCTINFORMATION: 'InventoryProductInformation/FETCH',
  NEW_INVENTORYPRODUCTINFORMATION: 'InventoryProductInformation/NEW',
  EDIT_INVENTORYPRODUCTINFORMATION: 'InventoryProductInformation/EDIT',
  RESET_INVENTORYPRODUCTINFORMATION: 'InventoryProductInformation/RESET',

  RE_FETCH_INVENTORYPRODUCTINFORMATIONS: 'InventoryProductInformations/REFRESH',
  SAVE_INVENTORYPRODUCTINFORMATION: 'InventoryProductInformation/SAVE',
  SAVE_INVENTORYPRODUCTINFORMATION_RECIEVED:
    'InventoryProductInformation/SAVE_INVENTORYPRODUCTINFORMATION_RECIEVED',

  DELETE_INVENTORYPRODUCTINFORMATION: 'InventoryProductInformation/DELETE',
  DELETE_INVENTORYPRODUCTINFORMATION_RECIEVED: 'InventoryProductInformation/DELETE_RECIEVED',
}

export default actions
