import { getRequest, postRequest, getPartTransferID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('PartTransferID'))
    searchTerm = `${searchTerm}, PartTransferID:${getPartTransferID()},`
  return getRequest(`partTransferRecieved/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`partTransferRecieved/edit/${id}`)
}

export async function save(values) {
  return postRequest(`partTransferRecieved/save`, values)
}

export function remove(values) {
  return postRequest(`partTransferRecieved/delete`, values)
}

export function updateQuantity(id) {
  return getRequest(`partTransferRecieved/update-quantity/${id}`)
}
