const actions = {
  REQUESTING: 'ContractBudgetInventoryClasss/REQUESTING',
  RESPONSE_ERROR: 'ContractBudgetInventoryClasss/RESPONSE_ERROR',

  FETCH_ContractBudgetInventoryClassS: 'ContractBudgetInventoryClasss/FETCH',
  LIST_ContractBudgetInventoryClass: 'ContractBudgetInventoryClass/LIST',

  FETCH_ContractBudgetInventoryClass: 'ContractBudgetInventoryClass/FETCH',
  NEW_ContractBudgetInventoryClass: 'ContractBudgetInventoryClass/NEW',
  EDIT_ContractBudgetInventoryClass: 'ContractBudgetInventoryClass/EDIT',
  RESET_ContractBudgetInventoryClass: 'ContractBudgetInventoryClass/RESET',

  RE_FETCH_ContractBudgetInventoryClassS: 'ContractBudgetInventoryClasss/REFRESH',
  SAVE_ContractBudgetInventoryClass: 'ContractBudgetInventoryClass/SAVE',
  SAVE_ContractBudgetInventoryClass_RECIEVED: 'ContractBudgetInventoryClass/SAVE_ContractBudgetInventoryClass_RECIEVED',

  DELETE_ContractBudgetInventoryClass: 'ContractBudgetInventoryClass/DELETE',
  DELETE_ContractBudgetInventoryClass_RECIEVED: 'ContractBudgetInventoryClass/DELETE_RECIEVED',
}

export default actions
