import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'

// app styles
import './global.scss'

// middlewared
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose
const store = createStore(
  reducers(history),
  composeEnhancers(applyMiddleware(...middlewares /* logger */)),
)
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }

// const hel = () => {
//   let div
//   if (!document.getElementById('div')) {
//     div = document.createElement('div')
//     div.style.width = '100px'
//     div.style.height = '20x'
//     div.style.position = 'fixed'
//     div.style.bottom = '0'
//     div.style.right = '0'
//     div.style.background = 'black'
//     div.style.color = 'white'
//     div.id = 'div'
//     div.style.textAlign = 'center'
//     document.getElementsByTagName('body')[0].appendChild(div)
//   } else {
//     div = document.getElementById('div')
//   }
//   div.innerHTML = `${window.innerWidth} x ${window.innerHeight}`
// }
// window.addEventListener('load', hel)
// window.addEventListener('resize', hel)
