const actions = {
  REQUESTING: 'WorkOrderPhotos/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderPhotos/RESPONSE_ERROR',

  FETCH_WORKORDERPHOTOS: 'WorkOrderPhotos/FETCH',
  LIST_WORKORDERPHOTO: 'WorkOrderPhoto/LIST',

  FETCH_WORKORDERPHOTO: 'WorkOrderPhoto/FETCH',
  NEW_WORKORDERPHOTO: 'WorkOrderPhoto/NEW',
  EDIT_WORKORDERPHOTO: 'WorkOrderPhoto/EDIT',
  RESET_WORKORDERPHOTO: 'WorkOrderPhoto/RESET',

  RE_FETCH_WORKORDERPHOTOS: 'WorkOrderPhotos/REFRESH',
  SAVE_WORKORDERPHOTO: 'WorkOrderPhoto/SAVE',
  SAVE_WORKORDERPHOTO_RECIEVED: 'WorkOrderPhoto/SAVE_WORKORDERPHOTO_RECIEVED',

  DELETE_WORKORDERPHOTO: 'WorkOrderPhoto/DELETE',
  DELETE_WORKORDERPHOTO_RECIEVED: 'WorkOrderPhoto/DELETE_RECIEVED',
}

export default actions
