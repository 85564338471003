const actions = {
  REQUESTING: 'Invoice2Lines/REQUESTING',
  RESPONSE_ERROR: 'Invoice2Lines/RESPONSE_ERROR',

  FETCH_INVOICE2LINES: 'Invoice2Lines/FETCH',
  LIST_INVOICE2LINE: 'Invoice2Line/LIST',

  FETCH_INVOICE2LINE: 'Invoice2Line/FETCH',
  NEW_INVOICE2LINE: 'Invoice2Line/NEW',
  EDIT_INVOICE2LINE: 'Invoice2Line/EDIT',
  RESET_INVOICE2LINE: 'Invoice2Line/RESET',

  RE_FETCH_INVOICE2LINES: 'Invoice2Lines/REFRESH',
  SAVE_INVOICE2LINE: 'Invoice2Line/SAVE',
  SAVE_INVOICE2LINE_RECIEVED: 'Invoice2Line/SAVE_INVOICE2LINE_RECIEVED',

  DELETE_INVOICE2LINE: 'Invoice2Line/DELETE',
  DELETE_INVOICE2LINE_RECIEVED: 'Invoice2Line/DELETE_RECIEVED',
}

export default actions
