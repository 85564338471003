import { getRequest, postRequest, getcontractIdNumber, getInventoryNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  if (!searchTerm.includes('InventoryNumber'))
    searchTerm = `${searchTerm}, InventoryNumber:${getInventoryNumber()},`
  return getRequest(
    `inventoryLog/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`inventoryLog/edit/${id}`)
}

export async function save(values) {
  return postRequest(`inventoryLog/save`, values)
}

export function remove(values) {
  return postRequest(`inventoryLog/delete`, values)
}
