const actions = {
  REQUESTING: 'WorkOrderOperationCosts/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderOperationCosts/RESPONSE_ERROR',
  AMOUNT_UPDATED: 'WorkOrderOperationCosts/AMOUNT_UPDATED',
  
  FETCH_WORKORDEROPERATIONCOSTS: 'WorkOrderOperationCosts/FETCH',
  FETCH_OPERATION_COSTS_DELETE_ALL: 'WorkOrderOperationCosts/DELETE_ALL',
  LIST_WORKORDEROPERATIONCOST: 'WorkOrderOperationCost/LIST',
  FETCH_OPERATION_COSTS_DELETE_ALL_RECIEVED: 'WorkOrderOperationCosts/DELETE_ALL_RECIEVED',

  FETCH_WORKORDEROPERATIONCOST: 'WorkOrderOperationCost/FETCH',
  NEW_WORKORDEROPERATIONCOST: 'WorkOrderOperationCost/NEW',
  EDIT_WORKORDEROPERATIONCOST: 'WorkOrderOperationCost/EDIT',
  RESET_WORKORDEROPERATIONCOST: 'WorkOrderOperationCost/RESET',

  RE_FETCH_WORKORDEROPERATIONCOSTS: 'WorkOrderOperationCosts/REFRESH',
  SAVE_WORKORDEROPERATIONCOST: 'WorkOrderOperationCost/SAVE',
  SAVE_WORKORDEROPERATIONCOST_RECIEVED: 'WorkOrderOperationCost/SAVE_WORKORDEROPERATIONCOST_RECIEVED',

  DELETE_WORKORDEROPERATIONCOST: 'WorkOrderOperationCost/DELETE',
  DELETE_WORKORDEROPERATIONCOST_RECIEVED: 'WorkOrderOperationCost/DELETE_RECIEVED',

  FETCH_OPERATION_COSTS: 'WorkOrders/FETCH_OPERATION_COSTS',
  RECIEVED_OPERATION_COSTS: 'WorkOrders/RECIEVED_OPERATION_COSTS',
}

export default actions
