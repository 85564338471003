import actions from './actions'

export default function WorkOrderCostTypeTotalsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_WORKORDERCOSTTYPETOTAL:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_WORKORDERCOSTTYPETOTAL:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_WORKORDERCOSTTYPETOTAL:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_WORKORDERCOSTTYPETOTAL:
      return {
        ...state,
        editing: { workOrderCostTypeTotalID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_WORKORDERCOSTTYPETOTAL_RECIEVED ||
      actions.UPDATE_WORKORDERCOSTTYPETOTAL_RECIEVED ||
      actions.DELETE_WORKORDERCOSTTYPETOTAL_RECIEVED:
      return {
        ...state,
        editing: { workOrderCostTypeTotalID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
