const actions = {
  REQUESTING: 'ClinYearPrices/REQUESTING',
  RESPONSE_ERROR: 'ClinYearPrices/RESPONSE_ERROR',

  FETCH_CLINYEARPRICES: 'ClinYearPrices/FETCH',
  LIST_CLINYEARPRICE: 'ClinYearPrice/LIST',

  FETCH_CLINYEARPRICE: 'ClinYearPrice/FETCH',
  NEW_CLINYEARPRICE: 'ClinYearPrice/NEW',
  EDIT_CLINYEARPRICE: 'ClinYearPrice/EDIT',
  RESET_CLINYEARPRICE: 'ClinYearPrice/RESET',

  RE_FETCH_CLINYEARPRICES: 'ClinYearPrices/REFRESH',
  SAVE_CLINYEARPRICE: 'ClinYearPrice/SAVE',
  SAVE_CLINYEARPRICE_RECIEVED: 'ClinYearPrice/SAVE_CLINYEARPRICE_RECIEVED',

  DELETE_CLINYEARPRICE: 'ClinYearPrice/DELETE',
  DELETE_CLINYEARPRICE_RECIEVED: 'ClinYearPrice/DELETE_RECIEVED',
}

export default actions
