import localeAntd from 'antd/es/locale/de_DE'
import messages from './localization-de-DE.json'

export default {
  locale: 'de-DE',
  localeAntd,
  messages,
}


