import actions from './actions'

export default function InvoicesReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_INVOICE:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_INVOICE:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_INVOICE_RECIEVED:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_INVOICE:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_INVOICE:
      return {
        ...state,
        editing: { invoiceID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_INVOICE_RECIEVED || actions.DELETE_INVOICE_RECIEVED:
      return {
        ...state,
        editing: { invoiceID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }

    case actions.Refresh_Recieved:
      return {
        ...state,
        refresh: true,
        loading: false,
      }

    case actions.Refresh_reset_Recieved:
      return {
        ...state,
        refresh: false,
        loading: false,
      }

    case actions.RESET_INVOICE_RECIEVED:
      return {
        ...state,
        editing: { invoiceID: 0 },
        newInvoice: false,
        loading: false,
      }

    case actions.CREATE_RESET_INVOICE_RECIEVED:
      return {
        ...state,
        newInvoice: false,
        loading: false,
      }

    case actions.MAKE_NEW_INVOICE_RECIEVED:
      return {
        ...state,
        loading: false,
        newInvoice: action.payload
      }

    default:
      return state
  }
}
