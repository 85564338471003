const actions = {
  REQUESTING: 'PartTransfers/REQUESTING',
  RESPONSE_ERROR: 'PartTransfers/RESPONSE_ERROR',

  FETCH_PARTTRANSFERS: 'PartTransfers/FETCH',
  LIST_PARTTRANSFER: 'PartTransfer/LIST',

  FETCH_PARTTRANSFER: 'PartTransfer/FETCH',
  NEW_PARTTRANSFER: 'PartTransfer/NEW',
  EDIT_PARTTRANSFER: 'PartTransfer/EDIT',
  RESET_PARTTRANSFER: 'PartTransfer/RESET',

  RE_FETCH_PARTTRANSFERS: 'PartTransfers/REFRESH',
  SAVE_PARTTRANSFER: 'PartTransfer/SAVE',
  SAVE_PARTTRANSFER_RECIEVED: 'PartTransfer/SAVE_PARTTRANSFER_RECIEVED',

  DELETE_PARTTRANSFER: 'PartTransfer/DELETE',
  DELETE_PARTTRANSFER_RECIEVED: 'PartTransfer/DELETE_RECIEVED',

  
  FETCH_PARTTRANSFER2: 'PartOrder/FETCH_PARTTRANSFER2',
  FETCH_PARTTRANSFER2_REC: 'PartOrder/FETCH_PARTTRANSFER2_REC',

}

export default actions
