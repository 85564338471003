const actions = {
  REQUESTING: 'DASHBOARD/REQUESTING',
  RESPONSE_ERROR: 'DASHBOARD/RESPONSE_ERROR',

  GetNextControlDatesMonthly: 'DASHBOARD/GetNextControlDatesMonthly',
  GetNextControlDatesMonthly_RECIEVED: 'DASHBOARD/GetNextControlDatesMonthly_RECIEVED',

  GetWorkOrderCountGroupByType: 'DASHBOARD/GetWorkOrderCountGroupByType',
  GetWorkOrderCountGroupByType_RECIEVED: 'DASHBOARD/GetWorkOrderCountGroupByType_RECIEVED',

  GetContractBudgets: 'DASHBOARD/GetContractBudgets',
  GetContractBudgets_RECIEVED: 'DASHBOARD/GetContractBudgets_RECIEVED',

  GetInventoryCount: 'DASHBOARD/GetInventoryCount',
  GetInventoryCount_RECIEVED: 'DASHBOARD/GetInventoryCount_RECIEVED',

  GetContractDetails: 'DASHBOARD/GetContractDetails',
  GetContractDetails_RECIEVED: 'DASHBOARD/GetContractDetails_RECIEVED',

  GetWorkOrderTotalAmountGroupByType: 'DASHBOARD/GetWorkOrderTotalAmountGroupByType',
  GetWorkOrderTotalAmountGroupByType_RECIEVED: 'DASHBOARD/GetWorkOrderTotalAmountGroupByType_RECIEVED',

  GetWorkOrderOverLimits: 'DASHBOARD/GetWorkOrderOverLimits',
  GetWorkOrderOverLimitsRecieved: 'DASHBOARD/GetWorkOrderOverLimitsRecieved',

  GetContractBudgetsClass: 'DASHBOARD/GetContractBudgetsClass',
  GetContractBudgetsClass_RECIEVED: 'DASHBOARD/GetContractBudgetsClass_RECIEVED',
}

export default actions
