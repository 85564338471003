import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/invoice2Line'

function* fetchInvoice2LinesRefresh() {
  const fe = JSON.parse(localStorage.getItem('fetchInvoice2Lines'))
  if (fe == null) {
    fe.size = 10
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_INVOICE2LINE,
    result,
  })
}

function* fetchInvoice2Lines({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchInvoice2Lines'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchInvoice2Lines', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_INVOICE2LINE,
    result,
  })
}

function* fetchInvoice2LineEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_INVOICE2LINE,
    result,
  })
}

function* fetchInvoice2LineSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_INVOICE2LINE_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVOICE2LINES,
  })
}

function* fetchInvoice2LineDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_INVOICE2LINE_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVOICE2LINES,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INVOICE2LINES, fetchInvoice2Lines),
    takeEvery(actions.FETCH_INVOICE2LINE, fetchInvoice2LineEdit),
    takeEvery(actions.SAVE_INVOICE2LINE, fetchInvoice2LineSave),
    takeEvery(actions.DELETE_INVOICE2LINE, fetchInvoice2LineDelete),
    takeEvery(actions.RE_FETCH_INVOICE2LINES, fetchInvoice2LinesRefresh),
  ])
}
