const actions = {
  REQUESTING: 'WorkOrders/REQUESTING',
  RESPONSE_ERROR: 'WorkOrders/RESPONSE_ERROR',

  FETCH_WORKORDERS: 'WorkOrders/FETCH',
  FETCH_WORKORDERS_FILTER: 'WorkOrders/FETCH_FILTER',
  LIST_WORKORDER: 'WorkOrder/LIST',

  FETCH_WORKORDER_COSTVIEW: 'WorkOrders/FETCH_WORKORDER_COSTVIEW',
  FETCH_WORKORDER_COSTVIEW_RECIEVED: 'WorkOrders/FETCH_WORKORDER_COSTVIEW_RECIEVED',
  FETCH_WORKORDER_COSTVIEW_RESET: 'WorkOrders/FETCH_WORKORDER_COSTVIEW_RESET',
  FETCH_WORKORDER_COSTVIEW_RESET_RECIEVED: 'WorkOrders/FETCH_WORKORDER_COSTVIEW_RESET_RECIEVED',

  FETCH_WORKORDER: 'WorkOrder/FETCH',
  NEW_WORKORDER: 'WorkOrder/NEW',
  EDIT_WORKORDER: 'WorkOrder/EDIT',
  RESET_WORKORDER: 'WorkOrder/RESET',

  RE_FETCH_WORKORDERS: 'WorkOrders/REFRESH',
  SAVE_WORKORDER: 'WorkOrder/SAVE',
  SAVE_WORKORDER_RECIEVED: 'WorkOrder/SAVE_WORKORDER_RECIEVED',

  DELETE_WORKORDER: 'WorkOrder/DELETE',
  DELETE_WORKORDER_RECIEVED: 'WorkOrder/DELETE_RECIEVED',

  FETCH_NEXTCONTROLS: 'WorkOrder/FETCH_NEXTCONTROLS',
  FETCH_NEXTCONTROLS_RECIEVED: 'WorkOrder/FETCH_NEXTCONTROLS_RECIEVED',

  CREATE_AUTO_WORKORDERS: 'WorkOrders/CREATE_AUTO',
  CREATE_AUTO_WORKORDERS_CATCH: 'WorkOrders/CREATE_AUTO_CATCH',

  SKIP_AUTO_WORKORDERS_CATCH: 'WorkOrders/SKIP_AUTO_CATCH',
  SKIP_AUTO_WORKORDERS: 'WorkOrders/SKIP_AUTO',

  CREATE_NEW_WORKORDER: 'WorkOrder/CREATE',
  CREATE_NEW_WORKORDER_RECIEVED: 'WorkOrder/CREATE_RECIEVED',
  CREATE_NEW_WORKORDER_WITH_ASSET: 'WorkOrder/CREATE_NEW_WORKORDER_WITH_ASSET',

  CREATE_NEW_WORKORDER_RESET: 'WorkOrder/CREATE_RESET',
  CREATE_NEW_WORKORDER_RESET_REQ: 'WorkOrder/CREATE_RESET_REQ',
  CREATE_NEW_WORKORDER_RESET_RECIEVED: 'WorkOrder/CREATE_RESET_RECIEVED',

  FETCH_WORKORDER_ASSET_REPORT: 'WorkOrders/FETCH_WORKORDER_ASSET_REPORT',
  FETCH_WORKORDER_ASSET_REPORT_RESET: 'WorkOrders/FETCH_WORKORDER_ASSET_REPORT_RESET',
  FETCH_WORKORDER_ASSET_REPORT_RESET_RECIEVED: 'WorkOrders/FETCH_WORKORDER_ASSET_REPORT_RESET_RECIEVED',
  FETCH_WORKORDER_ASSET_REPORT_RECIEVED: 'WorkOrder/FETCH_WORKORDER_ASSET_REPORT_RECIEVED',
  FETCH_WORKORDER_ASSET_COUNT: 'WorkOrders/FETCH_WORKORDER_ASSET_COUNT',
  FETCH_WORKORDER_ASSET_COUNT_RECIEVED: 'WorkOrders/FETCH_WORKORDER_ASSET_COUNT_RECIEVED',

  RECALL_WORKORDER: 'WorkOrder/RECALL',
  RECALL_WORKORDER_RECIEVED: 'WorkOrder/RECALL_RECIEVED',

  SAVE_ASSET: 'WorkOrder/SAVE_ASSET',
  SAVE_SERVICE: 'WorkOrder/SAVE_SERBICE',

  SAVEPDF: 'WorkOrder/SAVEPDF',
  SAVEPDF_RECIEVED: 'WorkOrder/SAVEPDF_RECIEVED',

  PRINT_POPUP: 'WorkOrder/PRINT_POPUP',
  PRINT_POPUP_RECIEVED: 'WorkOrder/PRINT_POPUP_RECIEVED',
  PRINT_POPUP_RESET: 'WorkOrder/PRINT_POPUP_RESET',
  PRINT_POPUP_RESET_RECIEVED: 'WorkOrder/PRINT_POPUP_RESET_RECIEVED',

  GET_APPOINTMENT_DATE: 'WorkOrder/GET_APPOINTMENT_DATE',
  GET_APPOINTMENT_DATE_RECIEVED: 'WorkOrder/GET_APPOINTMENT_DATE_RECIEVED',

  GET_APPOINTMENT_DATE_RESET: 'WorkOrder/GET_APPOINTMENT_DATE_RESET',
  GET_APPOINTMENT_DATE_RESET_RECIEVED: 'WorkOrder/GET_APPOINTMENT_DATE_RESET_RECIEVED',

  GET_COSTVIEW2: 'WorkOrder/GET_COSTVIEW2',
  GET_COSTVIEW_RECIVEVED2: 'WorkOrder/GET_COSTVIEW_RECIVEVED2',
 
  ADD_INVENTORY: 'WorkOrder/ADD_INVENTORY',
  ADD_INVENTORY_RECIEVED: 'WorkOrder/ADD_INVENTORY_RECIEVED',
  ADD_INVENTORY_RESET: 'WorkOrder/ADD_INVENTORY_RESET',
  ADD_INVENTORY_RESET_RECIEVED: 'WorkOrder/ADD_INVENTORY_RESET_RECIEVED',

}

export default actions
