import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`

  return getRequest(`supplier/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`supplier/edit/${id}`)
}

export async function save(values) {
  return postRequest(`supplier/save`, values)
}

export function remove(values) {
  return postRequest(`supplier/delete`, values)
}
