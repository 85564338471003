const actions = {
  REQUESTING: 'WorkOrderPartMaps/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderPartMaps/RESPONSE_ERROR',
  AMOUNT_UPDATED: 'WorkOrderPartMaps/AMOUNT_UPDATED',

  FETCH_WORKORDERPARTMAPS: 'WorkOrderPartMaps/FETCH',
  LIST_WORKORDERPARTMAP: 'WorkOrderPartMap/LIST',

  FETCH_WORKORDERPARTMAP: 'WorkOrderPartMap/FETCH',
  NEW_WORKORDERPARTMAP: 'WorkOrderPartMap/NEW',
  EDIT_WORKORDERPARTMAP: 'WorkOrderPartMap/EDIT',
  RESET_WORKORDERPARTMAP: 'WorkOrderPartMap/RESET',

  RE_FETCH_WORKORDERPARTMAPS: 'WorkOrderPartMaps/REFRESH',

  SAVE_WORKORDERPARTMAP: 'WorkOrderPartMap/SAVE',
  SAVE_WORKORDERPARTMAP_RECIEVED: 'WorkOrderPartMap/SAVE_WORKORDERPARTMAP_RECIEVED',

  DELETE_WORKORDERPARTMAP: 'WorkOrderPartMap/DELETE',
  DELETE_WORKORDERPARTMAP_RECIEVED: 'WorkOrderPartMap/DELETE_RECIEVED',

  FETCH_WORKORDERPARTMAPS_QTY: 'WorkOrderPartMapsQuantity/FETCH',
  FETCH_WORKORDERPARTMAPS_QTY_RECIEVED: 'WorkOrderPartMapsQuantity/FETCH_RECIEVED',

  FETCH_MANUEL_PART_EDIT: 'FETCH_MANUEL_PART_EDIT',
  FETCH_MANUEL_PART_EDIT_RECIEVED: 'FETCH_MANUEL_PART_EDIT_RECIEVED',
  
  FETCH_MANUEL_PART_RESET: 'FETCH_MANUEL_PART_RESET',
  FETCH_MANUEL_PART_RESET_RECIEVED: 'FETCH_MANUEL_PART_RESET_RECIEVED',

  
  SAVE_MANUEL_PART_EDIT: 'SAVE_MANUEL_PART_EDIT',
  SAVE_MANUEL_PART_EDIT_RECIEVED: 'SAVE_MANUEL_PART_EDIT_RECIEVED',


  DELETE_MATERIAL: 'DELETE_MATERIAL',
  DELETE_MATERIAL_RECIEVED: 'DELETE_MATERIAL_RECIEVED',
  
}

export default actions
