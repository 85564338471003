const actions = {
  REQUESTING: 'ServiceRequests/REQUESTING',
  RESPONSE_ERROR: 'ServiceRequests/RESPONSE_ERROR',

  FETCH_SERVICEREQUESTS: 'ServiceRequests/FETCH',
  LIST_SERVICEREQUEST: 'ServiceRequest/LIST',

  FETCH_SERVICEREQUEST: 'ServiceRequest/FETCH',
  NEW_SERVICEREQUEST: 'ServiceRequest/NEW',
  EDIT_SERVICEREQUEST: 'ServiceRequest/EDIT',
  RESET_SERVICEREQUEST: 'ServiceRequest/RESET',

  RE_FETCH_SERVICEREQUESTS: 'ServiceRequests/REFRESH',
  SAVE_SERVICEREQUEST: 'ServiceRequest/SAVE',
  SAVE_SERVICEREQUEST_RECIEVED: 'ServiceRequest/SAVE_SERVICEREQUEST_RECIEVED',

  DELETE_SERVICEREQUEST: 'ServiceRequest/DELETE',
  DELETE_SERVICEREQUEST_RECIEVED: 'ServiceRequest/DELETE_RECIEVED',
}

export default actions
