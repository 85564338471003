/* eslint-disable */


import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/proposalDetail'


function* fetchPROPOSALDETAIL_PRE_PRICEs({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, id, 3) //  unitType = 3 = PRE_PRICE
  yield put({
    type: actions.LIST_PROPOSALDETAIL_PRE_PRICE,
    result,
  });
}

function* fetchPROPOSALDETAIL_PRE_PRICEEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PROPOSALDETAIL_PRE_PRICE,
    result,
  })
}

function* fetchPROPOSALDETAIL_PRE_PRICESave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const resultSave = yield call(save, values)
  if (resultSave !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC });
    yield put({
      type: 'Refresh_Proposal'
    });
  }
}

function* fetchPROPOSALDETAIL_PRE_PRICEDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC });
    yield put({
      type: 'Refresh_Proposal'
    });
  } 
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_PROPOSALDETAIL_PRE_PRICES, fetchPROPOSALDETAIL_PRE_PRICEs),
    takeEvery(actions.FETCH_PROPOSALDETAIL_PRE_PRICE, fetchPROPOSALDETAIL_PRE_PRICEEdit),
    takeEvery(actions.SAVE_PROPOSALDETAIL_PRE_PRICE, fetchPROPOSALDETAIL_PRE_PRICESave),
    takeEvery(actions.DELETE_PROPOSALDETAIL_PRE_PRICE, fetchPROPOSALDETAIL_PRE_PRICEDelete),
  ])
}
