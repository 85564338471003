export default async function getBasicData() {
  return [
    {
      key: 'address',
      icon: 'fe fe-home',
      role: 'admin',
      children: [
        {
          key: 'areas',
          url: '/areas',
        },
        {
          key: 'locations',
          url: '/locations',
        },
        {
          key: 'address',
          url: '/addresses',
        },
      ],
    },
    {
      key: 'clin2',
      icon: 'fe fe-command',
      url: '/costtypes',
    },
    {
      key: 'elin',
      icon: 'fe fe-shopping-cart',
      url: '/inventoryclasscosttypes',
    },
    {
      key: 'inventorys_g',
      icon: 'fe fe-bookmark',
      url: '/inventories',
    },
    {
      key: 'parts',
      icon: 'fe fe-package',
      url: '/parts',
    }   
  ]
}
