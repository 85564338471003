import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`;
  return getRequest(`clinBudget/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`clinBudget/edit/${id}`)
}

export async function save(values) {
  return postRequest(`clinBudget/save`, values)
}

export async function remove(values) {
  return postRequest(`clinBudget/delete`, values)
}

export async function getWorkOrderBudgets(workOrderID) {
  const cs = getcontractIdNumber()

  return getRequest(`clinBudget/get-budget-view/${workOrderID}/${cs}`)
}
