import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC, DEAKTIVATE_200_DESC } from '../../constants'
import { find, save, remove, mlfb, list2 } from '../../services/inventory'

// function* fetchInventorysRefresh() {
//   let fe = JSON.parse(localStorage.getItem('fetchInventorys'))
//   if (fe == null) {
//     fe = {}
//     fe.size = localStorage.getItem('pageSize') || 15
//     fe.index = 0
//     fe.searchTerm = '%20'
//     fe.orderBy = '%20'
//   } else {
//     fe.searchTerm = '%20'
//     fe.orderBy = '%20'
//   }
//   yield put({
//     type: actions.REQUESTING,
//   })
//   const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
//   yield put({
//     type: actions.LIST_INVENTORY,
//     result,
//   })
// }

// function* fetchInventorys({ size, index, searchTerm, orderBy }) {
//   if (
//     JSON.parse(localStorage.getItem('fetchInventorys')) ===
//     JSON.stringify({ size, index, searchTerm, orderBy })
//   ) {
//     return
//   }
//   // Load last params froom last session.
//   if (!size && !index && !searchTerm && !orderBy) {
//     const fe = null // JSON.parse(localStorage.getItem('fetchInventorys'))
//     if (fe != null) {
//       size = fe.size || 15
//       index = fe.index || 0
//       searchTerm = fe.searchTerm || '%20'
//       orderBy = fe.orderBy || '%20'
//     } else {
//       size = size || localStorage.getItem('pageSize') || 15
//       index = 0
//       searchTerm = '%20'
//       orderBy = '%20'
//     }
//   }
//   localStorage.setItem('fetchInventorys', JSON.stringify({ size, index, searchTerm, orderBy }))
//   yield put({
//     type: actions.REQUESTING,
//   })
//   const result = yield call(list, size, index, searchTerm, orderBy)
//   yield put({
//     type: actions.LIST_INVENTORY,
//     result,
//   })
// }

function* fetchInventoryEdit({ id, openModal }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id, openModal)
  yield put({
    type: actions.EDIT_INVENTORY,
    result,
    openModal,
  })
}

function* fetchInventorySave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_INVENTORY_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVENTORYS,
  })
}

function* fetchInventoryDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    if (result === "Deactive") {
      notification.success({ message: DEL_200_MSG, description: DEAKTIVATE_200_DESC })
    } else {
      notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
    }
  }
  yield put({
    type: actions.DELETE_INVENTORY_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVENTORYS,
  })
}

function* fetchMlfb({ id }) {
  if (id === null) {
    yield put({
      type: actions.FETCH_MLFB_RECIEVED,
      result: null,
    })
  } else {
    yield put({
      type: actions.FETCH_MLFB_REQ,
    })
    const result = yield call(mlfb, id)
    yield put({
      type: actions.FETCH_MLFB_RECIEVED,
      result,
    })
  }
}

function* fetchInventorysFilter({
  size,
  index,
  searchTerm,
  orderBy,
  inventoryNumber,
  deviceInfo,
  appropriation,
  className,
  areaName,
  location,
  street,
  model,
  serialNumber,
  mlfbNumber,
  emhBarcode,
  status,
  costTypeCode
}) {

  const t = localStorage.getItem('fetchInventorysFilter')
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy

        inventoryNumber = fe.inventoryNumber || inventoryNumber
        deviceInfo = fe.deviceInfo || deviceInfo
        appropriation = fe.appropriation || appropriation
        className = fe.className || className
        areaName = fe.areaName || areaName
        location = fe.location || location
        street = fe.street || street
        model = fe.model || model
        serialNumber = fe.serialNumber || serialNumber
        mlfbNumber = fe.mlfbNumber || mlfbNumber
        emhBarcode = fe.emhBarcode || emhBarcode
        status = fe.status || status
        costTypeCode = fe.costTypeCode || costTypeCode
      }
    } catch (e) {
      console.log(e)
    }
  }

  size = size || localStorage.getItem('pageSize') || 15

  try {
    localStorage.setItem('fetchInventorysFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        inventoryNumber,
        deviceInfo,
        appropriation,
        className,
        areaName,
        location,
        street,
        model,
        serialNumber,
        mlfbNumber,
        emhBarcode,
        status,
        costTypeCode
      })
    )
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: actions.REQUESTING,
  });

  const result = yield call(
    list2,
    size,
    index,
    searchTerm,
    orderBy,
    inventoryNumber,
    deviceInfo,
    appropriation,
    className,
    areaName,
    location,
    street,
    model,
    serialNumber,
    mlfbNumber,
    emhBarcode,
    status,
    costTypeCode
  )
  yield put({
    type: actions.LIST_INVENTORY,
    result,
  })
}

function* resetInventory() {
  yield put({
    type: actions.RESET_INVENTORY_REC,
  })
}

function* confirmModalReset() {
  yield put({
    type: actions.CONFIRM_MODAL_RESET_RECIEVER,
  })
}

function* confirmModalOpen() {
  yield put({
    type: actions.CONFIRM_MODAL_OPEN_RECIEVER,
  })
}

function* confirmOkcatch() {
  yield put({
    type: actions.CONFIRM_CHOOSE_OK_RECIEVER,
  })
}

function* addLocationCatch({ id }) {
  yield put({
    type: actions.ADD_LOCATION_RECIEVED,
    payload: id
  })
}

function* addLocationReset() {
  yield put({
    type: actions.ADD_LOCATION_RESET_RECIEVED,
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_MLFB, fetchMlfb),

    // takeEvery(actions.FETCH_INVENTORYS, fetchInventorys),

    takeEvery(actions.RESET_INVENTORY, resetInventory),
    takeEvery(actions.FETCH_INVENTORY, fetchInventoryEdit),
    
    takeEvery(actions.SAVE_INVENTORY, fetchInventorySave),
    takeEvery(actions.DELETE_INVENTORY, fetchInventoryDelete),
    takeEvery(actions.RE_FETCH_INVENTORYS, fetchInventorysFilter),
    takeEvery(actions.FETCH_INVENTORYS_FILTER, fetchInventorysFilter),


    takeEvery(actions.CONFIRM_MODAL_RESET, confirmModalReset),
    takeEvery(actions.CONFIRM_MODAL_OPEN, confirmModalOpen),
    takeEvery(actions.CONFIRM_CHOOSE_OK, confirmOkcatch),

    takeEvery(actions.ADD_LOCATION, addLocationCatch),
    takeEvery(actions.ADD_LOCATION_RESET, addLocationReset),
  ])
}
