const actions = {
  REQUESTING: 'WorkOrderInventoryLogs/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderInventoryLogs/RESPONSE_ERROR',

  FETCH_WorkOrderInventoryLogS: 'WorkOrderInventoryLogs/FETCH',
  LIST_WorkOrderInventoryLog: 'WorkOrderInventoryLog/LIST',

  FETCH_WorkOrderInventoryLog: 'WorkOrderInventoryLog/FETCH',
  NEW_WorkOrderInventoryLog: 'WorkOrderInventoryLog/NEW',
  EDIT_WorkOrderInventoryLog: 'WorkOrderInventoryLog/EDIT',
  RESET_WorkOrderInventoryLog: 'WorkOrderInventoryLog/RESET',

  RE_FETCH_WorkOrderInventoryLogS: 'WorkOrderInventoryLogs/REFRESH',
  SAVE_WorkOrderInventoryLog: 'WorkOrderInventoryLog/SAVE',
  SAVE_WorkOrderInventoryLog_RECIEVED: 'WorkOrderInventoryLog/SAVE_WorkOrderInventoryLog_RECIEVED',

  DELETE_WorkOrderInventoryLog: 'WorkOrderInventoryLog/DELETE',
  DELETE_WorkOrderInventoryLog_RECIEVED: 'WorkOrderInventoryLog/DELETE_RECIEVED',
}

export default actions
