import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, list2 } from '../../services/technicianPart'

// function* fetchTechnicianPartsRefresh() {
//   const fe = JSON.parse(localStorage.getItem('fetchTechnicianParts'))
//   if (fe == null) {
//     fe.size = 10
//     fe.index = 0
//     fe.searchTerm = '%20'
//     fe.orderBy = '%20'
//   }
//   yield put({
//     type: actions.REQUESTING,
//   })
//   const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
//   yield put({
//     type: actions.LIST_TECHNICIANPART,
//     result,
//   })
// }

function* fetchTechnicianParts({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchTechnicianParts'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchTechnicianParts', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_TECHNICIANPART,
    result,
  })
}

function* fetchTechnicianPartEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_TECHNICIANPART,
    result,
  })
}

function* fetchTechnicianPartSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_TECHNICIANPART_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_TECHNICIANPARTS,
  })
}

function* fetchTechnicianPartDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_TECHNICIANPART_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_TECHNICIANPARTS,
  })
}

function* fetchPartsFilter({ size, index, searchTerm, orderBy, partID, technicianID }) {
  const t = localStorage.getItem('fetchTechnicianPartsFilter')
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        partID = fe.partID || partID
        technicianID = fe.technicianID || technicianID
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(
      'fetchTechnicianPartsFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        partID,
        technicianID,
      }),
    )
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list2, size, index, searchTerm, orderBy, partID, technicianID)
  yield put({
    type: actions.LIST_TECHNICIANPART,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_TECHNICIANPARTS, fetchTechnicianParts),
    takeEvery(actions.FETCH_TECHNICIANPART, fetchTechnicianPartEdit),
    takeEvery(actions.SAVE_TECHNICIANPART, fetchTechnicianPartSave),
    takeEvery(actions.DELETE_TECHNICIANPART, fetchTechnicianPartDelete),
    takeEvery(actions.RE_FETCH_TECHNICIANPARTS, fetchPartsFilter),
    takeEvery(actions.FETCH_TECHNICIANPARTS_FILTER, fetchPartsFilter),
  ])
}
