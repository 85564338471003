const actions = {
  REQUESTING: 'Parts/REQUESTING',
  RESPONSE_ERROR: 'Parts/RESPONSE_ERROR',

  FETCH_PARTS: 'Parts/FETCH',
  FETCH_PARTS_FILTER: 'Parts/FETCH_FILTER',
  LIST_PART: 'Part/LIST',

  FETCH_PART: 'Part/FETCH',
  NEW_PART: 'Part/NEW',
  EDIT_PART: 'Part/EDIT',
  RESET_PART: 'Part/RESET',

  RE_FETCH_PARTS: 'Parts/REFRESH',
  SAVE_PART: 'Part/SAVE',
  SAVE_PART_RECIEVED: 'Part/SAVE_PART_RECIEVED',

  DELETE_PART: 'Part/DELETE',
  DELETE_PART_RECIEVED: 'Part/DELETE_RECIEVED',

  CLONE_PART: 'Part/CLONE',
  CLONE_PART_RECIEVED: 'Part/CLONE_RECIEVED',

  SEARCH: 'Part/SEARCH',
  SEARCH_REC: 'Part/SEARCH_REC',
  SEARCH_REC_RESET: 'Part/SEARCH/RESET',
  SEARCH_REC_RESET_RECIEVED: 'Part/SEARCH/RESET_RECIEVED',

  SAVE_PART_LUPP_CHECK: 'Part/SAVE_PART_LUPP_CHECK',
  SAVE_PART_LUPP_CHECK_RECIEVED: 'Part/SAVE_PART_LUPP_CHECK_RECIEVED',

  NEW_PART_STATUS_MODAL: 'Part/NEW_PART_STATUS_MODAL',
  NEW_PART_STATUS_MODAL_RECIEVED: 'Part/NEW_PART_STATUS_MODAL_RECIEVED',

  NEW_PART_STATUS_MODAL_RESET: 'Part/NEW_PART_STATUS_MODAL_RESET',
  NEW_PART_STATUS_MODAL_RESET_RECIEVED: 'Part/NEW_PART_STATUS_MODAL_RESET_RECIEVED',

  CONFIRM_MODAL_OPEN: 'CONFIRM_MODAL_OPEN',
  CONFIRM_MODAL_RESET: 'CONFIRM_MODAL_RESET',
  CONFIRM_CHOOSE_OK: 'CONFIRM_CHOOSE_OK',

  CONFIRM_MODAL_OPEN_RECIEVER: 'CONFIRM_MODAL_OPEN_RECIEVER',
  CONFIRM_MODAL_RESET_RECIEVER: 'CONFIRM_MODAL_RESET_RECIEVER',
  CONFIRM_CHOOSE_OK_RECIEVER: 'CONFIRM_CHOOSE_OK_RECIEVER',
}

export default actions
