/* eslint-disable */


const actions = {
  REQUESTING: 'PROPOSALDETAIL_PRE_PRICEs/REQUESTING',
  RESPONSE_ERROR: 'PROPOSALDETAIL_PRE_PRICEs/RESPONSE_ERROR',

  FETCH_PROPOSALDETAIL_PRE_PRICES: 'PROPOSALDETAIL_PRE_PRICEs/FETCH',
  LIST_PROPOSALDETAIL_PRE_PRICE: 'PROPOSALDETAIL_PRE_PRICE/LIST',

  FETCH_PROPOSALDETAIL_PRE_PRICE: 'PROPOSALDETAIL_PRE_PRICE/FETCH',
  NEW_PROPOSALDETAIL_PRE_PRICE: 'PROPOSALDETAIL_PRE_PRICE/NEW',
  EDIT_PROPOSALDETAIL_PRE_PRICE: 'PROPOSALDETAIL_PRE_PRICE/EDIT',
  RESET_PROPOSALDETAIL_PRE_PRICE: 'PROPOSALDETAIL_PRE_PRICE/RESET',

  RE_FETCH_PROPOSALDETAIL_PRE_PRICES: 'PROPOSALDETAIL_PRE_PRICEs/REFRESH',
  SAVE_PROPOSALDETAIL_PRE_PRICE: 'PROPOSALDETAIL_PRE_PRICE/SAVE',
  SAVE_PROPOSALDETAIL_PRE_PRICE_RECIEVED: 'PROPOSALDETAIL_PRE_PRICE/SAVE_PROPOSALDETAIL_PRE_PRICE_RECIEVED',

  DELETE_PROPOSALDETAIL_PRE_PRICE: 'PROPOSALDETAIL_PRE_PRICE/DELETE',
  DELETE_PROPOSALDETAIL_PRE_PRICE_RECIEVED: 'PROPOSALDETAIL_PRE_PRICE/DELETE_RECIEVED',
}

export default actions
