const actions = {
  REQUESTING: 'ClinBudgets/REQUESTING',
  RESPONSE_ERROR: 'ClinBudgets/RESPONSE_ERROR',

  FETCH_CLINBUDGETS: 'ClinBudgets/FETCH',
  LIST_CLINBUDGET: 'ClinBudget/LIST',

  FETCH_CLINBUDGET: 'ClinBudget/FETCH',
  NEW_CLINBUDGET: 'ClinBudget/NEW',
  EDIT_CLINBUDGET: 'ClinBudget/EDIT',
  RESET_CLINBUDGET: 'ClinBudget/RESET',

  RE_FETCH_CLINBUDGETS: 'ClinBudgets/REFRESH',
  SAVE_CLINBUDGET: 'ClinBudget/SAVE',
  SAVE_CLINBUDGET_RECIEVED: 'ClinBudget/SAVE_CLINBUDGET_RECIEVED',

  DELETE_CLINBUDGET: 'ClinBudget/DELETE',
  DELETE_CLINBUDGET_RECIEVED: 'ClinBudget/DELETE_RECIEVED',

  FETCH_WORKORDER_BUDGETS: 'FETCH_WORKORDER_BUDGETS',
  FETCH_WORKORDER_BUDGETS_RECIEVED: 'FETCH_WORKORDER_BUDGETS_RECIEVED',

  RESET_WORKORDER_BUDGETS: 'RESET_WORKORDER_BUDGETS',
  RESET_WORKORDER_BUDGETS_RECIEVED: 'RESET_WORKORDER_BUDGETS_RECIEVED',

}

export default actions
