const actions = {
  REQUESTING: 'PartOrderArriveds/REQUESTING',
  RESPONSE_ERROR: 'PartOrderArriveds/RESPONSE_ERROR',

  FETCH_PARTORDERARRIVEDS: 'PartOrderArriveds/FETCH',
  LIST_PARTORDERARRIVED: 'PartOrderArrived/LIST',

  FETCH_PARTORDERARRIVED: 'PartOrderArrived/FETCH',
  NEW_PARTORDERARRIVED: 'PartOrderArrived/NEW',
  EDIT_PARTORDERARRIVED: 'PartOrderArrived/EDIT',
  RESET_PARTORDERARRIVED: 'PartOrderArrived/RESET',

  RE_FETCH_PARTORDERARRIVEDS: 'PartOrderArriveds/REFRESH',
  SAVE_PARTORDERARRIVED: 'PartOrderArrived/SAVE',
  SAVE_PARTORDERARRIVED_RECIEVED: 'PartOrderArrived/SAVE_PARTORDERARRIVED_RECIEVED',

  DELETE_PARTORDERARRIVED: 'PartOrderArrived/DELETE',
  DELETE_PARTORDERARRIVED_RECIEVED: 'PartOrderArrived/DELETE_RECIEVED',
  
  UPDATE_QUANTITY: 'PartOrderArrived/UPDATE_QUANTITY',
  UPDATE_QUANTITY_RECIEVED: 'PartOrderArrived/UPDATE_QUANTITY_RECIEVED',
}

export default actions
