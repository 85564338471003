import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'
import { VERSION,APPLICAITON_OWNER } from '../../../constants/index'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner}>
          <div className="row">
            <div className="col-md-8">
              <p>{APPLICAITON_OWNER}</p>
            </div>
            <div className="col-md-4">
              <div className={style.logo}>
                {/* <img src="/resources/images/softlare-logo.png" alt="Softlare" /> */}
                {/* <div className={style.logoName}>SFM</div> */}
                <div className={style.logoDescr}>
                  <b>SFM</b> Version {VERSION}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
