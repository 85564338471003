export default async function getMenuData() {
    return [
        {
            key: 'workorderlist',
            url: '/workorders',
            icon: 'fe fe-clipboard',
        },
        {
            key: 'inventories',
            icon: 'fe fe-bookmark',
            url: '/inventories',
        },
        {
            key: 'inventoryclass',
            icon: 'fe fe-hard-drive',
            url: '/inventoryclasses',
        },
        {
            key: 'inventoryServices',
            icon: 'fe fe-server',
            url: '/inventoryservices',
        },
        {
            key: 'parts',
            icon: 'fe fe-package',
            url: '/parts',
        },
        {
            key: 'partOrders',
            icon: 'fe fe-shopping-cart',
            url: '/partorders',
        },
        {
            key: 'partTransfers',
            icon: 'fe fe-truck',
            url: '/partTransfers',
        },
        {
            key: 'inventoryProductInformations',
            url: '/inventoryProductInformations',
            icon: 'fe fe-tag',
        },
        {
            key: 'inventoryServiceLabors',
            icon: 'fe fe-sliders',
            url: '/inventoryServiceLabors',
        },
        {
            key: 'technicians',
            icon: 'fe fe-git-branch',
            url: '/technicians',
        },
        {
            key: 'inventoryclasscosttype',
            icon: 'fe fe-shopping-cart',
            url: '/inventoryclasscosttypes',
        },


        {
            key: 'costtype',
            icon: 'fe fe-command',
            url: '/costtypes',
        },
        {
            key: 'contractbudget',
            icon: 'fe fe-credit-card',
            url: '/contractbudgets',
        },
        {
            key: 'address',
            icon: 'fe fe-home',
            role: 'admin',
            children: [
                {
                    key: 'areas',
                    url: '/areas',
                },
                {
                    key: 'locations',
                    url: '/locations',
                },
                {
                    key: 'address',
                    url: '/addresses',
                },
            ],
        },
        {
            key: 'repairLimits',
            icon: 'fe fe-briefcase',
            url: '/repairLimits',
        },
        //   {
        //     key: 'technicianParts',
        //     icon: 'fe fe-package',
        //     url: '/technicianParts',
        //     role: 'user',
        //   },
        {
            key: 'technicianDailyReports',
            icon: 'fe fe-mail',
            url: '/technicianDailyReports',
            role: 'user',
        },
        //   {
        //     key: 'nextcontrols',
        //     icon: 'fe fe-command',
        //     url: '/nextcontrols'
        //   },

        {
            key: 'mlfbDocuments',
            icon: 'fe fe-folder',
            children: [
                {
                    key: 'mlfbDocumentsFolder',
                    url: '/mlfbDocumentsFolder'
                },
                {
                    key: 'mlfbDocuments',
                    url: '/mlfbDocuments'
                },
            ],
        },


        {
            key: 'contractBudgetInventoryClassMenuName',
            icon: 'fe fe-credit-card',
            url: '/budget-class',
        },
        //   {
        //     key: 'contactPerson',
        //     icon: 'fe fe-user',
        //     url: '/contactPersons',
        //   },
        {
            key: 'suppliers',
            icon: 'fe fe-truck',
            url: '/suppliers',
        },
        {
            key: 'dashboards',
            icon: 'fe fe-activity',
            url: '/dashboard/analytics',
        },
        {
            key: 'workOrderInventoryLogs',
            icon: 'fe fe-git-branch',
            url: '/workOrderInventoryLogs',
        },

    ]
}

