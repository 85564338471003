const actions = {
  REQUESTING: 'Contracts/REQUESTING',
  RESPONSE_ERROR: 'Contracts/RESPONSE_ERROR',

  FETCH_CONTRACTS: 'Contracts/FETCH',
  LIST_CONTRACT: 'Contract/LIST',

  FETCH_CONTRACT: 'Contract/FETCH',
  NEW_CONTRACT: 'Contract/NEW',
  EDIT_CONTRACT: 'Contract/EDIT',
  RESET_CONTRACT: 'Contract/RESET',

  RE_FETCH_CONTRACTS: 'Contracts/REFRESH',
  SAVE_CONTRACT: 'Contract/SAVE',
  SAVE_CONTRACT_RECIEVED: 'Contract/SAVE_CONTRACT_RECIEVED',

  DELETE_CONTRACT: 'Contract/DELETE',
  DELETE_CONTRACT_RECIEVED: 'Contract/DELETE_RECIEVED',

  SELECT_CONTRACT: 'SELECT_CONTRACT',
  UNSELECT_CONTRACT: 'UNSELECT_CONTRACT',
  CHOOSE_CONTRACT: 'CHOOSE_CONTRACT',
  FETCH_CURRENT_BUDGET: 'FETCH_CURRENT_BUDGET'
}

export default actions
