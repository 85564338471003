import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list2, find, save, remove } from '../../services/technicianDailyReport'

function* fetchTechnicianDailyReportEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_TECHNICIANDAILYREPORT,
    result,
  })
}

function* fetchTechnicianDailyReportSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_TECHNICIANDAILYREPORT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_TECHNICIANDAILYREPORTS,
  })
}

function* fetchTechnicianDailyReportDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_TECHNICIANDAILYREPORT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_TECHNICIANDAILYREPORTS,
  })
}







function* fetchTechnicianDailyReports({
  size,
  index,
  searchTerm,
  orderBy,
  reportDate,
  technicianID,
   status
}) {

  const t = localStorage.getItem('fetchTechnicianDailyReportsFilter')
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        reportDate = fe.reportDate || reportDate
        status = fe.status || status
        technicianID = fe.technicianID || technicianID
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(
      'fetchTechnicianDailyReportsFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        reportDate,
        technicianID,
         status
      }),
    )
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    list2,
    size,
    index,
    searchTerm,
    orderBy,
    reportDate,
     technicianID,
      status
  )

  yield put({
    type: actions.LIST_TECHNICIANDAILYREPORT,
    result,
  })

} 

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_TECHNICIANDAILYREPORTS, fetchTechnicianDailyReports),
    takeEvery(actions.FETCH_TECHNICIANDAILYREPORT, fetchTechnicianDailyReportEdit),
    takeEvery(actions.SAVE_TECHNICIANDAILYREPORT, fetchTechnicianDailyReportSave),
    takeEvery(actions.DELETE_TECHNICIANDAILYREPORT, fetchTechnicianDailyReportDelete),
    takeEvery(actions.RE_FETCH_TECHNICIANDAILYREPORTS, fetchTechnicianDailyReports),
  ])
}
