/*eslint-disable*/

import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/proposalFile'

function* fetchProposalFiles() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, 0) // automatic finding id
  yield put({
    type: actions.LIST_ProposalFile,
    result,
  })
}

function* fetchProposalFileEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_ProposalFile,
    result,
  })
}

function* fetchProposalFileSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const resultSave = yield call(save, values)
  if (resultSave !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC });
    const result = yield call(list, 0) // automatic finding id
    yield put({
      type: actions.LIST_ProposalFile,
      result,
    });
  }
}

function* fetchProposalFileDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const resultSave = yield call(remove, values)
  if (resultSave !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC });
    const result = yield call(list, 0) // automatic finding id
    yield put({
      type: actions.LIST_ProposalFile,
      result,
    });
  } 
}


function* fetchProposalFiles2({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, id) // automatic finding id
  yield put({
    type: actions.LIST_ProposalFile,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ProposalFilesWorkOrder, fetchProposalFiles2),

    takeEvery(actions.FETCH_ProposalFileS, fetchProposalFiles),
    takeEvery(actions.FETCH_ProposalFile, fetchProposalFileEdit),
    takeEvery(actions.SAVE_ProposalFile, fetchProposalFileSave),
    takeEvery(actions.DELETE_ProposalFile, fetchProposalFileDelete),
  ])
}
