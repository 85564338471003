/* eslint-disable */

import actions from './actions'

export default function PROPOSALDETAIL_MATERIALsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null
      }
    case actions.LIST_PROPOSALDETAIL_MATERIAL:
      return {
        ...state,
        result: action.result,
        loading: false
      }
    case actions.EDIT_PROPOSALDETAIL_MATERIAL:
      return {
        ...state,
        editing: action.result,
        loading: false
      }
    case actions.RESET_PROPOSALDETAIL_MATERIAL:
      return {
        ...state,
        editing: null,
        loading: false
      }
    case actions.NEW_PROPOSALDETAIL_MATERIAL:
      return {
        ...state,
        editing: { PROPOSALDETAIL_MATERIALID: 0 },
        loading: false
      }
    case actions.DELETE_PROPOSALDETAIL_MATERIAL_RECIEVED:
      return {
        ...state,
        editing: { PROPOSALDETAIL_MATERIALID: 0 },
        loading: false
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message
      }
    case actions.EDIT_PROPOSALDETAIL_MATERIAL_ONFLY:
      return {
        ...state,
        onfly: action.payload,
        loading: false
      }
    case actions.RESET_PROPOSALDETAIL_MATERIAL_ONFLY:
      return {
        ...state,
        onfly: null,
        loading: false
      }


      
    case actions.FETCH_MANUEL_PART_EDIT_RECIEVED:
      return {
        ...state,
        updatemanuel: action.result,
        loading: false,
        saveSuccess: false,
      }

    case actions.FETCH_MANUEL_PART_RESET_RECIEVED:
      return {
        ...state,
        updatemanuel: null,
        loading: false,
      }

    case actions.DELETE_MATERIAL_RECIEVED:
      return {
        ...state,
        updatemanuel: null,
        loading: false,
      }




    default:
      return state
  }
}
