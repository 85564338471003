const actions = {
  REQUESTING: 'TechnicianDailyRecords/REQUESTING',
  RESPONSE_ERROR: 'TechnicianDailyRecords/RESPONSE_ERROR',

  FETCH_TECHNICIANDAILYRECORDS: 'TechnicianDailyRecords/FETCH',
  LIST_TECHNICIANDAILYRECORD: 'TechnicianDailyRecord/LIST',

  FETCH_TECHNICIANDAILYRECORD: 'TechnicianDailyRecord/FETCH',
  NEW_TECHNICIANDAILYRECORD: 'TechnicianDailyRecord/NEW',
  EDIT_TECHNICIANDAILYRECORD: 'TechnicianDailyRecord/EDIT',
  RESET_TECHNICIANDAILYRECORD: 'TechnicianDailyRecord/RESET',

  RE_FETCH_TECHNICIANDAILYRECORDS: 'TechnicianDailyRecords/REFRESH',
  SAVE_TECHNICIANDAILYRECORD: 'TechnicianDailyRecord/SAVE',
  SAVE_TECHNICIANDAILYRECORD_RECIEVED: 'TechnicianDailyRecord/SAVE_TECHNICIANDAILYRECORD_RECIEVED',

  DELETE_TECHNICIANDAILYRECORD: 'TechnicianDailyRecord/DELETE',
  DELETE_TECHNICIANDAILYRECORD_RECIEVED: 'TechnicianDailyRecord/DELETE_RECIEVED',
}

export default actions
