const actions = {
  REQUESTING: 'ExternalInvoiceLogs/REQUESTING',
  RESPONSE_ERROR: 'ExternalInvoiceLogs/RESPONSE_ERROR',

  FETCH_EXTERNALINVOICELOGS: 'ExternalInvoiceLogs/FETCH',
  LIST_EXTERNALINVOICELOG: 'ExternalInvoiceLog/LIST',

  FETCH_EXTERNALINVOICELOG: 'ExternalInvoiceLog/FETCH',
  NEW_EXTERNALINVOICELOG: 'ExternalInvoiceLog/NEW',
  EDIT_EXTERNALINVOICELOG: 'ExternalInvoiceLog/EDIT',
  RESET_EXTERNALINVOICELOG: 'ExternalInvoiceLog/RESET',

  RE_FETCH_EXTERNALINVOICELOGS: 'ExternalInvoiceLogs/REFRESH',
  SAVE_EXTERNALINVOICELOG: 'ExternalInvoiceLog/SAVE',
  SAVE_EXTERNALINVOICELOG_RECIEVED: 'ExternalInvoiceLog/SAVE_EXTERNALINVOICELOG_RECIEVED',

  DELETE_EXTERNALINVOICELOG: 'ExternalInvoiceLog/DELETE',
  DELETE_EXTERNALINVOICELOG_RECIEVED: 'ExternalInvoiceLog/DELETE_RECIEVED',
}

export default actions
