/* eslint-disable */

const actions = {
  REQUESTING: 'PROPOSALDETAIL_MATERIALs/REQUESTING',
  RESPONSE_ERROR: 'PROPOSALDETAIL_MATERIALs/RESPONSE_ERROR',
  FETCH_PROPOSALDETAIL_MATERIALS: 'PROPOSALDETAIL_MATERIALs/FETCH',
  LIST_PROPOSALDETAIL_MATERIAL: 'PROPOSALDETAIL_MATERIAL/LIST',
  FETCH_PROPOSALDETAIL_MATERIAL: 'PROPOSALDETAIL_MATERIAL/FETCH',
  NEW_PROPOSALDETAIL_MATERIAL: 'PROPOSALDETAIL_MATERIAL/NEW',
  EDIT_PROPOSALDETAIL_MATERIAL: 'PROPOSALDETAIL_MATERIAL/EDIT',
  RESET_PROPOSALDETAIL_MATERIAL: 'PROPOSALDETAIL_MATERIAL/RESET',
  RE_FETCH_PROPOSALDETAIL_MATERIALS: 'PROPOSALDETAIL_MATERIALs/REFRESH',
  SAVE_PROPOSALDETAIL_MATERIAL: 'PROPOSALDETAIL_MATERIAL/SAVE',
  SAVE_PROPOSALDETAIL_MATERIAL_RECIEVED: 'PROPOSALDETAIL_MATERIAL/SAVE_PROPOSALDETAIL_MATERIAL_RECIEVED',
  DELETE_PROPOSALDETAIL_MATERIAL: 'PROPOSALDETAIL_MATERIAL/DELETE',
  DELETE_PROPOSALDETAIL_MATERIAL_RECIEVED: 'PROPOSALDETAIL_MATERIAL/DELETE_RECIEVED',
  EDIT_PROPOSALDETAIL_MATERIAL_ONFLY_GET: 'EDIT_PROPOSALDETAIL_MATERIAL_ONFLY_GET',
  EDIT_PROPOSALDETAIL_MATERIAL_ONFLY: 'EDIT_PROPOSALDETAIL_MATERIAL_ONFLY',
  RESET_PROPOSALDETAIL_MATERIAL_ONFLY_GET: 'RESET_PROPOSALDETAIL_MATERIAL_ONFLY_GET',
  RESET_PROPOSALDETAIL_MATERIAL_ONFLY: 'RESET_PROPOSALDETAIL_MATERIAL_ONFLY',

  
  FETCH_MANUEL_PART_EDIT: 'PROPOSAL/FETCH_MANUEL_PART_EDIT',
  FETCH_MANUEL_PART_EDIT_RECIEVED: 'PROPOSAL/FETCH_MANUEL_PART_EDIT_RECIEVED',  
  
  FETCH_MANUEL_PART_RESET: 'PROPOSAL/FETCH_MANUEL_PART_RESET',
  FETCH_MANUEL_PART_RESET_RECIEVED: 'PROPOSAL/FETCH_MANUEL_PART_RESET_RECIEVED',  
  
  SAVE_MANUEL_PART_EDIT: 'PROPOSAL/SAVE_MANUEL_PART_EDIT',
  SAVE_MANUEL_PART_EDIT_RECIEVED: 'PROPOSAL/SAVE_MANUEL_PART_EDIT_RECIEVED',
  
  DELETE_MATERIAL: 'PROPOSAL/DELETE_MATERIAL',
  DELETE_MATERIAL_RECIEVED: 'PROPOSAL/DELETE_MATERIAL_RECIEVED'  
}

export default actions