import { getRequest, postRequest, getWorkorderID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('workOrderID'))
    searchTerm = `${searchTerm}, workOrderID:${getWorkorderID()},`
  return getRequest(
    `workOrderPhoto/get-list/99999/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`workOrderPhoto/edit/${id}`)
}

export async function save(values) {
  return postRequest(`workOrderPhoto/save`, values)
}

export function remove(values) {
  return postRequest(`workOrderPhoto/delete`, values)
}
