import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Input, Checkbox, Select } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import ddl from 'redux/dropdowns/actions'
import actions from 'redux/userss/actions'

export default () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const { userss, dropdowns } = useSelector(state => ({
    userss: state.userss,
    dropdowns: state.dropdowns,
  }))

  useEffect(() => {
    dispatch({ type: ddl.FETCH_DROPDOWN, entity: ddl.CONTRACTS })
  }, [dispatch])

  useEffect(() => {
    const { profile } = userss
    if (profile !== undefined && profile !== null) {
      try {
        form.setFieldsValue({
          contracts: profile.contracts,
          userName: profile.userName,
          gender: profile.gender !== null ? profile.gender.toString() : '',
          pageSize: profile.pageSize !== null ? profile.pageSize.toString() : '',
          name: profile.name,
          surname: profile.surname,
          phone: profile.phone,
          mobile: profile.mobile,
          email: profile.email,
          password: profile.password,
          roleName: profile.roleName,
          prID: profile.prID,
          userImage: profile.userImage,
          isActive: profile.isActive,
          language: profile.language,
          passwordLocked: profile.passwordLocked,
        })
      } catch (e) {
        // console.log(e)
      }
      setVisible(true)
    }
    // eslint-disable-next-line
  }, [userss.profile])

  const onSubmit = () => {
    const fields = form.getFieldsValue()
    form
      .validateFields()
      .then(values => {
        const { profile } = userss
        values.userID = profile.userID
        values.contracts = fields.contracts
        setLoading(true)
        setTimeout(() => {
          dispatch({
            type: actions.SAVE_USERS,
            values,
          })
        }, 100)
      })
      .catch(hmm => {
        console.log(hmm)
      })
  }

  const onCancel = () => {
    setVisible(false)
    dispatch({ type: actions.RESET_USERS })
  }

  const requiredRule = [
    {
      required: true,
      message: <FormattedMessage id="required.field" />,
    },
  ]

  return (
    <div>
      <Modal
        title={<FormattedMessage id="edit.profile" />}
        visible={visible}
        footer={null}
        getContainer={false}
        onCancel={onCancel}
        width="575px"
      >
        <Form
          form={form}
          {...{
            labelCol: { xs: { span: 24 }, sm: { span: 8 } },
            wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
          }}
          labelAlign="left"
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name)
          }}
        >
          <div style={{ display: 'none' }}>
            <Form.Item name="contracts" label={<FormattedMessage id="contractID" />}>
              <Select
                showSearch
                mode="multiple"
                onSelect={value => {
                  console.log(value)
                }}
                filterOption={(input, option) => {
                  return (
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }}
              >
                {dropdowns &&
                  dropdowns.contracts &&
                  dropdowns.contracts.options.map(item => {
                    return (
                      <Select.Option
                        key={`${item.value}${item.key}`}
                        name={item.key}
                        value={item.value.toString()}
                      >
                        <div className="zip-code-item">
                          <span className="colStart">
                            <span className="zip-city">{item.key}</span>
                          </span>
                          <span className="colEnd">
                            <span className="state-name">{item.description}</span>
                          </span>
                        </div>
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            style={{ display: 'none' }}
            name="userName"
            label={<FormattedMessage id="userName" />}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item style={{ display: 'none' }} name="passwordLocked">
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item name="password" label={<FormattedMessage id="password" />}>
            <Input autoComplete="off" type="password" />
          </Form.Item>

          <Form.Item
            style={{ display: 'none' }}
            name="roleName"
            label={<FormattedMessage id="role" />}
          >
            <Select
              showSearch
              onSelect={value => {
                form.setFieldsValue({ roleName: value })
              }}
            >
              <Select.Option value="">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">-</span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="admin">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">
                      <FormattedMessage id="admin" />
                    </span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="operator">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">
                      <FormattedMessage id="operator" />
                    </span>
                  </span>
                </div>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="gender" label={<FormattedMessage id="gender" />}>
            <Select
              showSearch
              onSelect={value => {
                form.setFieldsValue({ gender: value })
              }}
            >
              <Select.Option value="0">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">-</span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="1">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">
                      <FormattedMessage id="herr" />
                    </span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="2">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">
                      <FormattedMessage id="frau" />
                    </span>
                  </span>
                </div>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="email" label={<FormattedMessage id="email" />}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="name" label={<FormattedMessage id="vorname" />}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="surname" label={<FormattedMessage id="surname" />}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="phone" label={<FormattedMessage id="phone" />}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="mobile" label={<FormattedMessage id="mobile" />}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name="prID" label={<FormattedMessage id="prID" />}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="pageSize"
            label={<FormattedMessage id="pageSize" />}
            rules={requiredRule}
          >
            <Select
              onChange={value => {
                form.setFieldsValue({ pageSize: value })
              }}
            >
              <Select.Option value="15">
                15 / <FormattedMessage id="items" />
              </Select.Option>
              <Select.Option value="30">
                30 / <FormattedMessage id="items" />
              </Select.Option>
              <Select.Option value="60">
                60 / <FormattedMessage id="items" />
              </Select.Option>
              <Select.Option value="100">
                100 / <FormattedMessage id="items" />
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="language" label={<FormattedMessage id="language" />}>
            <Select
              onSelect={value => {
                form.setFieldsValue({ language: value })
              }}
            >
              <Select.Option value="de-DE">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">Deutsch</span>
                  </span>
                </div>
              </Select.Option>
              <Select.Option value="en-US">
                <div className="zip-code-item">
                  <span className="colStart">
                    <span className="zip-city">English</span>
                  </span>
                </div>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            style={{ display: 'none' }}
            name="isActive"
            label={<FormattedMessage id="status" />}
            valuePropName="checked"
          >
            <Checkbox>
              <FormattedMessage id="isActive" />
            </Checkbox>
          </Form.Item>
          <div className="ant-modal-footer actions">
            <div className="row">
              <div className="col-md-5 col-sm-5 text-left" />
              <div className="col-md-7 col-sm-7">
                <Button key="back" onClick={onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
                <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
                  <FormattedMessage id="submit" />
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
