const actions = {
  REQUESTING: 'ProposalFiles/REQUESTING',
  RESPONSE_ERROR: 'ProposalFiles/RESPONSE_ERROR',

  FETCH_ProposalFilesWorkOrder: 'ProposalFiles/FETCH_3',

  FETCH_ProposalFileS: 'ProposalFiles/FETCH',
  LIST_ProposalFile: 'ProposalFile/LIST',

  FETCH_ProposalFile: 'ProposalFile/FETCH',
  NEW_ProposalFile: 'ProposalFile/NEW',
  EDIT_ProposalFile: 'ProposalFile/EDIT',
  RESET_ProposalFile: 'ProposalFile/RESET',

  RE_FETCH_ProposalFileS: 'ProposalFiles/REFRESH',
  SAVE_ProposalFile: 'ProposalFile/SAVE',
  SAVE_ProposalFile_RECIEVED: 'ProposalFile/SAVE_ProposalFile_RECIEVED',

  DELETE_ProposalFile: 'ProposalFile/DELETE',
  DELETE_ProposalFile_RECIEVED: 'ProposalFile/DELETE_RECIEVED',
}

export default actions
