const actions = {
  REQUESTING: 'Suppliers/REQUESTING',
  RESPONSE_ERROR: 'Suppliers/RESPONSE_ERROR',

  FETCH_SUPPLIERS: 'Suppliers/FETCH',
  LIST_SUPPLIER: 'Supplier/LIST',

  FETCH_SUPPLIER: 'Supplier/FETCH',
  NEW_SUPPLIER: 'Supplier/NEW',
  EDIT_SUPPLIER: 'Supplier/EDIT',
  RESET_SUPPLIER: 'Supplier/RESET',

  RE_FETCH_SUPPLIERS: 'Suppliers/REFRESH',
  SAVE_SUPPLIER: 'Supplier/SAVE',
  SAVE_SUPPLIER_RECIEVED: 'Supplier/SAVE_SUPPLIER_RECIEVED',

  DELETE_SUPPLIER: 'Supplier/DELETE',
  DELETE_SUPPLIER_RECIEVED: 'Supplier/DELETE_RECIEVED',
}

export default actions
