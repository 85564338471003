import actions from './actions'

export default function InventoryServicesReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_INVENTORYSERVICE:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_INVENTORYSERVICE:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_INVENTORYSERVICE:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_INVENTORYSERVICE:
      return {
        ...state,
        editing: { inventoryServiceID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_INVENTORYSERVICE_RECIEVED ||
      actions.UPDATE_INVENTORYSERVICE_RECIEVED ||
      actions.DELETE_INVENTORYSERVICE_RECIEVED:
      return {
        ...state,
        editing: { inventoryServiceID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
