import { getRequest, postRequest, getWorkorderID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  const workOrderID = getWorkorderID()
  if (!searchTerm.includes('workOrderID'))
    searchTerm = `${searchTerm}, workOrderID:${workOrderID},`
  return getRequest(
    `workOrderTechnicianMap/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy ||
      '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`workOrderTechnicianMap/edit/${id}`)
}

export async function save(values) {
  return postRequest(`workOrderTechnicianMap/save`, values)
}

export function remove(values) {
  return postRequest(`workOrderTechnicianMap/delete`, values)
}
