import React from 'react'
import { connect } from 'react-redux'
import { accessMenu } from 'role'
import { Link } from 'react-router-dom'


import { getContractAppType } from 'services/api'
import MenuPages from './MenuPages'
import MenuPages2 from './MenuPagesContract'
import MenuPages3 from './MenuPagesReports'
import MenuBasicData from './MenuBasicData'
import LanguageSwitcher from './LanguageSwitcher'
import UserMenu from './UserMenu'
import style from './style.module.scss'

@connect(({ contracts, user }) => ({ contracts, user }))
class TopBarDark extends React.Component {
  render() {
    const { contracts, user } = this.props
    const appType = getContractAppType()
    return (
      <div className={style.topbarDark}>
        <Link to="/contracts" className={`${style.topbarDark__logo} d-md-block topbarDarkLogo`}>
          {/* <a className={`${style.topbarDark__logo} d-md-block topbarDarkLogo`}> */}
          <img src="/resources/images/protec-logo.png" alt="softlare" style={{ width: 130, marginRight: 10 }} />
          {/* <div className={style.topbarDark__logo__name}>softlare</div> */}
          <div className={`${style.topbarDark__logo__descr} d-none d-lg-block topbarDarkLogo`} style={{ marginTop: 8 }}>
            Contract Management System
          </div>
          {/* </a> */}
        </Link>
        <div className="mr-3 d-block">
          {user.role !== 'admin' && accessMenu('general') && <MenuPages />}
          {user.role === 'admin' && <MenuPages />}
        </div>
        {appType === 2 &&
          <div className="mr-3 d-block">
            {contracts.selected && user.role !== 'admin' && accessMenu('basicdata') && <MenuBasicData />}
            {contracts.selected && user.role === 'admin' && <MenuBasicData />}
          </div>
        }
        <div className="mr-3 d-block">
          {contracts.selected && user.role !== 'admin' && accessMenu('contract') && <MenuPages2 />}
          {contracts.selected && user.role === 'admin' && <MenuPages2 />}
        </div>
        <div className="mr-auto d-block">
          {contracts.selected && user.role !== 'admin' && accessMenu('reports') && <MenuPages3 />}
          {contracts.selected && user.role === 'admin' && <MenuPages3 />}
        </div>
        {/* <div className="mb-0 mr-4 d-xl-block d-none"><Status /></div> */}
        <div className="mr-4 d-md-block">
          <LanguageSwitcher />
        </div>
        {/* <div className="mr-4 d-none d-md-block"><Actions /></div> */}
        <div className="d-md-block">
          <UserMenu />
        </div>
      </div>
    )
  }
}

export default TopBarDark