const actions = {
  REQUESTING: 'Addresss/REQUESTING',
  RESPONSE_ERROR: 'Addresss/RESPONSE_ERROR',

  FETCH_ADDRESSS: 'Addresss/FETCH',
  LIST_ADDRESS: 'Address/LIST',

  FILTER_ADDRESS: 'Address/FILTER_ADDRESS',
  FILTER_ADDRESS_RECIEVED: 'FILTER_ADDRESS_RECIEVED',

  FETCH_ADDRESS: 'Address/FETCH',
  NEW_ADDRESS: 'Address/NEW',
  EDIT_ADDRESS: 'Address/EDIT',
  RESET_ADDRESS: 'Address/RESET',

  RE_FETCH_ADDRESSS: 'Addresss/REFRESH',
  SAVE_ADDRESS: 'Address/SAVE',
  SAVE_ADDRESS_RECIEVED: 'Address/SAVE_ADDRESS_RECIEVED',

  DELETE_ADDRESS: 'Address/DELETE',
  DELETE_ADDRESS_RECIEVED: 'Address/DELETE_RECIEVED',
}

export default actions
