import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(`repairLimit/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`repairLimit/edit/${id}`)
}

export async function save(values) {
  return postRequest(`repairLimit/save`, values)
}

export function remove(values) {
  return postRequest(`repairLimit/delete`, values)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy,
  inventoryClassCode,
  mlfb,
  installYear
) {
  const contractID = getcontractIdNumber()
  const url = `repairLimit/get-list2/${size || '15'}/${index || '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${contractID}/${inventoryClassCode || '%20'}/${mlfb || '%20'}/${installYear || '%20'}`
  return getRequest(url)
}
