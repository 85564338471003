import actions from './actions'

const resultItems = { result: { items: undefined } }

const initialState = {
  loading: false,
  workorders: resultItems,
  inventories: resultItems,
  workordercosts: resultItems,
  workordercosts2: resultItems,
  parts: resultItems,
  workOrdersPm: resultItems,
  workorderMaintenances: resultItems
}

export default function ReportsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
      }
    case actions.FETCH_WORKORDER_REPORTS_RESET:
      return {
        ...state,
        loading: false,
        workorders: resultItems,
      }
    case actions.FETCH_INVENTORY_REPORTS_RESET:
      return {
        ...state,
        loading: false,
        inventories: resultItems,
      }
    case actions.FETCH_WORKORDERCOST_REPORTS_RESET:
      return {
        ...state,
        loading: false,
        workordercosts: resultItems,
      }
    case actions.FETCH_WONUMBER_WORKORDERCOST_REPORTS_RESET:
      return {
        ...state,
        loading: false,
        workordercosts2: resultItems,
      }
    case actions.FETCH_PART_REPORT_RECIEVED:
      return {
        ...state,
        loading: false,
        parts: { result: action.result },
      }
    case actions.FETCH_PART_REPORT_RESET:
      return {
        ...state,
        loading: false,
        parts: resultItems,
      }
    case actions.FETCH_WORKORDER_REPORTS_RECIEVED:
      return {
        ...state,
        loading: false,
        workorders: { result: action.result },
      }

    case actions.FETCH_WORKORDER_REPORTS_MAINTENANCE_RECIEVED:
      return {
        ...state,
        loading: false,
        workorderMaintenances: { result: action.result },
      }


    case actions.FETCH_INVENTORY_REPORTS_RECIEVED:
      return {
        ...state,
        loading: false,
        inventories: { result: action.result }, // { result: { items: action.result, count: action.result ? action.result.length : 0 } },
      }
    case actions.FETCH_WORKORDERCOST_REPORTS_RECIEVED:
      return {
        ...state,
        loading: false,
        workordercosts: {
          result: { items: action.result, count: action.result ? action.result.length : 0 },
        },
      }
    case actions.FETCH_WONUMBER_WORKORDERCOST_REPORTS_RECIEVED:
      return {
        ...state,
        loading: false,
        workordercosts2: {
          result: { items: action.result, count: action.result ? action.result.length : 0 },
        },
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      }

    case actions.FETCH_MAINTENANCE_REPORT_RECIEVED:
      return {
        ...state,
        loading: false,
        workOrdersPm: {
          result: { items: action.result, count: action.result ? action.result.length : 0 },
        },
      }
    default:
      return state
  }
}
