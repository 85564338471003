import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list2, find, save, remove } from '../../services/repairLimit'

function* fetchrepairLimitsFilter({
  size,
  index,
  searchTerm,
  orderBy,
  inventoryClassCode,
  mlfb,
  installYear
}) {
  const t = localStorage.getItem('fetchrepairLimitsFilter')
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        inventoryClassCode = fe.inventoryClassCode || inventoryClassCode
        mlfb = fe.mlfb || mlfb
        installYear = fe.installYear || installYear
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(
      'fetchInventoryClassCostTypesFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        inventoryClassCode,
        mlfb,
        installYear
      }),
    )
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    list2,
    size,
    index,
    searchTerm,
    orderBy,
    inventoryClassCode,
    mlfb,
    installYear
  )
  yield put({
    type: actions.LIST_REPAIRLIMIT,
    result,
  })
}


function* fetchRepairLimitEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_REPAIRLIMIT,
    result,
  })
}

function* fetchRepairLimitSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_REPAIRLIMIT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_REPAIRLIMITS,
  })
}

function* fetchRepairLimitDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_REPAIRLIMIT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_REPAIRLIMITS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_REPAIRLIMITS, fetchrepairLimitsFilter),
    takeEvery(actions.FETCH_REPAIRLIMIT, fetchRepairLimitEdit),
    takeEvery(actions.SAVE_REPAIRLIMIT, fetchRepairLimitSave),
    takeEvery(actions.DELETE_REPAIRLIMIT, fetchRepairLimitDelete),
    takeEvery(actions.RE_FETCH_REPAIRLIMITS, fetchrepairLimitsFilter),
  ])
}
