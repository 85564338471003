import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `inventoryServiceLabor/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy ||
      '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`inventoryServiceLabor/edit/${id}`)
}

export async function save(values) {
  return postRequest(`inventoryServiceLabor/save`, values)
}

export function remove(values) {
  return postRequest(`inventoryServiceLabor/delete`, values)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy,
  repairCode,
  repairDescription,
  className,
  mlfbNumber,
) {
  const contractID = getcontractIdNumber()
  const url = `inventoryServiceLabor/get-list2/${size || '15'}/${index || '0'}/${searchTerm ||
    '%20'}/${orderBy || '%20'}/${contractID}/${repairCode || '%20'}/${repairDescription ||
    '%20'}/${className || '%20'}/${mlfbNumber || '%20'}`
  return getRequest(url)
}
