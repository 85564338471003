/* eslint-disable */

import actions from './actions'

export default function PROPOSALDETAIL_PRE_PRICEsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_PROPOSALDETAIL_PRE_PRICE:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_PROPOSALDETAIL_PRE_PRICE:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_PROPOSALDETAIL_PRE_PRICE:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_PROPOSALDETAIL_PRE_PRICE:
      return {
        ...state,
        editing: { PROPOSALDETAIL_PRE_PRICEID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.DELETE_PROPOSALDETAIL_PRE_PRICE_RECIEVED:
      return {
        ...state,
        editing: { PROPOSALDETAIL_PRE_PRICEID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
