import actions from './actions'

export default function CostTypesReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_COSTTYPE:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_COSTTYPE:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal
      }
    case actions.RESET_COSTTYPE:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        openModal: null
      }
    case actions.NEW_COSTTYPE:
      return {
        ...state,
        editing: { costTypeID: 0 },
        loading: false,
        saveSuccess: false,
        openModal: true
      }
      
    case actions.CREATE_COSTTYPE_RECIEVED ||
      actions.UPDATE_COSTTYPE_RECIEVED ||
      actions.DELETE_COSTTYPE_RECIEVED:
      return {
        ...state,
        editing: { costTypeID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
