const actions = {
  REQUESTING: 'RepairLimits/REQUESTING',
  RESPONSE_ERROR: 'RepairLimits/RESPONSE_ERROR',

  FETCH_REPAIRLIMITS: 'RepairLimits/FETCH',
  LIST_REPAIRLIMIT: 'RepairLimit/LIST',

  FETCH_REPAIRLIMIT: 'RepairLimit/FETCH',
  NEW_REPAIRLIMIT: 'RepairLimit/NEW',
  EDIT_REPAIRLIMIT: 'RepairLimit/EDIT',
  RESET_REPAIRLIMIT: 'RepairLimit/RESET',

  RE_FETCH_REPAIRLIMITS: 'RepairLimits/REFRESH',
  SAVE_REPAIRLIMIT: 'RepairLimit/SAVE',
  SAVE_REPAIRLIMIT_RECIEVED: 'RepairLimit/SAVE_REPAIRLIMIT_RECIEVED',

  DELETE_REPAIRLIMIT: 'RepairLimit/DELETE',
  DELETE_REPAIRLIMIT_RECIEVED: 'RepairLimit/DELETE_RECIEVED',
}

export default actions
