import { getRequest, postRequest, getcontractIdNumber, getMicroBaseApiUrl } from './api'
 
const token = localStorage.getItem('token')
export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(`partOrder/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`partOrder/edit/${id}`)
}

export async function save(values) {
  return postRequest(`partOrder/save`, values)
}

export function remove(values) {
  return postRequest(`partOrder/delete`, values)
}

export function savePdf(partOrderID, file) {
  const params = {
    values: {
      partOrderID,
      file
    }
  }
  return postRequest(`partOrder/save-pdf`, params)
}

export async function export2(
  partID,
  status,
  supplierID,
  orderDate,
  model,
  mlfb,
  manufacturer,
  manufacturerNr,
  owner,
  description
) {
  const contractID = getcontractIdNumber()
  const url = `report/part-orders-excel/${contractID}/${partID || '%20'}/${status|| '%20'}/${supplierID|| '%20'}/${orderDate|| '%20'}/${model || '%20'}/${manufacturer || '%20'}/${manufacturerNr || '%20'}/${mlfb || '%20'}/${owner || '%20'}/${description || '%20'}`
  const furl = `${getMicroBaseApiUrl(url)}${url}`
  const win = window.open(`${furl}/${token}`, '_blank')
  win.focus()
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy, 
  partID,
  status,
  supplierID,
  orderDate,
  model,
  mlfb,
  manufacturer,
  manufacturerNr,
  owner,
  description
) {
  const contractID = getcontractIdNumber()
  const url = `partOrder/get-list2/${size || '15'}/${index || '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${contractID}/${partID || '%20'}/${status|| '%20'}/${supplierID|| '%20'}/${orderDate|| '%20'}/${model || '%20'}/${manufacturer || '%20'}/${manufacturerNr || '%20'}/${mlfb || '%20'}/${owner || '%20'}/${description || '%20'}`
  return getRequest(url)
}
 