import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  getWorkOrders,
  getInventories,
  getClassCodeCosts,
  getWOClassCodeCosts,
  sendEmail,
  getWorkOrders23,
  getMaintenanceReportPreview,
  getWorkOrdersMaintenance
} from '../../services/report'
import { SAVE_200_MSG, SAVE_200_DESC } from '../../constants'
import actions from './actions'

function* fetchWorkOrders({
  size,
  index,
  searchTerm,
  orderBy,
  workOrderStatus,
  workOrderSubStatus,
  area,
  location,
  address,
  begin,
  end,
  contactName,
  contactPhone,
  workOrderType,
  inventoryNumber,
  excel,
}) {
  const fe = JSON.parse(localStorage.getItem('workOrdersFetchReport'))
  if (fe != null) {
    workOrderStatus = fe.workOrderStatus || workOrderStatus
    workOrderSubStatus = fe.workOrderSubStatus || workOrderSubStatus
    location = fe.location || location
    area = fe.area || area
    address = fe.address || address
    contactName = fe.contactName || contactName
    contactPhone = fe.contactPhone || contactPhone
    workOrderType = fe.workOrderType || workOrderType
    inventoryNumber = fe.inventoryNumber || inventoryNumber
    begin = fe.begin || begin
    end = fe.end || end
  }
  yield put({
    type: actions.REQUESTING,
  })
  size = size || localStorage.getItem('pageSize') || 15
  const result = yield call(
    getWorkOrders,
    size,
    index,
    searchTerm,
    orderBy,
    workOrderStatus,
    workOrderSubStatus,
    area,
    location,
    address,
    begin,
    end,
    contactName,
    contactPhone,
    workOrderType,
    inventoryNumber,
    excel,
  )
  yield put({
    type: actions.FETCH_WORKORDER_REPORTS_RECIEVED,
    result,
  })
}
function* fetchWorkOrders2({
  workOrderStatus,
  workOrderSubStatus,
  area,
  location,
  address,
  begin,
  end,
  contactName,
  contactPhone,
  workOrderType,
  inventoryNumber,
}) {
  yield call(
    getWorkOrders23,
    workOrderStatus,
    workOrderSubStatus,
    area,
    location,
    address,
    begin,
    end,
    contactName,
    contactPhone,
    workOrderType,
    inventoryNumber,
  )
}

function* fetchInvetories({
  size,
  index,
  searchTerm,
  orderBy,
  intervalID,
  area,
  location,
  address,
  account,
  emHBarcode,
  begin,
  end,
  excel,
}) {
  const fe = JSON.parse(localStorage.getItem('fetchInvetoriesReport'))
  if (fe != null) {
    intervalID = fe.intervalID || intervalID
    area = fe.area || area
    location = fe.location || location
    address = fe.address || address
    account = fe.garrison || account
    emHBarcode = fe.emHBarcode || emHBarcode
    begin = fe.begin || begin
    end = fe.begin || end
  }
  size = size || localStorage.getItem('pageSize') || 15
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    getInventories,
    size,
    index,
    searchTerm,
    orderBy,
    intervalID,
    area,
    location,
    address,
    account,
    emHBarcode,
    begin,
    end,
    excel,
  )
  yield put({
    type: actions.FETCH_INVENTORY_REPORTS_RECIEVED,
    result,
  })
}

function* fetchCosts({ workOrderStatus, location, area, address, begin, end, excel }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    getClassCodeCosts,
    workOrderStatus,
    location,
    area,
    address,
    begin,
    end,
    excel,
  )
  yield put({
    type: actions.FETCH_WORKORDERCOST_REPORTS_RECIEVED,
    result,
  })
}

function* fetchCosts2({ workOrderStatus, location, area, address, begin, end, excel }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    getWOClassCodeCosts,
    workOrderStatus,
    location,
    area,
    address,
    begin,
    end,
    excel,
  )
  yield put({
    type: actions.FETCH_WONUMBER_WORKORDERCOST_REPORTS_RECIEVED,
    result,
  })
}

function* fetchSendEmail() {
  yield put({
    type: actions.REQUESTING,
  })
  yield call(sendEmail)
  notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
}

function* fetchMaintenanceReports({ workOrderStatus, begin, end, classID }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getMaintenanceReportPreview, workOrderStatus, begin, end, classID)
  yield put({
    type: actions.FETCH_MAINTENANCE_REPORT_RECIEVED,
    result,
  })
}

function* fetchWorkOrdersMaintenance({ workOrderStatus, workOrderSubStatus, begin, end, notAffect, notAllow, excel }) {
  yield put({
    type: actions.REQUESTING,
  });
  const fe = JSON.parse(localStorage.getItem('workOrdersMaintenancesReports2'))
  if (fe != null) {
    workOrderStatus = fe.workOrderStatus || workOrderStatus
    workOrderSubStatus = fe.workOrderSubStatus || workOrderSubStatus
    notAffect = fe.notAffect || notAffect
    notAllow = fe.notAllow || notAllow
    begin = fe.begin || begin
    end = fe.end || end
  }
  const result = yield call(
    getWorkOrdersMaintenance,
    workOrderStatus,
    workOrderSubStatus,
    begin,
    end,
    notAffect,
    notAllow,
    excel,
  )
  yield put({
    type: actions.FETCH_WORKORDER_REPORTS_MAINTENANCE_RECIEVED,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_MAINTENANCE_REPORT, fetchMaintenanceReports),
    takeEvery(actions.FETCH_WORKORDER_REPORTS, fetchWorkOrders),
    takeEvery(actions.FETCH_WORKORDER_REPORTS2, fetchWorkOrders2),
    takeEvery(actions.FETCH_INVENTORY_REPORTS, fetchInvetories),
    takeEvery(actions.FETCH_WORKORDERCOST_REPORTS, fetchCosts),
    takeEvery(actions.FETCH_WORKORDERCOST_REPORTS2, fetchCosts2),
    takeEvery(actions.FETCH_WORKORDER_SENDEMAIL, fetchSendEmail),
    takeEvery(actions.FETCH_WORKORDER_REPORTS_MAINTENANCE, fetchWorkOrdersMaintenance),
  ])
}