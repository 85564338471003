import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `inventoryProductInformation/get-list/${size || 15}/${index || 0}/${searchTerm ||
      '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`inventoryProductInformation/edit/${id}`)
}

export async function save(values) {
  return postRequest(`inventoryProductInformation/save`, values)
}

export function remove(values) {
  return postRequest(`inventoryProductInformation/delete`, values)
}

export async function checklupp(values) {
  return postRequest(`inventoryProductInformation/checklupp`, values)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy,
  className,
  deviceInfo,
  model,
  stockNumber,
  mlfbNumber,
  installyear,
  manufacturer,
) {
  const contractID = getcontractIdNumber()
  const url = `inventoryProductInformation/get-list2/${size || '15'}/${index || '0'}/${searchTerm ||
    '%20'}/${orderBy || '%20'}/${contractID}/${className || '%20'}/${deviceInfo || '%20'}/${model ||
    '%20'}/${stockNumber || '%20'}/${mlfbNumber || '%20'}/${installyear || '%20'}/${manufacturer ||
    '%20'}`
  return getRequest(url)
}
 