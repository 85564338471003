import { getRequest, postRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(
    `customer/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`customer/edit/${id}`)
}

export async function save(values) {
  return postRequest(`customer/save`, values)
}

export function remove(values) {
  return postRequest(`customer/delete`, values)
}
