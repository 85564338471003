const actions = {
  REQUESTING: 'ContactPersons/REQUESTING',
  RESPONSE_ERROR: 'ContactPersons/RESPONSE_ERROR',

  FETCH_CONTACTPERSONS: 'ContactPersons/FETCH',
  LIST_CONTACTPERSON: 'ContactPerson/LIST',

  FETCH_CONTACTPERSON: 'ContactPerson/FETCH',
  NEW_CONTACTPERSON: 'ContactPerson/NEW',
  EDIT_CONTACTPERSON: 'ContactPerson/EDIT',
  RESET_CONTACTPERSON: 'ContactPerson/RESET',

  RE_FETCH_CONTACTPERSONS: 'ContactPersons/REFRESH',
  SAVE_CONTACTPERSON: 'ContactPerson/SAVE',
  SAVE_CONTACTPERSON_RECIEVED: 'ContactPerson/SAVE_CONTACTPERSON_RECIEVED',

  DELETE_CONTACTPERSON: 'ContactPerson/DELETE',
  DELETE_CONTACTPERSON_RECIEVED: 'ContactPerson/DELETE_RECIEVED',

}

export default actions
