/*eslint-disable*/

import { all, put, call, takeEvery } from 'redux-saga/effects'

import getMenuData from 'services/menu/general'
import getMenuData2 from 'services/menu/contract'
import getMenuData3 from 'services/menu/reports'
import getMenuData4 from 'services/menu/basic'
import getMenuData5Basic from 'services/menu/basic2'

import domapAppTypeMenu from 'services/menu/appType/domap'
import generalAppTypeMenu from 'services/menu/appType/general'

import domapReportMenu from 'services/menu/reports-domap'
import generalReportMenu from 'services/menu/reports'

import { getContractAppType, getcontractIdNumber } from 'services/api'

import actions from './actions'

export function* GET_DATA() {
  const payload = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload
  })
}

export function* GET_DATA2() {
  const payload = yield call(getMenuData2);
  const appType = getContractAppType();
  if (appType !== '') {
    if (appType === 1) {
      const payload = yield call(domapAppTypeMenu)
      yield put({
        type: 'menu/SET_STATE2',
        payload
      });
    }
    if (appType === 2) {
      const payload = yield call(generalAppTypeMenu)
      yield put({
        type: 'menu/SET_STATE2',
        payload
      });
      const menu4 = yield call(getcontractIdNumber() === 4 ? getMenuData5Basic : getMenuData4);
      yield put({
        type: 'menu/SET_STATE4',
        payload: menu4
      });
    }
  } else {
    yield put({
      type: 'menu/SET_STATE2',
      payload
    });
  }
}

// export function* GET_DATA3() {
//   const payload = yield call(getMenuData3)
//   yield put({
//     type: 'menu/SET_STATE3',
//     payload
//   })
// }

export function* GET_DATA3() {
  const payload = yield call(getMenuData3);
  const appType = getContractAppType();
  if (appType !== '') {
    if (appType === 1) {
      const payload = yield call(domapReportMenu)
      yield put({
        type: 'menu/SET_STATE3',
        payload
      });
    }
    if (appType === 2) {
      const payload = yield call(generalReportMenu)
      yield put({
        type: 'menu/SET_STATE3',
        payload
      }); 
    }
  } else {
    yield put({
      type: 'menu/SET_STATE3',
      payload
    });
  }
}

export function* GET_DATA4() {
  const payload = yield call(getMenuData4)
  yield put({
    type: 'menu/SET_STATE4',
    payload
  })
}

export function* getContractMenu({ appType }) {
  if (appType === 1) {
    const payload = yield call(domapAppTypeMenu)
    yield put({
      type: 'menu/SET_STATE2',
      payload
    });
    
    const menu3 = yield call(domapReportMenu)
    yield put({
      type: 'menu/SET_STATE3',
      payload: menu3
    });
  }

  if (appType === 2) {
    const payload = yield call(generalAppTypeMenu)
    yield put({
      type: 'menu/SET_STATE2',
      payload
    });

    const menu4 = yield call(getcontractIdNumber() === 4 ? getMenuData5Basic : getMenuData4);
    yield put({
      type: 'menu/SET_STATE4',
      payload: menu4
    });
    
    const menu3 = yield call(generalReportMenu)
    yield put({
      type: 'menu/SET_STATE3',
      payload: menu3
    });
  }



}

export default function* rootSaga() {
  yield all([
    GET_DATA(),
    GET_DATA2(),
    GET_DATA3(),
    GET_DATA4(),
    takeEvery(actions.GET_CONTRACT_MENU, getContractMenu),

  ])
}


// contract based contract menu. yapicaz, contract sectigi zaman menude degisicek.

