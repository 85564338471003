const actions = {
  REQUESTING: 'customers/REQUESTING',
  RESPONSE_ERROR: 'customers/RESPONSE_ERROR',

  FETCH_CUSTOMERS: 'customers/FETCH',
  LIST_CUSTOMER: 'customer/LIST',

  FETCH_CUSTOMER: 'customer/FETCH',
  NEW_CUSTOMER: 'customer/NEW',
  EDIT_CUSTOMER: 'customer/EDIT',
  RESET_CUSTOMER: 'customer/RESET',

  RE_FETCH_CUSTOMERS: 'customers/REFRESH',
  SAVE_CUSTOMER: 'customer/SAVE',
  SAVE_CUSTOMER_RECIEVED: 'customer/SAVE_CUSTOMER_RECIEVED',

  DELETE_CUSTOMER: 'customer/DELETE',
  DELETE_CUSTOMER_RECIEVED: 'customer/DELETE_RECIEVED',
}

export default actions
