import localeAntd from 'antd/es/locale/zh_CN'

const messages = {

}

export default {
  locale: 'zh-CN',
  localeAntd,
  messages,
}
