import { getRequest, postRequest, getWorkorderID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  const workOrderID = getWorkorderID()
  if (!searchTerm.includes('workOrderID'))
    searchTerm = `${searchTerm}, workOrderID:${workOrderID},`
  return getRequest(`workOrderTravelCost/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`workOrderTravelCost/edit/${id}`)
}

export async function save(values) {
  return postRequest(`workOrderTravelCost/save`, values)
}

export function remove(values) {
  return postRequest(`workOrderTravelCost/delete`, values)
}
