const actions = {
  REQUESTING: 'WorkOrderTravelCosts/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderTravelCosts/RESPONSE_ERROR',

  FETCH_WORKORDERTRAVELCOSTS: 'WorkOrderTravelCosts/FETCH',
  LIST_WORKORDERTRAVELCOST: 'WorkOrderTravelCost/LIST',

  FETCH_WORKORDERTRAVELCOST: 'WorkOrderTravelCost/FETCH',
  NEW_WORKORDERTRAVELCOST: 'WorkOrderTravelCost/NEW',
  EDIT_WORKORDERTRAVELCOST: 'WorkOrderTravelCost/EDIT',
  RESET_WORKORDERTRAVELCOST: 'WorkOrderTravelCost/RESET',

  RE_FETCH_WORKORDERTRAVELCOSTS: 'WorkOrderTravelCosts/REFRESH',
  SAVE_WORKORDERTRAVELCOST: 'WorkOrderTravelCost/SAVE',
  SAVE_WORKORDERTRAVELCOST_RECIEVED: 'WorkOrderTravelCost/SAVE_WORKORDERTRAVELCOST_RECIEVED',

  DELETE_WORKORDERTRAVELCOST: 'WorkOrderTravelCost/DELETE',
  DELETE_WORKORDERTRAVELCOST_RECIEVED: 'WorkOrderTravelCost/DELETE_RECIEVED',
}

export default actions
